<template>
    <Layout>
        <div class="d-flex align-items-baseline justify-content-between">
            <SettingsTabs />
            <!-- <div
                class="d-flex align-items-center justify-content-start text-uppercase fw-bolder gap-1">
                {{ $t('player mode') }}:
                <span
                    class="badge"
                    :class="{
                        'bg-info': player_mode == 'all',
                        'bg-warning': player_mode == 'online',
                        'bg-dark': player_mode == 'offline',
                    }"
                    >{{ $t(player_mode) }}</span
                >
            </div> -->
        </div>
        <div class="card overflow-auto m-0">
            <div class="card-body">
                <div v-if="loading">
                    <LoadingTableForm
                        :cols="2"
                        :rows="4"
                        class="table-bordered bg-white" />
                    <LoadingTableForm
                        :cols="4"
                        :rows="10"
                        class="table-bordered bg-white mb-0" />
                </div>
                <b-form @submit.prevent="submitForm" v-else>
                    <div class="table-responsive">
                        <table
                            v-if="$route.params.section == 'provider'"
                            class="table table-bordered table-wrap table-form mb-0">
                            <thead>
                                <tr class="fs-5 bg-light bg-soft">
                                    <th class="text-start" colspan="4">
                                        {{ $t('vendor settings') }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-if="
                                        ['all', 'online'].includes(player_mode)
                                    ">
                                    <th class="bg-light bg-soft">
                                        {{ $t('casino menu') }}
                                    </th>
                                    <td>
                                        <multiselect
                                            :placeholder="`${$t(
                                                'select casino menu'
                                            )}`"
                                            v-model="pl.site.menu.table"
                                            :options="['honorlink', 'kplay']"
                                            label="name"
                                            :can-deselect="false"
                                            :can-clear="false">
                                            <template
                                                v-slot:singlelabel="{ value }">
                                                <span class="flex-fill ps-2">{{
                                                    $t(value.value)
                                                }}</span>
                                            </template>
                                            <template
                                                v-slot:option="{ option }">
                                                {{ $t(option.value) }}
                                            </template>
                                        </multiselect>
                                    </td>
                                    <th class="bg-light bg-soft">
                                        {{ $t('slot menu') }}
                                    </th>
                                    <td>
                                        <multiselect
                                            :placeholder="`${$t(
                                                'select slot menu'
                                            )}`"
                                            v-model="pl.site.menu.slots"
                                            :options="['honorlink', 'kplay']"
                                            label="name"
                                            :can-deselect="false"
                                            :can-clear="false">
                                            <template
                                                v-slot:singlelabel="{ value }">
                                                <span class="flex-fill ps-2">{{
                                                    $t(value.value)
                                                }}</span>
                                            </template>
                                            <template
                                                v-slot:option="{ option }">
                                                {{ $t(option.value) }}
                                            </template>
                                        </multiselect>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="bg-light bg-soft" colspan="4">
                                        &nbsp;
                                    </th>
                                </tr>
                                <tr>
                                    <th class="bg-light bg-soft" colspan="4">
                                        &nbsp;
                                    </th>
                                </tr>
                                <!-- <tr>
                                    <th class="bg-light bg-soft">
                                        {{ $t('title') }}
                                    </th>
                                    <td colspan="3">
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="pl.site.title" />
                                    </td>
                                </tr>

                                <tr>
                                    <th class="bg-light bg-soft">
                                        <div
                                            class="d-flex justify-content-center">
                                            <b-form-checkbox
                                                switch
                                                v-model="
                                                    pl.site.open_message.enabled
                                                ">
                                                {{ $t('open message') }}
                                            </b-form-checkbox>
                                        </div>
                                    </th>
                                    <td colspan="4">
                                        <QuillEditor
                                            contentType="html"
                                            :placeholder="
                                                $t('enter content here')
                                            "
                                            v-model:content="
                                                pl.site.open_message.content
                                            " />
                                    </td>
                                </tr>
                                <tr>
                                    <th class="bg-light bg-soft">
                                        <div
                                            class="d-flex justify-content-center">
                                            <b-form-checkbox
                                                switch
                                                v-model="
                                                    pl.site.welcome_message
                                                        .enabled
                                                ">
                                                {{ $t('welcome message') }}
                                            </b-form-checkbox>
                                        </div>
                                    </th>
                                    <td colspan="4">
                                        <QuillEditor
                                            contentType="html"
                                            :placeholder="
                                                $t('enter content here')
                                            "
                                            v-model:content="
                                                pl.site.welcome_message.content
                                            " />
                                    </td>
                                </tr>
                                <tr>
                                    <th class="bg-light bg-soft">
                                        <div
                                            class="d-flex justify-content-center">
                                            <b-form-checkbox
                                                switch
                                                v-model="
                                                    pl.site.maintenance_message
                                                        .enabled
                                                ">
                                                {{ $t('maintenance message') }}
                                            </b-form-checkbox>
                                        </div>
                                    </th>
                                    <td colspan="4">
                                        <QuillEditor
                                            contentType="html"
                                            :placeholder="
                                                $t('enter content here')
                                            "
                                            v-model:content="
                                                pl.site.maintenance_message
                                                    .content
                                            " />
                                    </td>
                                </tr> -->
                            </tbody>
                        </table>
                        <table
                            v-if="$route.params.section == 'advance'"
                            class="table table-bordered table-wrap table-form">
                            <thead>
                                <tr class="fs-5 bg-light bg-soft">
                                    <th class="text-start" colspan="4">
                                        {{
                                            $t('transaction')
                                        }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th
                                        class="bg-light bg-soft"
                                        style="width: 15%">
                                        {{ $t('enabled') }}
                                    </th>
                                    <td>
                                        <div class="d-flex gap-2 mt-2">
                                            <b-form-radio
                                                name="transaction-enabled"
                                                :value="true"
                                                v-model="
                                                    pl.transaction.enabled
                                                ">
                                                {{ $t('yes') }}
                                            </b-form-radio>
                                            <b-form-radio
                                                name="transaction-enabled"
                                                :value="false"
                                                v-model="
                                                    pl.transaction.enabled
                                                ">
                                                {{ $t('no') }}
                                            </b-form-radio>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table
                            v-if="$route.params.section == 'advance'"
                            class="table table-bordered table-wrap table-form mb-2 table-striped">
                            <thead>
                                <tr class="fs-5 bg-light bg-soft">
                                    <th class="text-start" colspan="8">
                                        {{ $t('account details') }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(f, index) in bank_account_fields"
                                    :key="f.level">
                                    <th style="width: 18%">
                                        {{ $t(`level`) }} {{ f.level }}
                                    </th>
                                    <th class="bg-light bg-soft">
                                        {{ $t('bank name') }}
                                    </th>
                                    <td>
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="f.bank_name" />
                                    </td>
                                    <th class="bg-light bg-soft">
                                        {{ $t('account holder') }}
                                    </th>
                                    <td>
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="f.account_holder" />
                                    </td>
                                    <th class="bg-light bg-soft">
                                        {{ $t('account number') }}
                                    </th>
                                    <td>
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="f.account_number" />
                                    </td>
                                    <td>
                                        <button
                                            type="button"
                                            class="btn btn-danger btn-sm"
                                            @click="
                                                removeBankAccountField(index)
                                            ">
                                            {{ $t('remove') }}
                                        </button>
                                    </td>
                                </tr>
                                <tr v-if="usable_level_options.length">
                                    <th style="width: 20%" class="py-0">
                                        <select
                                            class="form-control"
                                            @input="addBankAccountField">
                                            <option value="">
                                                {{ $t('select level to add') }}
                                            </option>
                                            <option
                                                :value="level.value"
                                                v-for="level in usable_level_options"
                                                :key="level.value">
                                                {{ $t('level') }}
                                                {{ level.value }}
                                            </option>
                                        </select>
                                    </th>
                                    <th class="bg-light bg-soft text-muted">
                                        {{ $t('bank name') }}
                                    </th>
                                    <td>
                                        <input
                                            disabled
                                            type="text"
                                            class="form-control" />
                                    </td>
                                    <th class="bg-light bg-soft text-muted">
                                        {{ $t('account holder') }}
                                    </th>
                                    <td>
                                        <input
                                            disabled
                                            type="text"
                                            class="form-control" />
                                    </td>
                                    <th class="bg-light bg-soft text-muted">
                                        {{ $t('account number') }}
                                    </th>
                                    <td>
                                        <input
                                            disabled
                                            type="text"
                                            class="form-control" />
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                        <table
                            v-if="$route.params.section == 'advance'"
                            class="table table-bordered table-wrap table-form">
                            <thead>
                                <tr class="fs-5 bg-light bg-soft">
                                    <th class="text-start" colspan="4">
                                        {{ $t('points') }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th class="bg-light bg-soft">
                                        {{ $t('bonus points') }}
                                    </th>
                                    <td>
                                        <VueNumberFormat
                                            class="form-control text-end rounded-end w-25"
                                            :class="{
                                                'is-invalid':
                                                    submitted &&
                                                    v$.pl.registration
                                                        .bonus_points.$error,
                                            }"
                                            v-model:value="
                                                pl.registration.bonus_points
                                            "
                                            :options="{
                                                precision: 0,
                                                prefix: '',
                                                suffix: '',
                                                thousand: ',',
                                                acceptNegative: false,
                                                isInteger: true,
                                            }"></VueNumberFormat>
                                        <div
                                            v-for="(item, index) in v$.pl
                                                .registration.bonus_points
                                                .$errors"
                                            :key="index"
                                            class="invalid-feedback">
                                            <span v-if="item.$message">
                                                {{ $t(item.$message) }}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="bg-light bg-soft">
                                        <div
                                            class="d-flex justify-content-center">
                                            <b-form-checkbox
                                                switch
                                                v-model="
                                                    pl.registration
                                                        .welcome_message.enabled
                                                ">
                                                {{ $t('welcome message') }}
                                            </b-form-checkbox>
                                        </div>
                                    </th>
                                    <td colspan="4">
                                        <QuillEditor
                                            contentType="html"
                                            :placeholder="
                                                $t('enter content here')
                                            "
                                            v-model:content="
                                                pl.registration.welcome_message
                                                    .content
                                            " />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table
                            v-if="$route.params.section == 'advance'"
                            class="table table-bordered table-wrap table-form">
                            <thead>
                                <tr class="fs-5 bg-light bg-soft">
                                    <th class="text-start" colspan="6">
                                        {{ $t('player commission') }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th class="bg-light bg-soft">
                                        {{ $t('player commission enabled') }}
                                    </th>
                                    <td>
                                        <div class="d-flex gap-2 mt-2">
                                            <b-form-radio
                                                name="player commission enabled"
                                                :value="true"
                                                v-model="
                                                    pl.player_commission.enabled
                                                ">
                                                {{ $t('yes') }}
                                            </b-form-radio>
                                            <b-form-radio
                                                name="player commission enabled"
                                                :value="false"
                                                v-model="
                                                    pl.player_commission.enabled
                                                ">
                                                {{ $t('no') }}
                                            </b-form-radio>
                                        </div>
                                    </td>
                                    <th class="bg-light bg-soft">
                                        {{ $t('casino enabled') }}
                                    </th>
                                    <td>
                                        <div class="d-flex gap-2 mt-2">
                                            <b-form-radio
                                                name="player commission casino enabled"
                                                :value="true"
                                                v-model="
                                                    pl.player_commission.casino
                                                        .enabled
                                                ">
                                                {{ $t('yes') }}
                                            </b-form-radio>
                                            <b-form-radio
                                                name="player commission casino enabled"
                                                :value="false"
                                                v-model="
                                                    pl.player_commission.casino
                                                        .enabled
                                                ">
                                                {{ $t('no') }}
                                            </b-form-radio>
                                        </div>
                                    </td>
                                    <th class="bg-light bg-soft">
                                        {{ $t('casino total') }}
                                    </th>
                                    <td>
                                        <VueNumberFormat
                                            class="form-control text-end rounded-end"
                                            :class="{
                                                'is-invalid':
                                                    submitted &&
                                                    v$.pl.player_commission
                                                        .casino.total.$error,
                                            }"
                                            v-model:value="
                                                pl.player_commission.casino
                                                    .total
                                            "
                                            :options="{
                                                precision: 0,
                                                prefix: '',
                                                suffix: '',
                                                decimal: '.',
                                                thousand: ',',
                                                acceptNegative: false,
                                                isInteger: true,
                                            }"></VueNumberFormat>
                                        <div
                                            v-for="(item, index) in v$.pl
                                                .player_commission.casino.total
                                                .$errors"
                                            :key="index"
                                            class="invalid-feedback">
                                            <span v-if="item.$message">
                                                {{ $t(item.$message) }}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <b-collapse
                            :visible="pl.player_commission.casino.enabled">
                            <table
                                v-if="$route.params.section == 'advance'"
                                class="table table-bordered table-wrap table-form">
                                <thead>
                                    <tr class="fs-5 bg-light bg-soft">
                                        <th class="text-start" colspan="10">
                                            {{ $t('casino percentage') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th class="bg-light bg-soft">
                                            {{ $t('level') }} 1
                                        </th>
                                        <td>
                                            <VueNumberFormat
                                                class="form-control text-end rounded-end"
                                                :class="{
                                                    'is-invalid':
                                                        submitted &&
                                                        v$.pl.player_commission
                                                            .casino.level_one
                                                            .$error,
                                                }"
                                                v-model:value="
                                                    pl.player_commission.casino
                                                        .level_one
                                                "
                                                :options="{
                                                    precision: 2,
                                                    prefix: '',
                                                    suffix: '%',
                                                    decimal: '.',
                                                    thousand: ',',
                                                    acceptNegative: false,
                                                    isInteger: false,
                                                }"></VueNumberFormat>
                                            <div
                                                v-for="(item, index) in v$.pl
                                                    .player_commission.casino
                                                    .level_one.$errors"
                                                :key="index"
                                                class="invalid-feedback">
                                                <span v-if="item.$message">
                                                    {{ $t(item.$message) }}
                                                </span>
                                            </div>
                                        </td>
                                        <th class="bg-light bg-soft">
                                            {{ $t('level') }} 2
                                        </th>
                                        <td>
                                            <VueNumberFormat
                                                class="form-control text-end rounded-end"
                                                :class="{
                                                    'is-invalid':
                                                        submitted &&
                                                        v$.pl.player_commission
                                                            .casino.level_two
                                                            .$error,
                                                }"
                                                v-model:value="
                                                    pl.player_commission.casino
                                                        .level_two
                                                "
                                                :options="{
                                                    precision: 2,
                                                    prefix: '',
                                                    suffix: '%',
                                                    decimal: '.',
                                                    thousand: ',',
                                                    acceptNegative: false,
                                                    isInteger: false,
                                                }"></VueNumberFormat>
                                            <div
                                                v-for="(item, index) in v$.pl
                                                    .player_commission.casino
                                                    .level_two.$errors"
                                                :key="index"
                                                class="invalid-feedback">
                                                <span v-if="item.$message">
                                                    {{ $t(item.$message) }}
                                                </span>
                                            </div>
                                        </td>
                                        <th class="bg-light bg-soft">
                                            {{ $t('level') }} 3
                                        </th>
                                        <td>
                                            <VueNumberFormat
                                                class="form-control text-end rounded-end"
                                                :class="{
                                                    'is-invalid':
                                                        submitted &&
                                                        v$.pl.player_commission
                                                            .casino.level_three
                                                            .$error,
                                                }"
                                                v-model:value="
                                                    pl.player_commission.casino
                                                        .level_three
                                                "
                                                :options="{
                                                    precision: 2,
                                                    prefix: '',
                                                    suffix: '%',
                                                    decimal: '.',
                                                    thousand: ',',
                                                    acceptNegative: false,
                                                    isInteger: false,
                                                }"></VueNumberFormat>
                                            <div
                                                v-for="(item, index) in v$.pl
                                                    .player_commission.casino
                                                    .level_three.$errors"
                                                :key="index"
                                                class="invalid-feedback">
                                                <span v-if="item.$message">
                                                    {{ $t(item.$message) }}
                                                </span>
                                            </div>
                                        </td>
                                        <th class="bg-light bg-soft">
                                            {{ $t('level') }} 4
                                        </th>
                                        <td>
                                            <VueNumberFormat
                                                class="form-control text-end rounded-end"
                                                :class="{
                                                    'is-invalid':
                                                        submitted &&
                                                        v$.pl.player_commission
                                                            .casino.level_four
                                                            .$error,
                                                }"
                                                v-model:value="
                                                    pl.player_commission.casino
                                                        .level_four
                                                "
                                                :options="{
                                                    precision: 2,
                                                    prefix: '',
                                                    suffix: '%',
                                                    decimal: '.',
                                                    thousand: ',',
                                                    acceptNegative: false,
                                                    isInteger: false,
                                                }"></VueNumberFormat>
                                            <div
                                                v-for="(item, index) in v$.pl
                                                    .player_commission.casino
                                                    .level_four.$errors"
                                                :key="index"
                                                class="invalid-feedback">
                                                <span v-if="item.$message">
                                                    {{ $t(item.$message) }}
                                                </span>
                                            </div>
                                        </td>
                                        <th class="bg-light bg-soft">
                                            {{ $t('level') }} 5
                                        </th>
                                        <td>
                                            <VueNumberFormat
                                                class="form-control text-end rounded-end"
                                                :class="{
                                                    'is-invalid':
                                                        submitted &&
                                                        v$.pl.player_commission
                                                            .casino.level_five
                                                            .$error,
                                                }"
                                                v-model:value="
                                                    pl.player_commission.casino
                                                        .level_five
                                                "
                                                :options="{
                                                    precision: 2,
                                                    prefix: '',
                                                    suffix: '%',
                                                    decimal: '.',
                                                    thousand: ',',
                                                    acceptNegative: false,
                                                    isInteger: false,
                                                }"></VueNumberFormat>
                                            <div
                                                v-for="(item, index) in v$.pl
                                                    .player_commission.casino
                                                    .level_five.$errors"
                                                :key="index"
                                                class="invalid-feedback">
                                                <span v-if="item.$message">
                                                    {{ $t(item.$message) }}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="bg-light bg-soft">
                                            {{ $t('level') }} 6
                                        </th>
                                        <td>
                                            <VueNumberFormat
                                                class="form-control text-end rounded-end"
                                                :class="{
                                                    'is-invalid':
                                                        submitted &&
                                                        v$.pl.player_commission
                                                            .casino.level_six
                                                            .$error,
                                                }"
                                                v-model:value="
                                                    pl.player_commission.casino
                                                        .level_six
                                                "
                                                :options="{
                                                    precision: 2,
                                                    prefix: '',
                                                    suffix: '%',
                                                    decimal: '.',
                                                    thousand: ',',
                                                    acceptNegative: false,
                                                    isInteger: false,
                                                }"></VueNumberFormat>
                                            <div
                                                v-for="(item, index) in v$.pl
                                                    .player_commission.casino
                                                    .level_six.$errors"
                                                :key="index"
                                                class="invalid-feedback">
                                                <span v-if="item.$message">
                                                    {{ $t(item.$message) }}
                                                </span>
                                            </div>
                                        </td>
                                        <th class="bg-light bg-soft">
                                            {{ $t('level') }} 7
                                        </th>
                                        <td>
                                            <VueNumberFormat
                                                class="form-control text-end rounded-end"
                                                :class="{
                                                    'is-invalid':
                                                        submitted &&
                                                        v$.pl.player_commission
                                                            .casino.level_seven
                                                            .$error,
                                                }"
                                                v-model:value="
                                                    pl.player_commission.casino
                                                        .level_seven
                                                "
                                                :options="{
                                                    precision: 2,
                                                    prefix: '',
                                                    suffix: '%',
                                                    decimal: '.',
                                                    thousand: ',',
                                                    acceptNegative: false,
                                                    isInteger: false,
                                                }"></VueNumberFormat>
                                            <div
                                                v-for="(item, index) in v$.pl
                                                    .player_commission.casino
                                                    .level_seven.$errors"
                                                :key="index"
                                                class="invalid-feedback">
                                                <span v-if="item.$message">
                                                    {{ $t(item.$message) }}
                                                </span>
                                            </div>
                                        </td>
                                        <th class="bg-light bg-soft">
                                            {{ $t('level') }} 8
                                        </th>
                                        <td>
                                            <VueNumberFormat
                                                class="form-control text-end rounded-end"
                                                :class="{
                                                    'is-invalid':
                                                        submitted &&
                                                        v$.pl.player_commission
                                                            .casino.level_eight
                                                            .$error,
                                                }"
                                                v-model:value="
                                                    pl.player_commission.casino
                                                        .level_eight
                                                "
                                                :options="{
                                                    precision: 2,
                                                    prefix: '',
                                                    suffix: '%',
                                                    decimal: '.',
                                                    thousand: ',',
                                                    acceptNegative: false,
                                                    isInteger: false,
                                                }"></VueNumberFormat>
                                            <div
                                                v-for="(item, index) in v$.pl
                                                    .player_commission.casino
                                                    .level_eight.$errors"
                                                :key="index"
                                                class="invalid-feedback">
                                                <span v-if="item.$message">
                                                    {{ $t(item.$message) }}
                                                </span>
                                            </div>
                                        </td>
                                        <th class="bg-light bg-soft">
                                            {{ $t('level') }} 9
                                        </th>
                                        <td>
                                            <VueNumberFormat
                                                class="form-control text-end rounded-end"
                                                :class="{
                                                    'is-invalid':
                                                        submitted &&
                                                        v$.pl.player_commission
                                                            .casino.level_nine
                                                            .$error,
                                                }"
                                                v-model:value="
                                                    pl.player_commission.casino
                                                        .level_nine
                                                "
                                                :options="{
                                                    precision: 2,
                                                    prefix: '',
                                                    suffix: '%',
                                                    decimal: '.',
                                                    thousand: ',',
                                                    acceptNegative: false,
                                                    isInteger: false,
                                                }"></VueNumberFormat>
                                            <div
                                                v-for="(item, index) in v$.pl
                                                    .player_commission.casino
                                                    .level_nine.$errors"
                                                :key="index"
                                                class="invalid-feedback">
                                                <span v-if="item.$message">
                                                    {{ $t(item.$message) }}
                                                </span>
                                            </div>
                                        </td>
                                        <th class="bg-light bg-soft">
                                            {{ $t('level') }} 10
                                        </th>
                                        <td>
                                            <VueNumberFormat
                                                class="form-control text-end rounded-end"
                                                :class="{
                                                    'is-invalid':
                                                        submitted &&
                                                        v$.pl.player_commission
                                                            .casino.level_ten
                                                            .$error,
                                                }"
                                                v-model:value="
                                                    pl.player_commission.casino
                                                        .level_ten
                                                "
                                                :options="{
                                                    precision: 2,
                                                    prefix: '',
                                                    suffix: '%',
                                                    decimal: '.',
                                                    thousand: ',',
                                                    acceptNegative: false,
                                                    isInteger: false,
                                                }"></VueNumberFormat>
                                            <div
                                                v-for="(item, index) in v$.pl
                                                    .player_commission.casino
                                                    .level_ten.$errors"
                                                :key="index"
                                                class="invalid-feedback">
                                                <span v-if="item.$message">
                                                    {{ $t(item.$message) }}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-collapse>
                        <table
                            v-if="$route.params.section == 'advance'"
                            class="table table-bordered table-wrap table-form">
                            <thead>
                                <tr class="fs-5 bg-light bg-soft">
                                    <th class="text-start" colspan="4">
                                        {{ $t('bet limit') }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th class="bg-light bg-soft">
                                        {{ $t('table minimum bet amount') }}
                                    </th>
                                    <td>
                                        <VueNumberFormat
                                            class="form-control text-end rounded-end"
                                            :class="{
                                                'is-invalid':
                                                    submitted &&
                                                    v$.pl.site.table
                                                        .minimum_amount.$error,
                                            }"
                                            v-model:value="
                                                pl.site.table.minimum_amount
                                            "
                                            :options="{
                                                precision: 0,
                                                prefix: '',
                                                suffix: '',
                                                thousand: ',',
                                                acceptNegative: false,
                                                isInteger: true,
                                            }"></VueNumberFormat>
                                        <div
                                            v-for="(item, index) in v$.pl.site
                                                .table.minimum_amount.$errors"
                                            :key="index"
                                            class="invalid-feedback">
                                            <span v-if="item.$message">
                                                {{ $t(item.$message) }}
                                            </span>
                                        </div>
                                    </td>

                                    <th class="bg-light bg-soft">
                                        {{ $t('table maximum bet amount') }}
                                    </th>
                                    <td>
                                        <VueNumberFormat
                                            class="form-control text-end rounded-end"
                                            :class="{
                                                'is-invalid':
                                                    submitted &&
                                                    v$.pl.site.table
                                                        .maximum_amount.$error,
                                            }"
                                            v-model:value="
                                                pl.site.table.maximum_amount
                                            "
                                            :options="{
                                                precision: 0,
                                                prefix: '',
                                                suffix: '',
                                                thousand: ',',
                                                acceptNegative: false,
                                                isInteger: true,
                                            }"></VueNumberFormat>
                                        <div
                                            v-for="(item, index) in v$.pl.site
                                                .table.maximum_amount.$errors"
                                            :key="index"
                                            class="invalid-feedback">
                                            <span v-if="item.$message">
                                                {{ $t(item.$message) }}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="bg-light bg-soft">
                                        {{ $t('slots minimum bet amount') }}
                                    </th>
                                    <td>
                                        <VueNumberFormat
                                            class="form-control text-end rounded-end"
                                            :class="{
                                                'is-invalid':
                                                    submitted &&
                                                    v$.pl.site.slots
                                                        .minimum_amount.$error,
                                            }"
                                            v-model:value="
                                                pl.site.slots.minimum_amount
                                            "
                                            :options="{
                                                precision: 0,
                                                prefix: '',
                                                suffix: '',
                                                thousand: ',',
                                                acceptNegative: false,
                                                isInteger: true,
                                            }"></VueNumberFormat>
                                        <div
                                            v-for="(item, index) in v$.pl.site
                                                .slots.minimum_amount.$errors"
                                            :key="index"
                                            class="invalid-feedback">
                                            <span v-if="item.$message">
                                                {{ $t(item.$message) }}
                                            </span>
                                        </div>
                                    </td>

                                    <th class="bg-light bg-soft">
                                        {{ $t('slots maximum bet amount') }}
                                    </th>
                                    <td>
                                        <VueNumberFormat
                                            class="form-control text-end rounded-end"
                                            :class="{
                                                'is-invalid':
                                                    submitted &&
                                                    v$.pl.site.slots
                                                        .maximum_amount.$error,
                                            }"
                                            v-model:value="
                                                pl.site.slots.maximum_amount
                                            "
                                            :options="{
                                                precision: 0,
                                                prefix: '',
                                                suffix: '',
                                                thousand: ',',
                                                acceptNegative: false,
                                                isInteger: true,
                                            }"></VueNumberFormat>
                                        <div
                                            v-for="(item, index) in v$.pl.site
                                                .slots.maximum_amount.$errors"
                                            :key="index"
                                            class="invalid-feedback">
                                            <span v-if="item.$message">
                                                {{ $t(item.$message) }}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table
                            v-if="$route.params.section == 'advance'"
                            class="table table-bordered table-wrap table-form">
                            <thead>
                                <tr class="fs-5 bg-light bg-soft">
                                    <th class="text-start" colspan="4">
                                        {{ $t('deposit bonus') }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th class="bg-light bg-soft">
                                        {{ $t('deposit bonus enabled') }}
                                    </th>
                                    <td>
                                        <div class="d-flex gap-2 mt-2">
                                            <b-form-radio
                                                name="deposit bonus enabled"
                                                :value="true"
                                                v-model="
                                                    pl.deposit_bonus.enabled
                                                ">
                                                {{ $t('yes') }}
                                            </b-form-radio>
                                            <b-form-radio
                                                name="deposit bonus enabled"
                                                :value="false"
                                                v-model="
                                                    pl.deposit_bonus.enabled
                                                ">
                                                {{ $t('no') }}
                                            </b-form-radio>
                                        </div>
                                    </td>
                                    <th class="bg-light bg-soft">
                                        {{ $t('minimum deposit') }}
                                    </th>
                                    <td>
                                        <VueNumberFormat
                                            class="form-control text-end rounded-end"
                                            :class="{
                                                'is-invalid':
                                                    submitted &&
                                                    v$.pl.deposit_bonus
                                                        .minimum_deposit.$error,
                                            }"
                                            v-model:value="
                                                pl.deposit_bonus.minimum_deposit
                                            "
                                            :options="{
                                                precision: 0,
                                                prefix: '',
                                                suffix: '',
                                                thousand: ',',
                                                acceptNegative: false,
                                                isInteger: true,
                                            }"></VueNumberFormat>
                                        <div
                                            v-for="(item, index) in v$.pl
                                                .deposit_bonus.minimum_deposit
                                                .$errors"
                                            :key="index"
                                            class="invalid-feedback">
                                            <span v-if="item.$message">
                                                {{ $t(item.$message) }}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="bg-light bg-soft">
                                        {{ $t('first deposit percentage') }}
                                    </th>
                                    <td>
                                        <VueNumberFormat
                                            class="form-control text-end rounded-end"
                                            :class="{
                                                'is-invalid':
                                                    submitted &&
                                                    v$.pl.deposit_bonus
                                                        .first_deposit
                                                        .percentage.$error,
                                            }"
                                            v-model:value="
                                                pl.deposit_bonus.first_deposit
                                                    .percentage
                                            "
                                            :options="{
                                                precision: 2,
                                                prefix: '',
                                                suffix: '%',
                                                decimal: '.',
                                                thousand: ',',
                                                acceptNegative: false,
                                                isInteger: false,
                                            }"></VueNumberFormat>
                                        <div
                                            v-for="(item, index) in v$.pl
                                                .deposit_bonus.first_deposit
                                                .percentage.$errors"
                                            :key="index"
                                            class="invalid-feedback">
                                            <span v-if="item.$message">
                                                {{ $t(item.$message) }}
                                            </span>
                                        </div>
                                    </td>
                                    <th class="bg-light bg-soft">
                                        {{ $t('first deposit maximum points') }}
                                    </th>
                                    <td>
                                        <VueNumberFormat
                                            class="form-control text-end rounded-end"
                                            :class="{
                                                'is-invalid':
                                                    submitted &&
                                                    v$.pl.deposit_bonus
                                                        .first_deposit
                                                        .maximum_points.$error,
                                            }"
                                            v-model:value="
                                                pl.deposit_bonus.first_deposit
                                                    .maximum_points
                                            "
                                            :options="{
                                                precision: 0,
                                                prefix: '',
                                                suffix: '',
                                                thousand: ',',
                                                acceptNegative: false,
                                                isInteger: true,
                                            }"></VueNumberFormat>
                                        <div
                                            v-for="(item, index) in v$.pl
                                                .deposit_bonus.first_deposit
                                                .maximum_points.$errors"
                                            :key="index"
                                            class="invalid-feedback">
                                            <span v-if="item.$message">
                                                {{ $t(item.$message) }}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="bg-light bg-soft">
                                        {{ $t('next deposit percentage') }}
                                    </th>
                                    <td>
                                        <VueNumberFormat
                                            class="form-control text-end rounded-end"
                                            :class="{
                                                'is-invalid':
                                                    submitted &&
                                                    v$.pl.deposit_bonus
                                                        .next_deposit.percentage
                                                        .$error,
                                            }"
                                            v-model:value="
                                                pl.deposit_bonus.next_deposit
                                                    .percentage
                                            "
                                            :options="{
                                                precision: 2,
                                                prefix: '',
                                                suffix: '%',
                                                decimal: '.',
                                                thousand: ',',
                                                acceptNegative: false,
                                                isInteger: false,
                                            }"></VueNumberFormat>
                                        <div
                                            v-for="(item, index) in v$.pl
                                                .deposit_bonus.next_deposit
                                                .percentage.$errors"
                                            :key="index"
                                            class="invalid-feedback">
                                            <span v-if="item.$message">
                                                {{ $t(item.$message) }}
                                            </span>
                                        </div>
                                    </td>
                                    <th class="bg-light bg-soft">
                                        {{ $t('next deposit maximum points') }}
                                    </th>
                                    <td>
                                        <VueNumberFormat
                                            class="form-control text-end rounded-end"
                                            :class="{
                                                'is-invalid':
                                                    submitted &&
                                                    v$.pl.deposit_bonus
                                                        .next_deposit
                                                        .maximum_points.$error,
                                            }"
                                            v-model:value="
                                                pl.deposit_bonus.next_deposit
                                                    .maximum_points
                                            "
                                            :options="{
                                                precision: 0,
                                                prefix: '',
                                                suffix: '',
                                                thousand: ',',
                                                acceptNegative: false,
                                                isInteger: true,
                                            }"></VueNumberFormat>
                                        <div
                                            v-for="(item, index) in v$.pl
                                                .deposit_bonus.next_deposit
                                                .maximum_points.$errors"
                                            :key="index"
                                            class="invalid-feedback">
                                            <span v-if="item.$message">
                                                {{ $t(item.$message) }}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table
                            v-if="$route.params.section == 'advance'"
                            class="table table-bordered table-wrap table-form">
                            <thead>
                                <tr class="fs-5 bg-light bg-soft">
                                    <th class="text-start" colspan="4">
                                        {{ $t('attendance') }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th class="bg-light bg-soft">
                                        {{ $t('attendance enabled') }}
                                    </th>
                                    <td>
                                        <div class="d-flex gap-2 mt-2">
                                            <b-form-radio
                                                name="attendance enabled"
                                                :value="true"
                                                v-model="pl.attendance.enabled">
                                                {{ $t('yes') }}
                                            </b-form-radio>
                                            <b-form-radio
                                                name="attendance enabled"
                                                :value="false"
                                                v-model="pl.attendance.enabled">
                                                {{ $t('no') }}
                                            </b-form-radio>
                                        </div>
                                    </td>
                                    <th class="bg-light bg-soft">
                                        {{ $t('minimum deposit') }}
                                    </th>
                                    <td>
                                        <VueNumberFormat
                                            class="form-control text-end rounded-end"
                                            :class="{
                                                'is-invalid':
                                                    submitted &&
                                                    v$.pl.attendance
                                                        .minimum_deposit.$error,
                                            }"
                                            v-model:value="
                                                pl.attendance.minimum_deposit
                                            "
                                            :options="{
                                                precision: 0,
                                                prefix: '',
                                                suffix: '',
                                                thousand: ',',
                                                acceptNegative: false,
                                                isInteger: true,
                                            }"></VueNumberFormat>
                                        <div
                                            v-for="(item, index) in v$.pl
                                                .attendance.minimum_deposit
                                                .$errors"
                                            :key="index"
                                            class="invalid-feedback">
                                            <span v-if="item.$message">
                                                {{ $t(item.$message) }}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="bg-light bg-soft">
                                        {{ $t('daily points') }}
                                    </th>
                                    <td>
                                        <VueNumberFormat
                                            class="form-control text-end rounded-end"
                                            :class="{
                                                'is-invalid':
                                                    submitted &&
                                                    v$.pl.attendance
                                                        .daily_points.$error,
                                            }"
                                            v-model:value="
                                                pl.attendance.daily_points
                                            "
                                            :options="{
                                                precision: 0,
                                                prefix: '',
                                                suffix: '',
                                                thousand: ',',
                                                acceptNegative: false,
                                                isInteger: true,
                                            }"></VueNumberFormat>
                                        <div
                                            v-for="(item, index) in v$.pl
                                                .attendance.daily_points
                                                .$errors"
                                            :key="index"
                                            class="invalid-feedback">
                                            <span v-if="item.$message">
                                                {{ $t(item.$message) }}
                                            </span>
                                        </div>
                                    </td>
                                    <th class="bg-light bg-soft">
                                        {{ $t('one week points') }}
                                    </th>
                                    <td>
                                        <VueNumberFormat
                                            class="form-control text-end rounded-end"
                                            :class="{
                                                'is-invalid':
                                                    submitted &&
                                                    v$.pl.attendance
                                                        .one_week_points.$error,
                                            }"
                                            v-model:value="
                                                pl.attendance.one_week_points
                                            "
                                            :options="{
                                                precision: 0,
                                                prefix: '',
                                                suffix: '',
                                                thousand: ',',
                                                acceptNegative: false,
                                                isInteger: true,
                                            }"></VueNumberFormat>
                                        <div
                                            v-for="(item, index) in v$.pl
                                                .attendance.one_week_points
                                                .$errors"
                                            :key="index"
                                            class="invalid-feedback">
                                            <span v-if="item.$message">
                                                {{ $t(item.$message) }}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="bg-light bg-soft">
                                        {{ $t('two week points') }}
                                    </th>
                                    <td>
                                        <VueNumberFormat
                                            class="form-control text-end rounded-end"
                                            :class="{
                                                'is-invalid':
                                                    submitted &&
                                                    v$.pl.attendance
                                                        .two_week_points.$error,
                                            }"
                                            v-model:value="
                                                pl.attendance.two_week_points
                                            "
                                            :options="{
                                                precision: 0,
                                                prefix: '',
                                                suffix: '',
                                                thousand: ',',
                                                acceptNegative: false,
                                                isInteger: true,
                                            }"></VueNumberFormat>
                                        <div
                                            v-for="(item, index) in v$.pl
                                                .attendance.two_week_points
                                                .$errors"
                                            :key="index"
                                            class="invalid-feedback">
                                            <span v-if="item.$message">
                                                {{ $t(item.$message) }}
                                            </span>
                                        </div>
                                    </td>
                                    <th class="bg-light bg-soft">
                                        {{ $t('three week points') }}
                                    </th>
                                    <td>
                                        <VueNumberFormat
                                            class="form-control text-end rounded-end"
                                            :class="{
                                                'is-invalid':
                                                    submitted &&
                                                    v$.pl.attendance
                                                        .three_week_points
                                                        .$error,
                                            }"
                                            v-model:value="
                                                pl.attendance.three_week_points
                                            "
                                            :options="{
                                                precision: 0,
                                                prefix: '',
                                                suffix: '',
                                                thousand: ',',
                                                acceptNegative: false,
                                                isInteger: true,
                                            }"></VueNumberFormat>
                                        <div
                                            v-for="(item, index) in v$.pl
                                                .attendance.three_week_points
                                                .$errors"
                                            :key="index"
                                            class="invalid-feedback">
                                            <span v-if="item.$message">
                                                {{ $t(item.$message) }}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="bg-light bg-soft">
                                        {{ $t('four week points') }}
                                    </th>
                                    <td>
                                        <VueNumberFormat
                                            class="form-control text-end rounded-end"
                                            :class="{
                                                'is-invalid':
                                                    submitted &&
                                                    v$.pl.attendance
                                                        .four_week_points
                                                        .$error,
                                            }"
                                            v-model:value="
                                                pl.attendance.four_week_points
                                            "
                                            :options="{
                                                precision: 0,
                                                prefix: '',
                                                suffix: '',
                                                thousand: ',',
                                                acceptNegative: false,
                                                isInteger: true,
                                            }"></VueNumberFormat>
                                        <div
                                            v-for="(item, index) in v$.pl
                                                .attendance.four_week_points
                                                .$errors"
                                            :key="index"
                                            class="invalid-feedback">
                                            <span v-if="item.$message">
                                                {{ $t(item.$message) }}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <!-- <table v-if="$route.params.section == 'advance'" class="table table-bordered table-wrap table-form">
                                    <thead>
                                        <tr class="fs-5 bg-light bg-soft">
                                            <th
                                                class="text-start"
                                                colspan="6"
                                            >
                                                {{ $t("honorlink") }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th class="bg-light bg-soft">
                                                {{ $t("honorlink enabled") }}
                                            </th>
                                            <td>
                                                <div class="d-flex gap-2 mt-2">
                                                    <b-form-radio
                                                        name="honorlink enabled"
                                                        :value="true"
                                                        v-model="pl.honorlink.enabled"
                                                    >
                                                        {{ $t("yes") }}
                                                    </b-form-radio>
                                                    <b-form-radio
                                                        name="honorlink enabled"
                                                        :value="false"
                                                        v-model="pl.honorlink.enabled"
                                                    >
                                                        {{ $t("no") }}
                                                    </b-form-radio>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <b-collapse :visible="pl.honorlink.enabled">
                                    <table class="table table-bordered table-wrap table-form">
                                        <thead>
                                            <tr class="bg-info bg-gradient">
                                                <th
                                                    class="text-start"
                                                    colspan="6"
                                                >
                                                    {{ $t("casino") }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th class="bg-light bg-soft">
                                                    {{ $t("evolution") }}
                                                </th>
                                                <td>
                                                    <div class="d-flex gap-2 mt-2">
                                                        <b-form-radio
                                                            name="evolution"
                                                            :value="true"
                                                            v-model="pl.honorlink.casino.evolution.enabled"
                                                        >
                                                            {{ $t("enable") }}
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            name="evolution"
                                                            :value="false"
                                                            v-model="pl.honorlink.casino.evolution.enabled"
                                                        >
                                                            {{ $t("disable") }}
                                                        </b-form-radio>
                                                    </div>
                                                </td>
                                                <th class="bg-light bg-soft">
                                                    {{ $t("dreamgame") }}
                                                </th>
                                                <td>
                                                    <div class="d-flex gap-2 mt-2">
                                                        <b-form-radio
                                                            name="dreamgame"
                                                            :value="true"
                                                            v-model="pl.honorlink.casino.dreamgame.enabled"
                                                        >
                                                            {{ $t("enable") }}
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            name="dreamgame"
                                                            :value="false"
                                                            v-model="pl.honorlink.casino.dreamgame.enabled"
                                                        >
                                                            {{ $t("disable") }}
                                                        </b-form-radio>
                                                    </div>
                                                </td>
                                                <th class="bg-light bg-soft">
                                                    {{ $t("wmlive") }}
                                                </th>
                                                <td>
                                                    <div class="d-flex gap-2 mt-2">
                                                        <b-form-radio
                                                            name="wmlive"
                                                            :value="true"
                                                            v-model="pl.honorlink.casino.wmlive.enabled"
                                                        >
                                                            {{ $t("enable") }}
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            name="wmlive"
                                                            :value="false"
                                                            v-model="pl.honorlink.casino.wmlive.enabled"
                                                        >
                                                            {{ $t("disable") }}
                                                        </b-form-radio>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="bg-light bg-soft">
                                                    {{ $t("pragmaticplaylive") }}
                                                </th>
                                                <td>
                                                    <div class="d-flex gap-2 mt-2">
                                                        <b-form-radio
                                                            name="pragmaticplaylive"
                                                            :value="true"
                                                            v-model="pl.honorlink.casino.pragmaticplaylive.enabled"
                                                        >
                                                            {{ $t("enable") }}
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            name="pragmaticplaylive"
                                                            :value="false"
                                                            v-model="pl.honorlink.casino.pragmaticplaylive.enabled"
                                                        >
                                                            {{ $t("disable") }}
                                                        </b-form-radio>
                                                    </div>
                                                </td>
                                                <th class="bg-light bg-soft">
                                                    {{ $t("asiagaming") }}
                                                </th>
                                                <td>
                                                    <div class="d-flex gap-2 mt-2">
                                                        <b-form-radio
                                                            name="asiagaming"
                                                            :value="true"
                                                            v-model="pl.honorlink.casino.asiagaming.enabled"
                                                        >
                                                            {{ $t("enable") }}
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            name="asiagaming"
                                                            :value="false"
                                                            v-model="pl.honorlink.casino.asiagaming.enabled"
                                                        >
                                                            {{ $t("disable") }}
                                                        </b-form-radio>
                                                    </div>
                                                </td>
                                                <th class="bg-light bg-soft">
                                                    {{ $t("bota") }}
                                                </th>
                                                <td>
                                                    <div class="d-flex gap-2 mt-2">
                                                        <b-form-radio
                                                            name="bota"
                                                            :value="true"
                                                            v-model="pl.honorlink.casino.bota.enabled"
                                                        >
                                                            {{ $t("enable") }}
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            name="bota"
                                                            :value="false"
                                                            v-model="pl.honorlink.casino.bota.enabled"
                                                        >
                                                            {{ $t("disable") }}
                                                        </b-form-radio>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="bg-light bg-soft">
                                                    {{ $t("betgamestv") }}
                                                </th>
                                                <td>
                                                    <div class="d-flex gap-2 mt-2">
                                                        <b-form-radio
                                                            name="betgamestv"
                                                            :value="true"
                                                            v-model="pl.honorlink.casino.betgamestv.enabled"
                                                        >
                                                            {{ $t("enable") }}
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            name="betgamestv"
                                                            :value="false"
                                                            v-model="pl.honorlink.casino.betgamestv.enabled"
                                                        >
                                                            {{ $t("disable") }}
                                                        </b-form-radio>
                                                    </div>
                                                </td>
                                                <th class="bg-light bg-soft">
                                                    {{ $t("skywindlive") }}
                                                </th>
                                                <td>
                                                    <div class="d-flex gap-2 mt-2">
                                                        <b-form-radio
                                                            name="skywindlive"
                                                            :value="true"
                                                            v-model="pl.honorlink.casino.skywindlive.enabled"
                                                        >
                                                            {{ $t("enable") }}
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            name="skywindlive"
                                                            :value="false"
                                                            v-model="pl.honorlink.casino.skywindlive.enabled"
                                                        >
                                                            {{ $t("disable") }}
                                                        </b-form-radio>
                                                    </div>
                                                </td>
                                                <th class="bg-light bg-soft">
                                                    {{ $t("demo") }}
                                                </th>
                                                <td>
                                                    <div class="d-flex gap-2 mt-2">
                                                        <b-form-radio
                                                            name="casino demo"
                                                            :value="true"
                                                            v-model="pl.honorlink.casino.demo.enabled"
                                                        >
                                                            {{ $t("enable") }}
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            name="casino demo"
                                                            :value="false"
                                                            v-model="pl.honorlink.casino.demo.enabled"
                                                        >
                                                            {{ $t("disable") }}
                                                        </b-form-radio>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table class="table table-bordered table-wrap table-form">
                                        <thead>
                                            <tr class="bg-info bg-gradient">
                                                <th
                                                    class="text-start"
                                                    colspan="6"
                                                >
                                                    {{ $t("slots") }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th class="bg-light bg-soft">
                                                    {{ $t("pragmaticplaylive") }}
                                                </th>
                                                <td>
                                                    <div class="d-flex gap-2 mt-2">
                                                        <b-form-radio
                                                            name="pragmaticplaylive"
                                                            :value="true"
                                                            v-model="pl.honorlink.slots.pragmaticplaylive.enabled"
                                                        >
                                                            {{ $t("enable") }}
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            name="pragmaticplaylive"
                                                            :value="false"
                                                            v-model="pl.honorlink.slots.pragmaticplaylive.enabled"
                                                        >
                                                            {{ $t("disable") }}
                                                        </b-form-radio>
                                                    </div>
                                                </td>
                                                <th class="bg-light bg-soft">
                                                    {{ $t("boongo") }}
                                                </th>
                                                <td>
                                                    <div class="d-flex gap-2 mt-2">
                                                        <b-form-radio
                                                            name="boongo"
                                                            :value="true"
                                                            v-model="pl.honorlink.slots.boongo.enabled"
                                                        >
                                                            {{ $t("enable") }}
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            name="boongo"
                                                            :value="false"
                                                            v-model="pl.honorlink.slots.boongo.enabled"
                                                        >
                                                            {{ $t("disable") }}
                                                        </b-form-radio>
                                                    </div>
                                                </td>
                                                <th class="bg-light bg-soft">
                                                    {{ $t("cq9") }}
                                                </th>
                                                <td>
                                                    <div class="d-flex gap-2 mt-2">
                                                        <b-form-radio
                                                            name="cq9"
                                                            :value="true"
                                                            v-model="pl.honorlink.slots.cq9.enabled"
                                                        >
                                                            {{ $t("enable") }}
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            name="cq9"
                                                            :value="false"
                                                            v-model="pl.honorlink.slots.cq9.enabled"
                                                        >
                                                            {{ $t("disable") }}
                                                        </b-form-radio>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="bg-light bg-soft">
                                                    {{ $t("habanero") }}
                                                </th>
                                                <td>
                                                    <div class="d-flex gap-2 mt-2">
                                                        <b-form-radio
                                                            name="habanero"
                                                            :value="true"
                                                            v-model="pl.honorlink.slots.habanero.enabled"
                                                        >
                                                            {{ $t("enable") }}
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            name="habanero"
                                                            :value="false"
                                                            v-model="pl.honorlink.slots.habanero.enabled"
                                                        >
                                                            {{ $t("disable") }}
                                                        </b-form-radio>
                                                    </div>
                                                </td>
                                                <th class="bg-light bg-soft">
                                                    {{ $t("redtiger") }}
                                                </th>
                                                <td>
                                                    <div class="d-flex gap-2 mt-2">
                                                        <b-form-radio
                                                            name="redtiger"
                                                            :value="true"
                                                            v-model="pl.honorlink.slots.redtiger.enabled"
                                                        >
                                                            {{ $t("enable") }}
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            name="redtiger"
                                                            :value="false"
                                                            v-model="pl.honorlink.slots.redtiger.enabled"
                                                        >
                                                            {{ $t("disable") }}
                                                        </b-form-radio>
                                                    </div>
                                                </td>
                                                <th class="bg-light bg-soft">
                                                    {{ $t("netent") }}
                                                </th>
                                                <td>
                                                    <div class="d-flex gap-2 mt-2">
                                                        <b-form-radio
                                                            name="netent"
                                                            :value="true"
                                                            v-model="pl.honorlink.slots.netent.enabled"
                                                        >
                                                            {{ $t("enable") }}
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            name="netent"
                                                            :value="false"
                                                            v-model="pl.honorlink.slots.netent.enabled"
                                                        >
                                                            {{ $t("disable") }}
                                                        </b-form-radio>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="bg-light bg-soft">
                                                    {{ $t("playson") }}
                                                </th>
                                                <td>
                                                    <div class="d-flex gap-2 mt-2">
                                                        <b-form-radio
                                                            name="playson"
                                                            :value="true"
                                                            v-model="pl.honorlink.slots.playson.enabled"
                                                        >
                                                            {{ $t("enable") }}
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            name="playson"
                                                            :value="false"
                                                            v-model="pl.honorlink.slots.playson.enabled"
                                                        >
                                                            {{ $t("disable") }}
                                                        </b-form-radio>
                                                    </div>
                                                </td>
                                                <th class="bg-light bg-soft">
                                                    {{ $t("playstar") }}
                                                </th>
                                                <td>
                                                    <div class="d-flex gap-2 mt-2">
                                                        <b-form-radio
                                                            name="playstar"
                                                            :value="true"
                                                            v-model="pl.honorlink.slots.playstar.enabled"
                                                        >
                                                            {{ $t("enable") }}
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            name="playstar"
                                                            :value="false"
                                                            v-model="pl.honorlink.slots.playstar.enabled"
                                                        >
                                                            {{ $t("disable") }}
                                                        </b-form-radio>
                                                    </div>
                                                </td>
                                                <th class="bg-light bg-soft">
                                                    {{ $t("dragoonsoft") }}
                                                </th>
                                                <td>
                                                    <div class="d-flex gap-2 mt-2">
                                                        <b-form-radio
                                                            name="dragoonsoft"
                                                            :value="true"
                                                            v-model="pl.honorlink.slots.dragoonsoft.enabled"
                                                        >
                                                            {{ $t("enable") }}
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            name="dragoonsoft"
                                                            :value="false"
                                                            v-model="pl.honorlink.slots.dragoonsoft.enabled"
                                                        >
                                                            {{ $t("disable") }}
                                                        </b-form-radio>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="bg-light bg-soft">
                                                    {{ $t("blueprintgaming") }}
                                                </th>
                                                <td>
                                                    <div class="d-flex gap-2 mt-2">
                                                        <b-form-radio
                                                            name="blueprintgaming"
                                                            :value="true"
                                                            v-model="pl.honorlink.slots.blueprintgaming.enabled"
                                                        >
                                                            {{ $t("enable") }}
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            name="blueprintgaming"
                                                            :value="false"
                                                            v-model="pl.honorlink.slots.blueprintgaming.enabled"
                                                        >
                                                            {{ $t("disable") }}
                                                        </b-form-radio>
                                                    </div>
                                                </td>
                                                <th class="bg-light bg-soft">
                                                    {{ $t("gameart") }}
                                                </th>
                                                <td>
                                                    <div class="d-flex gap-2 mt-2">
                                                        <b-form-radio
                                                            name="gameart"
                                                            :value="true"
                                                            v-model="pl.honorlink.slots.gameart.enabled"
                                                        >
                                                            {{ $t("enable") }}
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            name="gameart"
                                                            :value="false"
                                                            v-model="pl.honorlink.slots.gameart.enabled"
                                                        >
                                                            {{ $t("disable") }}
                                                        </b-form-radio>
                                                    </div>
                                                </td>
                                                <th class="bg-light bg-soft">
                                                    {{ $t("wazdan") }}
                                                </th>
                                                <td>
                                                    <div class="d-flex gap-2 mt-2">
                                                        <b-form-radio
                                                            name="wazdan"
                                                            :value="true"
                                                            v-model="pl.honorlink.slots.wazdan.enabled"
                                                        >
                                                            {{ $t("enable") }}
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            name="wazdan"
                                                            :value="false"
                                                            v-model="pl.honorlink.slots.wazdan.enabled"
                                                        >
                                                            {{ $t("disable") }}
                                                        </b-form-radio>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="bg-light bg-soft">
                                                    {{ $t("evoplay") }}
                                                </th>
                                                <td>
                                                    <div class="d-flex gap-2 mt-2">
                                                        <b-form-radio
                                                            name="evoplay"
                                                            :value="true"
                                                            v-model="pl.honorlink.slots.evoplay.enabled"
                                                        >
                                                            {{ $t("enable") }}
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            name="evoplay"
                                                            :value="false"
                                                            v-model="pl.honorlink.slots.evoplay.enabled"
                                                        >
                                                            {{ $t("disable") }}
                                                        </b-form-radio>
                                                    </div>
                                                </td>
                                                <th class="bg-light bg-soft">
                                                    {{ $t("playpearls") }}
                                                </th>
                                                <td>
                                                    <div class="d-flex gap-2 mt-2">
                                                        <b-form-radio
                                                            name="playpearls"
                                                            :value="true"
                                                            v-model="pl.honorlink.slots.playpearls.enabled"
                                                        >
                                                            {{ $t("enable") }}
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            name="playpearls"
                                                            :value="false"
                                                            v-model="pl.honorlink.slots.playpearls.enabled"
                                                        >
                                                            {{ $t("disable") }}
                                                        </b-form-radio>
                                                    </div>
                                                </td>
                                                <th class="bg-light bg-soft">
                                                    {{ $t("mobilots") }}
                                                </th>
                                                <td>
                                                    <div class="d-flex gap-2 mt-2">
                                                        <b-form-radio
                                                            name="mobilots"
                                                            :value="true"
                                                            v-model="pl.honorlink.slots.mobilots.enabled"
                                                        >
                                                            {{ $t("enable") }}
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            name="mobilots"
                                                            :value="false"
                                                            v-model="pl.honorlink.slots.mobilots.enabled"
                                                        >
                                                            {{ $t("disable") }}
                                                        </b-form-radio>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="bg-light bg-soft">
                                                    {{ $t("nolimitcity") }}
                                                </th>
                                                <td>
                                                    <div class="d-flex gap-2 mt-2">
                                                        <b-form-radio
                                                            name="nolimitcity"
                                                            :value="true"
                                                            v-model="pl.honorlink.slots.nolimitcity.enabled"
                                                        >
                                                            {{ $t("enable") }}
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            name="nolimitcity"
                                                            :value="false"
                                                            v-model="pl.honorlink.slots.nolimitcity.enabled"
                                                        >
                                                            {{ $t("disable") }}
                                                        </b-form-radio>
                                                    </div>
                                                </td>
                                                <th class="bg-light bg-soft">
                                                    {{ $t("thunderkick") }}
                                                </th>
                                                <td>
                                                    <div class="d-flex gap-2 mt-2">
                                                        <b-form-radio
                                                            name="thunderkick"
                                                            :value="true"
                                                            v-model="pl.honorlink.slots.thunderkick.enabled"
                                                        >
                                                            {{ $t("enable") }}
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            name="thunderkick"
                                                            :value="false"
                                                            v-model="pl.honorlink.slots.thunderkick.enabled"
                                                        >
                                                            {{ $t("disable") }}
                                                        </b-form-radio>
                                                    </div>
                                                </td>
                                                <th class="bg-light bg-soft">
                                                    {{ $t("demo") }}
                                                </th>
                                                <td>
                                                    <div class="d-flex gap-2 mt-2">
                                                        <b-form-radio
                                                            name="slots demo"
                                                            :value="true"
                                                            v-model="pl.honorlink.slots.demo.enabled"
                                                        >
                                                            {{ $t("enable") }}
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            name="slots demo"
                                                            :value="false"
                                                            v-model="pl.honorlink.slots.demo.enabled"
                                                        >
                                                            {{ $t("disable") }}
                                                        </b-form-radio>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </b-collapse> -->
                    </div>

                    <div class="text-center mt-3">
                        <button
                            type="submit"
                            class="btn btn-danger btn-lg w-25">
                            {{ $t('save') }}
                        </button>
                    </div>
                </b-form>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import SettingsTabs from '@/components/settings-tabs';
import LoadingTableForm from '@/components/loading-table-form';

import Switches from 'vue-switches';
import { mapActions, mapGetters } from 'vuex';
import datetime from '../../../mixins/datetime';
import number from '../../../mixins/number';
import string from '../../../mixins/string';
import ui from '@/mixins/ui';
import convert from '../../../mixins/convert';
import {
    required,
    requiredIf,
    minLength,
    maxLength,
    minValue,
    maxValue,
    sameAs,
    email,
    helpers,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import Multiselect from '@vueform/multiselect';
import Swal from 'sweetalert2';
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        Layout,
        PageHeader,
        Switches,
        Multiselect,
        LoadingTableForm,
        SettingsTabs,
    },
    mixins: [datetime, number, string, convert, ui],
    data() {
        return {
            quillOptions: {
                contentOptions: {
                    contentType: 'text/plain', // Set the desired contentType to 'text/plain'
                },
            },
            title: null,
            submitted: false,
            loading: false,
            bank_account_fields: [],
            level_options: [
                { name: this.$t('level 1'), value: 1 },
                { name: this.$t('level 2'), value: 2 },
                { name: this.$t('level 3'), value: 3 },
                { name: this.$t('level 4'), value: 4 },
                { name: this.$t('level 5'), value: 5 },
                { name: this.$t('level 6'), value: 6 },
                { name: this.$t('level 7'), value: 7 },
                { name: this.$t('level 8'), value: 8 },
                { name: this.$t('level 9'), value: 9 },
                { name: this.$t('level 10'), value: 10 },
            ],
            used_level_options: [],
            pl: {
                site: {
                    title: null,
                    open_message: {
                        content: null,
                        enabled: false,
                    },
                    welcome_message: {
                        content: null,
                        enabled: false,
                    },
                    maintenance: false,
                    maintenance_message: {
                        content: null,
                        enabled: false,
                    },
                    menu: {
                        table: null,
                        slots: null,
                    },
                    table: {
                        minimum_amount: 0,
                        maximum_amount: 0,
                    },
                    slots: {
                        minimum_amount: 0,
                        maximum_amount: 0,
                    },
                },
                transaction: {
                    enabled: true,
                    minimum_deposit: 0,
                    minimum_withdraw: 0,
                    use_password: true,
                    use_pin: false,
                    level_one: {
                        bank_name: null,
                        account_holder: null,
                        account_number: null,
                    },
                    level_two: {
                        bank_name: null,
                        account_holder: null,
                        account_number: null,
                    },
                    level_three: {
                        bank_name: null,
                        account_holder: null,
                        account_number: null,
                    },
                    level_four: {
                        bank_name: null,
                        account_holder: null,
                        account_number: null,
                    },
                    level_five: {
                        bank_name: null,
                        account_holder: null,
                        account_number: null,
                    },
                    level_six: {
                        bank_name: null,
                        account_holder: null,
                        account_number: null,
                    },
                    level_seven: {
                        bank_name: null,
                        account_holder: null,
                        account_number: null,
                    },
                    level_eight: {
                        bank_name: null,
                        account_holder: null,
                        account_number: null,
                    },
                    level_nine: {
                        bank_name: null,
                        account_holder: null,
                        account_number: null,
                    },
                    level_ten: {
                        bank_name: null,
                        account_holder: null,
                        account_number: null,
                    },
                },
                registration: {
                    bonus_points: 0,
                    welcome_message: {
                        content: null,
                        enabled: false,
                    },
                },
                deposit_bonus: {
                    enabled: true,
                    minimum_deposit: 0,
                    first_deposit: {
                        percentage: 0,
                        maximum_points: 0,
                    },
                    next_deposit: {
                        percentage: 0,
                        maximum_points: 0,
                    },
                },
                attendance: {
                    enabled: false,
                    minimum_deposit: 0,
                    daily_points: 0,
                    one_week_points: 0,
                    two_week_points: 0,
                    three_week_points: 0,
                    four_week_points: 0,
                },
                player_commission: {
                    enabled: false,
                    casino: {
                        enabled: true,
                        total: 0,
                        level_one: 0,
                        level_two: 0,
                        level_three: 0,
                        level_four: 0,
                        level_five: 0,
                        level_six: 0,
                        level_seven: 0,
                        level_eight: 0,
                        level_nine: 0,
                        level_ten: 0,
                    },
                },
                honorlink: {
                    enabled: false,
                    casino: {
                        evolution: {
                            enabled: false,
                        },
                        dreamgame: {
                            enabled: false,
                        },
                        wmlive: {
                            enabled: false,
                        },
                        pragmaticplaylive: {
                            enabled: false,
                        },
                        asiagaming: {
                            enabled: false,
                        },
                        bota: {
                            enabled: false,
                        },
                        betgamestv: {
                            enabled: false,
                        },
                        skywindlive: {
                            enabled: false,
                        },
                        demo: {
                            enabled: false,
                        },
                    },
                    slots: {
                        pragmaticplay: {
                            enabled: false,
                        },
                        boongo: {
                            enabled: false,
                        },
                        cq9: {
                            enabled: false,
                        },
                        habanero: {
                            enabled: false,
                        },
                        redtiger: {
                            enabled: false,
                        },
                        netent: {
                            enabled: false,
                        },
                        playson: {
                            enabled: false,
                        },
                        playstar: {
                            enabled: false,
                        },
                        dragoonsoft: {
                            enabled: false,
                        },
                        blueprintgaming: {
                            enabled: false,
                        },
                        gameart: {
                            enabled: false,
                        },
                        wazdan: {
                            enabled: false,
                        },
                        evoplay: {
                            enabled: false,
                        },
                        playpearls: {
                            enabled: false,
                        },
                        mobilots: {
                            enabled: false,
                        },
                        nolimitcity: {
                            enabled: false,
                        },
                        thunderkick: {
                            enabled: false,
                        },
                        demo: {
                            enabled: false,
                        },
                    },
                },
            },
            pl_origin: {},
        };
    },
    validations() {
        return {
            pl: {
                site: {
                    title: {},
                    open_message: {
                        content: {},
                    },
                    welcome_message: {
                        content: {},
                    },
                    maintenance_message: {
                        content: {},
                    },
                    menu: {
                        table: {},
                        slots: {},
                    },
                    table: {
                        minimum_amount: {
                            maxValueValue: helpers.withMessage(
                                'must not be greater than 100,000,000',
                                maxValue(100000000)
                            ),
                        },
                        maximum_amount: {
                            maxValueValue: helpers.withMessage(
                                'must not be greater than 100,000,000',
                                maxValue(100000000)
                            ),
                        },
                    },
                    slots: {
                        minimum_amount: {
                            maxValueValue: helpers.withMessage(
                                'must not be greater than 100,000,000',
                                maxValue(100000000)
                            ),
                        },
                        maximum_amount: {
                            maxValueValue: helpers.withMessage(
                                'must not be greater than 100,000,000',
                                maxValue(100000000)
                            ),
                        },
                    },
                },
                transaction: {
                    minimum_deposit: {
                        maxValueValue: helpers.withMessage(
                            'the maximum value is 9,999,999,999',
                            maxValue(9999999999)
                        ),
                    },
                    minimum_withdraw: {
                        maxValueValue: helpers.withMessage(
                            'the maximum value is 9,999,999,999',
                            maxValue(9999999999)
                        ),
                    },
                },
                registration: {
                    bonus_points: {
                        maxValueValue: helpers.withMessage(
                            'the maximum value is 9,999,999,999',
                            maxValue(9999999999)
                        ),
                    },
                },
                player_commission: {
                    casino: {
                        total: {
                            maxValueValue: helpers.withMessage(
                                'the maximum value is 9,999',
                                maxValue(9999)
                            ),
                        },
                        level_one: {
                            maxValueValue: helpers.withMessage(
                                'the maximum value is 100',
                                maxValue(100)
                            ),
                        },
                        level_two: {
                            maxValueValue: helpers.withMessage(
                                'the maximum value is 100',
                                maxValue(100)
                            ),
                        },
                        level_three: {
                            maxValueValue: helpers.withMessage(
                                'the maximum value is 100',
                                maxValue(100)
                            ),
                        },
                        level_four: {
                            maxValueValue: helpers.withMessage(
                                'the maximum value is 100',
                                maxValue(100)
                            ),
                        },
                        level_five: {
                            maxValueValue: helpers.withMessage(
                                'the maximum value is 100',
                                maxValue(100)
                            ),
                        },
                        level_six: {
                            maxValueValue: helpers.withMessage(
                                'the maximum value is 100',
                                maxValue(100)
                            ),
                        },
                        level_seven: {
                            maxValueValue: helpers.withMessage(
                                'the maximum value is 100',
                                maxValue(100)
                            ),
                        },
                        level_eight: {
                            maxValueValue: helpers.withMessage(
                                'the maximum value is 100',
                                maxValue(100)
                            ),
                        },
                        level_nine: {
                            maxValueValue: helpers.withMessage(
                                'the maximum value is 100',
                                maxValue(100)
                            ),
                        },
                        level_ten: {
                            maxValueValue: helpers.withMessage(
                                'the maximum value is 100',
                                maxValue(100)
                            ),
                        },
                    },
                },
                deposit_bonus: {
                    minimum_deposit: {
                        maxValueValue: helpers.withMessage(
                            'the maximum value is 9,999,999,999',
                            maxValue(9999999999)
                        ),
                    },
                    first_deposit: {
                        percentage: {
                            maxValueValue: helpers.withMessage(
                                'the maximum value is 100',
                                maxValue(100)
                            ),
                        },
                        maximum_points: {
                            maxValueValue: helpers.withMessage(
                                'the maximum value is 9,999,999,999',
                                maxValue(9999999999)
                            ),
                        },
                    },
                    next_deposit: {
                        percentage: {
                            maxValueValue: helpers.withMessage(
                                'the maximum value is 100',
                                maxValue(100)
                            ),
                        },
                        maximum_points: {
                            maxValueValue: helpers.withMessage(
                                'the maximum value is 9,999,999,999',
                                maxValue(9999999999)
                            ),
                        },
                    },
                },
                attendance: {
                    minimum_deposit: {},
                    daily_points: {},
                    one_week_points: {},
                    two_week_points: {},
                    three_week_points: {},
                    four_week_points: {},
                },
            },
        };
    },
    watch: {
        bank_account_fields: {
            handler(newBankFields, oldBankFields) {
                console.log(newBankFields);
                console.log(oldBankFields);
                newBankFields.forEach((bank_field) => {
                    var level = this.stringifyLevel(bank_field.level);
                    this.pl['transaction'][level]['bank_name'] =
                        bank_field.bank_name;
                    this.pl['transaction'][level]['account_holder'] =
                        bank_field.account_holder;
                    this.pl['transaction'][level]['account_number'] =
                        bank_field.account_number;
                });
                // for (var level = 1; level <= 10; level++) {
                //     var i = this.getIndexByPropertyValue(this.bank_account_fields, 'level', level)
                //     var str_level = this.stringifyLevel(level)
                //     this.pl['transaction'][str_level]['bank_name'] = i > -1 ? this.bank_account_fields[i]['bank_name'] : null
                //     this.pl['transaction'][str_level]['account_holder'] = i > -1 ? this.bank_account_fields[i]['account_holder'] : null
                //     this.pl['transaction'][str_level]['account_number'] = i > -1 ? this.bank_account_fields[i]['account_number'] : null
                // }
            },
            deep: true,
        },
    },
    computed: {
        usable_level_options() {
            var arr = [];
            this.level_options.forEach((level) => {
                if (!this.used_level_options.includes(level.value)) {
                    arr.push(level);
                }
            });
            return arr;
        },
        forms() {
            var m_obj = this.objectToFormData(this.pl);
            var o_obj = this.objectToFormData(this.pl_origin);
            var m_fd = this.formDataToObject(m_obj);
            var o_fd = this.formDataToObject(o_obj);
            var f_fd = new FormData();
            var rows = '';
            for (var key in m_fd) {
                if (m_fd.hasOwnProperty(key)) {
                    var m_value =
                        m_fd[key] == 'true'
                            ? '1'
                            : m_fd[key] == 'false'
                            ? '0'
                            : m_fd[key] == ''
                            ? null
                            : m_fd[key];
                    var o_value =
                        o_fd[key] == 'true'
                            ? '1'
                            : o_fd[key] == 'false'
                            ? '0'
                            : o_fd[key] == ''
                            ? null
                            : o_fd[key];
                    var bool_values = [
                        ['inactive', 'active'],
                        ['disabled', 'enabled'],
                    ];
                    var bool_value =
                        key == 'status' ? bool_values[0] : bool_values[1];
                    // EDIT MODE
                    if (m_fd[key] != o_fd[key]) {
                        f_fd.append(key, m_value);
                        rows += `<tr>
                                    <td>${this.arrayToArrows(key)}</td>
                                    <td class="${
                                        o_fd[key] == 'true'
                                            ? 'text-primary'
                                            : o_fd[key] == 'false'
                                            ? 'text-secondary'
                                            : ''
                                    }">
                                        ${
                                            o_fd[key] == 'true'
                                                ? bool_value[1]
                                                : o_fd[key] == 'false'
                                                ? bool_value[0]
                                                : o_fd[key] == null
                                                ? ''
                                                : this.$t(o_fd[key]) || ''
                                        }
                                    </td>
                                    <td class="${
                                        m_fd[key] == 'true'
                                            ? 'text-primary'
                                            : m_fd[key] == 'false'
                                            ? 'text-secondary'
                                            : ''
                                    }">
                                        ${
                                            m_fd[key] == 'true'
                                                ? bool_value[1]
                                                : m_fd[key] == 'false'
                                                ? bool_value[0]
                                                : m_fd[key] == null
                                                ? ''
                                                : this.$t(m_fd[key]) || ''
                                        }
                                    </td>
                                </tr>`;
                        // rows += `<tr>
                        //             <td>${this.arrayToArrows(key)}</td>
                        //             <td class="${
                        //                 o_fd[key] == 'true'
                        //                     ? 'text-primary'
                        //                     : o_fd[key] == 'false'
                        //                     ? 'text-secondary'
                        //                     : ''
                        //             }">
                        //                 ${
                        //                     o_fd[key] == 'true'
                        //                         ? bool_value[1]
                        //                         : o_fd[key] == 'false'
                        //                         ? bool_value[0]
                        //                         : this.$t(o_fd[key]) || ''
                        //                 }
                        //             </td>
                        //             <td class="${
                        //                 m_fd[key] == 'true'
                        //                     ? 'text-primary'
                        //                     : m_fd[key] == 'false'
                        //                     ? 'text-secondary'
                        //                     : ''
                        //             }">
                        //                 ${
                        //                     m_fd[key] == 'true'
                        //                         ? bool_value[1]
                        //                         : m_fd[key] == 'false'
                        //                         ? bool_value[0]
                        //                         : this.$t(m_fd[key]) || ''
                        //                 }
                        //             </td>
                        //         </tr>`;
                    }
                }
            }

            return {
                obj_array: this.formDataToObject(f_fd),
                form_data: f_fd,
                x_www_form_urlencoded: this.formDataToUrlEncoded(f_fd),
                changes: rows,
                original: o_fd,
                modified: m_fd,
            };
        },
    },
    methods: {
        ...mapActions('setting', {
            settingGetList: 'getList',
            settingUpdate: 'update',
        }),
        // hasPropertyWithValue(arr, prop, value) {
        //     return arr.some(obj => obj.hasOwnProperty(prop) && obj[prop] === value);
        // },
        getIndexByPropertyValue(arr, prop, value) {
            return arr.findIndex(
                (obj) => obj.hasOwnProperty(prop) && obj[prop] === value
            );
        },
        async submitForm() {
            if (!this.isAuthorized()) return;
            this.submitted = true;
            this.v$.pl.$touch();
            if (this.v$.pl.$invalid) {
                return;
            } else {
                var swal_html = `
                        <table class="table table-sm table-bordered table-nowrap fs-6">
                            <thead>
                                <tr>
                                    <th>${this.$t('field')}</th>
                                    <th>${this.$t('before')}</th>
                                    <th>${this.$t('after')}</th>
                                </tr>
                            </thead>
                            <tbody>${this.forms.changes}</tbody>
                        </table>
                    `;
                if (!this.forms.changes) {
                    Swal.fire({
                        icon: 'info',
                        title: this.$t('no changes made'),
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    return;
                }

                const confirmed = await Swal.fire({
                    title: this.$t('do you want to modify the information?'),
                    // html: swal_html,
                    icon: 'info',
                    // width: 800,
                    showCancelButton: true,
                    confirmButtonColor: '#f1b44c',
                    cancelButtonColor: '#74788d',
                    cancelButtonText: this.$t('cancel'),
                    confirmButtonText: this.$t('confirm'),
                }).then(async (result) => {
                    return result.value;
                });

                if (confirmed) {
                    const res = await this.settingUpdate({
                        form_data: this.forms.form_data,
                        // id: this._id,
                    });

                    if (res) {
                        var field_rows = ``;
                        var errors = !res.success
                            ? this.convertObjectToArray(res.data.errors)
                            : [];
                        errors.forEach((e) => {
                            var field_value_rows = ``;
                            e.errors.forEach((err) => {
                                field_value_rows += `<div class="text-danger">${err.toLowerCase()}</div>`;
                            });
                            field_rows += `
                            <tr>
                                <td>${this.$t(e.field)}</td>
                                <td>${field_value_rows}</td>
                            </tr>
                            `;
                        });

                        var errors_html = `
                            <table class="table table-sm table-bordered fs-6">
                                <thead>
                                    <tr>
                                        <th>${this.$t('field')}</th>
                                        <th>${this.$t('validation')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    ${field_rows}
                                </tbody>
                            </table>
                        `;

                        Swal.fire({
                            title: res.success
                                ? this.$t('settings has been updated')
                                : this.$t('updating failed'),
                            html: res.success ? '' : errors_html,
                            icon: res.success ? 'success' : 'error',
                            width: res.success ? null : 800,
                            timer: res.success ? 2000 : null,
                            confirmButtonText: this.$t('ok'),
                        });
                        if (res.success) {
                            this.initSettings();
                        }
                    }
                }
            }
        },
        async initSettings() {
            this.loading = true;
            this.v$.pl.$reset();
            const data = await this.settingGetList({});
            if (data[0]) {
                this.pl.site.title = data[0].site.title;
                this.pl.site.open_message.content =
                    data[0].site.open_message.content || null;
                this.pl.site.open_message.enabled =
                    data[0].site.open_message.enabled;
                this.pl.site.welcome_message.content =
                    data[0].site.welcome_message.content || null;
                this.pl.site.welcome_message.enabled =
                    data[0].site.welcome_message.enabled;
                this.pl.site.maintenance_message.content =
                    data[0].site.maintenance_message.content || null;
                this.pl.site.maintenance_message.enabled =
                    data[0].site.maintenance_message.enabled;
                this.pl.site.menu.table = data[0].site.menu.table;
                this.pl.site.menu.slots = data[0].site.menu.slots;

                this.pl.site.table.maximum_amount =
                    data[0].site.table.maximum_amount;
                this.pl.site.table.minimum_amount =
                    data[0].site.table.minimum_amount;
                this.pl.site.slots.maximum_amount =
                    data[0].site.slots.maximum_amount;
                this.pl.site.slots.minimum_amount =
                    data[0].site.slots.minimum_amount;
                this.pl.transaction.minimum_deposit =
                    data[0].transaction.minimum_deposit;
                this.pl.transaction.minimum_withdraw =
                    data[0].transaction.minimum_withdraw;
                this.pl.transaction.use_password =
                    data[0].transaction.use_password;
                this.pl.transaction.use_pin = data[0].transaction.use_pin;

                this.bank_account_fields = [];
                for (var level = 1; level <= 10; level++) {
                    var str_level = this.stringifyLevel(level);
                    this.pl['transaction'][str_level]['bank_name'] =
                        data[0]['transaction'][str_level]['bank_name'];
                    this.pl['transaction'][str_level]['account_holder'] =
                        data[0]['transaction'][str_level]['account_holder'];
                    this.pl['transaction'][str_level]['account_number'] =
                        data[0]['transaction'][str_level]['account_number'];

                    this.pl['player_commission']['casino'][str_level] =
                        data[0]['player_commission']['casino'][str_level];
                    if (
                        data[0]['transaction'][str_level]['bank_name'] ||
                        data[0]['transaction'][str_level]['account_holder'] ||
                        data[0]['transaction'][str_level]['account_number']
                    ) {
                        this.renderBankAccountField(
                            level,
                            data[0]['transaction'][str_level]
                        );
                    }
                }

                this.pl.registration.bonus_points =
                    data[0].registration.bonus_points;
                this.pl.registration.welcome_message.content =
                    data[0].registration.welcome_message.content || null;
                this.pl.registration.welcome_message.enabled =
                    data[0].registration.welcome_message.enabled;

                this.pl.player_commission.enabled =
                    data[0].player_commission.enabled;
                this.pl.player_commission.casino.enabled =
                    data[0].player_commission.casino.enabled;
                this.pl.player_commission.casino.total =
                    data[0].player_commission.casino.total;

                this.pl.honorlink.enabled = data[0].honorlink.enabled;
                this.pl.honorlink.casino.evolution.enabled =
                    data[0].honorlink.casino.evolution.enabled;
                this.pl.honorlink.casino.dreamgame.enabled =
                    data[0].honorlink.casino.dreamgame.enabled;
                this.pl.honorlink.casino.wmlive.enabled =
                    data[0].honorlink.casino.wmlive.enabled;
                this.pl.honorlink.casino.pragmaticplaylive.enabled =
                    data[0].honorlink.casino.pragmaticplaylive.enabled;
                this.pl.honorlink.casino.asiagaming.enabled =
                    data[0].honorlink.casino.asiagaming.enabled;
                this.pl.honorlink.casino.bota.enabled =
                    data[0].honorlink.casino.bota.enabled;
                this.pl.honorlink.casino.betgamestv.enabled =
                    data[0].honorlink.casino.betgamestv.enabled;
                this.pl.honorlink.casino.skywindlive.enabled =
                    data[0].honorlink.casino.skywindlive.enabled;
                this.pl.honorlink.casino.demo.enabled =
                    data[0].honorlink.casino.demo.enabled;
                this.pl.honorlink.slots.pragmaticplay.enabled =
                    data[0].honorlink.slots.pragmaticplay.enabled;
                this.pl.honorlink.slots.boongo.enabled =
                    data[0].honorlink.slots.boongo.enabled;
                this.pl.honorlink.slots.cq9.enabled =
                    data[0].honorlink.slots.cq9.enabled;
                this.pl.honorlink.slots.habanero.enabled =
                    data[0].honorlink.slots.habanero.enabled;
                this.pl.honorlink.slots.redtiger.enabled =
                    data[0].honorlink.slots.redtiger.enabled;
                this.pl.honorlink.slots.netent.enabled =
                    data[0].honorlink.slots.netent.enabled;
                this.pl.honorlink.slots.playson.enabled =
                    data[0].honorlink.slots.playson.enabled;
                this.pl.honorlink.slots.playstar.enabled =
                    data[0].honorlink.slots.playstar.enabled;
                this.pl.honorlink.slots.dragoonsoft.enabled =
                    data[0].honorlink.slots.dragoonsoft.enabled;
                this.pl.honorlink.slots.blueprintgaming.enabled =
                    data[0].honorlink.slots.blueprintgaming.enabled;
                this.pl.honorlink.slots.gameart.enabled =
                    data[0].honorlink.slots.gameart.enabled;
                this.pl.honorlink.slots.wazdan.enabled =
                    data[0].honorlink.slots.wazdan.enabled;
                this.pl.honorlink.slots.evoplay.enabled =
                    data[0].honorlink.slots.evoplay.enabled;
                this.pl.honorlink.slots.playpearls.enabled =
                    data[0].honorlink.slots.playpearls.enabled;
                this.pl.honorlink.slots.mobilots.enabled =
                    data[0].honorlink.slots.mobilots.enabled;
                this.pl.honorlink.slots.nolimitcity.enabled =
                    data[0].honorlink.slots.nolimitcity.enabled;
                this.pl.honorlink.slots.thunderkick.enabled =
                    data[0].honorlink.slots.thunderkick.enabled;
                this.pl.honorlink.slots.demo.enabled =
                    data[0].honorlink.slots.demo.enabled;

                this.pl.deposit_bonus.enabled = data[0].deposit_bonus.enabled;
                this.pl.deposit_bonus.minimum_deposit =
                    data[0].deposit_bonus.minimum_deposit;
                this.pl.deposit_bonus.first_deposit.percentage =
                    data[0].deposit_bonus.first_deposit.percentage;
                this.pl.deposit_bonus.first_deposit.maximum_points =
                    data[0].deposit_bonus.first_deposit.maximum_points;
                this.pl.deposit_bonus.next_deposit.percentage =
                    data[0].deposit_bonus.next_deposit.percentage;
                this.pl.deposit_bonus.next_deposit.maximum_points =
                    data[0].deposit_bonus.next_deposit.maximum_points;

                this.pl.attendance.enabled = data[0].attendance.enabled;
                this.pl.attendance.minimum_deposit =
                    data[0].attendance.minimum_deposit;
                this.pl.attendance.daily_points =
                    data[0].attendance.daily_points;
                this.pl.attendance.one_week_points =
                    data[0].attendance.one_week_points;
                this.pl.attendance.two_week_points =
                    data[0].attendance.two_week_points;
                this.pl.attendance.three_week_points =
                    data[0].attendance.three_week_points;
                this.pl.attendance.four_week_points =
                    data[0].attendance.four_week_points;

                this.pl.transaction.use_password =
                    data[0].transaction.use_password;
                this.pl.transaction.use_pin = data[0].transaction.use_pin;
                this.pl.transaction.enabled = data[0].transaction.enabled;

                this.pl_origin = JSON.parse(JSON.stringify(data[0]));
            }

            this.loading = false;
        },
        addBankAccountField(e) {
            if (!e.target.value) return;
            this.used_level_options.push(parseInt(e.target.value));
            this.bank_account_fields.push({
                level: parseInt(e.target.value),
                bank_name: null,
                account_holder: null,
                account_number: null,
            });
            this.bank_account_fields = this.bank_account_fields.sort(
                (a, b) => a.level - b.level
            );
        },
        removeBankAccountField(b_index) {
            var used_index = this.used_level_options.indexOf(
                this.bank_account_fields[b_index].level
            );
            var b_level = this.stringifyLevel(
                this.bank_account_fields[b_index].level
            );
            this.pl['transaction'][b_level]['bank_name'] = '';
            this.pl['transaction'][b_level]['account_holder'] = '';
            this.pl['transaction'][b_level]['account_number'] = '';
            this.used_level_options.splice(used_index, 1);
            this.bank_account_fields.splice(b_index, 1);
        },
        renderBankAccountField(level, obj) {
            if (
                obj.bank_name == 'null' &&
                obj.account_holder == 'null' &&
                obj.account_number == 'null'
            )
                return;
            this.used_level_options.push(level);
            this.bank_account_fields.push({
                level: level,
                bank_name: obj.bank_name == 'null' ? null : obj.bank_name,
                account_holder:
                    obj.account_holder == 'null' ? null : obj.account_holder,
                account_number:
                    obj.account_number == 'null' ? null : obj.account_number,
            });
            // this.bank_account_fields = this.bank_account_fields.sort((a, b) => a.level - b.level);
        },
        stringifyLevel(level) {
            var str = `level_`;
            switch (level) {
                case 1:
                    str += `one`;
                    break;
                case 2:
                    str += `two`;
                    break;
                case 3:
                    str += `three`;
                    break;
                case 4:
                    str += `four`;
                    break;
                case 5:
                    str += `five`;
                    break;
                case 6:
                    str += `six`;
                    break;
                case 7:
                    str += `seven`;
                    break;
                case 8:
                    str += `eight`;
                    break;
                case 9:
                    str += `nine`;
                    break;
                case 10:
                    str += `ten`;
                    break;
            }
            return str;
        },
    },
    mounted() {
        this.initSettings();
    },
};
</script>
