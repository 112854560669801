<template>
    <Layout>
        <div class="d-flex justify-content-between align-items-center mb-1">
            <h4 class="mb-0 font-size-18 fw-bold">
                {{ $t(`player list`) }}
            </h4>
            <div class="button-items" v-if="!$route.query.window">
                <button
                    :class="`btn btn-primary ${!loading ? '' : 'disabled'}`"
                    @click="goToBatchCreateForm()">
                    {{ $t('multiple create') }}
                </button>
                <button
                    :class="`btn btn-primary ${!loading ? '' : 'disabled'}`"
                    @click="goToUserForm(null, 'player')">
                    {{ $t('create member') }}
                </button>
            </div>
        </div>

        <div class="card data">
            <div class="card-header bg-light bg-soft filters">
                <b-form inline>
                    <table
                        class="table table-bordered table-nowrap mb-0 table-form"
                        v-if="agents_options.length">
                        <tbody>
                            <tr v-if="!$route.query.window">
                                <td
                                    class="text-center hidden"
                                    style="width: 5%">
                                    {{ $t('agent') }}
                                </td>
                                <td class="bg-white">
                                    <div
                                        class="d-flex justify-content-start gap-1 flex-wrap">
                                        <multiselect
                                            style="flex: 0 1 150px"
                                            class="m-0 multiselect-sm"
                                            v-for="(
                                                agents, i
                                            ) in agents_options"
                                            :key="i"
                                            :placeholder="
                                                agents.length
                                                    ? $t(`select referrer`)
                                                    : $t(`no sub agents`)
                                            "
                                            :options="agents"
                                            v-model="referrer_ids[i]"
                                            @change="handleFilter"
                                            @clear="handleClear(i)"
                                            :can-deselect="false"
                                            :searchable="true"
                                            :clear-on-blur="true">
                                            <template
                                                v-slot:singlelabel="{ value }">
                                                <div
                                                    class="mlabel flex-fill ps-2">
                                                    <span :class="`lvl lvl-${value.value.agent.level}`">{{$t(`level ${value.value.agent.level}`)}}</span>
                                                    <!-- <i :class="`mdi mdi-numeric-${value.value.agent.level}-box fs-5  level-${value.value.agent.level}`"></i> -->
                                                    <span>{{
                                                        value.label
                                                    }}</span>
                                                </div>
                                            </template>
                                            <template
                                                v-slot:option="{ option }">
                                                <div>
                                                    <span :class="`lvl lvl-${option.value.agent.level}`">{{$t(`level ${option.value.agent.level}`)}}</span>
                                                    <!-- <i :class="`mdi mdi-numeric-${option.value.agent.level}-box fs-5  level-${option.value.agent.level}`"></i> -->
                                                    <span>{{
                                                        option.value.username
                                                    }}</span>
                                                </div>
                                            </template>
                                        </multiselect>
                                        <button
                                            class="btn btn-secondary btn-sm"
                                            @click="handleClear(0)">
                                            {{ $t('clear') }}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table v-if="!$route.query.window"
                        class="table table-bordered table-nowrap mb-0 table-form">
                        <tbody>
                            <!-- <tr v-if="filters.levels.length">
                                        <td></td>
                                        <td>
                                            <div class="d-flex gap-1 ">
                                                <i
                                                    :class="`mdi mdi-numeric-${l}-box level-${l}`"
                                                    v-for="l in filters.levels"
                                                    :key="l"
                                                ></i>

                                            </div>
                                        </td>
                                    </tr> -->
                            <tr>
                                <!-- <td
                                 
                                    :class="`${
                                        $route.query.window
                                            ? 'text-start'
                                            : 'text-center'
                                    }`"
                                    style="width:15%">
                                   
                                </td> -->
                                <td
                                    class="text-center"
                                    colspan="1"
                                    v-if="client_name == 'dreamssolution'">
                                    {{ $t('date') }}
                                </td>
                                <td
                                    class="bg-white"
                                    colspan="3"
                                    v-if="client_name == 'dreamssolution'">
                                    <div class="d-flex gap-1">
                                        <datepicker
                                            v-model="filters.from"
                                            :first-day-of-week="0"
                                            lang="en"
                                            confirm
                                            class="form-control bg-white"></datepicker>

                                        <label class="input-group-text"
                                            >~</label
                                        >
                                        <datepicker
                                            v-model="filters.to"
                                            :first-day-of-week="0"
                                            lang="en"
                                            confirm
                                            class="form-control bg-white"></datepicker>
                                        <button
                                            class="btn btn-outline-info flex-grow-1"
                                            @click="setDay('today')">
                                            {{ $t('today') }}
                                        </button>
                                        <button
                                            class="btn btn-outline-success flex-grow-1"
                                            @click="setDay('yesterday')">
                                            {{ $t('yesterday') }}
                                        </button>
                                    </div>
                                </td>
                                <td
                                    class="text-center hidden"
                                    v-if="!$route.query.window">
                                    {{ $t('level') }}
                                </td>
                                <td
                                    class="bg-white"
                                    v-if="!$route.query.window">
                                    <multiselect
                                        class="custom-multiselect"
                                        :placeholder="`${$t('select level')}`"
                                        v-model="filters.levels"
                                        :options="level_options"
                                        :close-on-select="false"
                                        :hide-selected="false"
                                        :mode="'tags'"
                                        label="name">
                                        <template
                                            v-slot:tag="{
                                                option,
                                                handleTagRemove,
                                            }">
                                            <i
                                                :class="`mdi mdi-numeric-${option.value}-box level-${option.value} fs-4`"
                                                @click="
                                                    handleTagRemove(
                                                        option,
                                                        $event
                                                    )
                                                "></i>
                                        </template>
                                        <template v-slot:option="{ option }">
                                            {{
                                                $t('level') + ' ' + option.value
                                            }}
                                        </template>
                                    </multiselect>
                                </td>
                                <td class="text-center">
                                    {{ $t('status') }}
                                </td>
                                <td class="bg-white" style="width: 15%">
                                    <multiselect
                                        :placeholder="`${$t('select status')}`"
                                        v-model="filters.status"
                                        :options="status_options"
                                        label="name">
                                        <template
                                            v-slot:singlelabel="{ value }">
                                            <span class="flex-fill ps-2">{{
                                                $t(value.value)
                                            }}</span>
                                        </template>
                                        <template v-slot:option="{ option }">
                                            {{ $t(option.value) }}
                                        </template>
                                    </multiselect>
                                </td>

                                <!-- <td class="text-center">
                                            {{ $t('username') }}
                                        </td>
                                        <td class="bg-white">
                                            <input
                                                type="text"
                                                class="form-control"
                                                :placeholder="
                                                        $t(`enter exact username`)
                                                    "
                                                v-model="filters.username"
                                            />
                                        </td>-->
                            </tr>
                            <tr>
                                <td
                                    class="text-center hidden"
                                    v-if="!$route.query.window">
                                    {{ $t('search') }}
                                </td>
                                <td
                                    class="bg-white"
                                    v-if="!$route.query.window">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t(`enter keyword`)"
                                        v-model="filters.keyword" />
                                </td>
                                <td
                                    class="text-center hidden"
                                    v-if="!$route.query.window">
                                    {{ $t('class') }}
                                </td>
                                <td
                                    class="bg-white"
                                    style="width: 15%"
                                    v-if="!$route.query.window">
                                    <multiselect
                                        :placeholder="`${$t('select class')}`"
                                        v-model="filters.class"
                                        :options="class_options"
                                        label="name"
                                        :can-deselect="false">
                                        <template
                                            v-slot:singlelabel="{ value }">
                                            <span class="flex-fill ps-2">{{
                                                $t(value.value)
                                            }}</span>
                                        </template>
                                        <template v-slot:option="{ option }">
                                            {{ $t(option.value) }}
                                        </template>
                                    </multiselect>
                                </td>
                                <td
                                    class="text-center hidden"
                                    v-if="!$route.query.window">
                                    {{ $t('classification') }}
                                </td>
                                <td
                                    class="bg-white"
                                    style="width: 15%"
                                    v-if="!$route.query.window">
                                    <multiselect
                                        :placeholder="`${$t(
                                            'select referrer'
                                        )}`"
                                        v-model="filters.referrer"
                                        :options="referrer_options"
                                        label="name"
                                        :can-deselect="false">
                                        <template
                                            v-slot:singlelabel="{ value }">
                                            <span class="flex-fill ps-2">{{
                                                $t(value.value)
                                            }}</span>
                                        </template>
                                        <template v-slot:option="{ option }">
                                            {{ $t(option.value) }}
                                        </template>
                                    </multiselect>
                                </td>
                                <td
                                    class="text-center hidden"
                                    v-if="!$route.query.window">
                                    {{ $t('type') }}
                                </td>
                                <td class="bg-white" style="width: 15%">
                                    <multiselect
                                        :placeholder="`${$t('select type')}`"
                                        v-model="filters.type"
                                        :options="type_options"
                                        label="name">
                                        <template
                                            v-slot:singlelabel="{ value }">
                                            <span class="flex-fill ps-2">{{
                                                $t(value.value)
                                            }}</span>
                                        </template>
                                        <template v-slot:option="{ option }">
                                            {{ $t(option.value) }}
                                        </template>
                                    </multiselect>
                                </td>
                                <td
                                    :rowspan="
                                        client_name == 'dreamssolution'
                                            ? '2'
                                            : '1'
                                    "
                                    :colspan="
                                        client_name == 'dreamssolution'
                                            ? '2'
                                            : '1'
                                    "
                                    class="text-center"
                                    v-if="!$route.query.window">
                                    <div class="button-items">
                                        <button
                                            :class="`btn btn-danger ${
                                                loading ? 'disabled' : ''
                                            }`"
                                            @click="resetFilter()">
                                            {{ $t('reset') }}
                                        </button>
                                        <b-dropdown
                                            id="dropdown-1"
                                            :text="$t('change')"
                                            :variant="
                                                user_ids.length < 1
                                                    ? 'light'
                                                    : 'warning'
                                            "
                                            :disabled="user_ids.length < 1">
                                            <b-dropdown-item
                                                @click="
                                                    showBatchModal(
                                                        'handler-bank'
                                                    )
                                                "
                                                >{{
                                                    $t(`update handler bank`)
                                                }}</b-dropdown-item
                                            >
                                            <b-dropdown-item
                                                @click="
                                                    showBatchModal('status')
                                                "
                                                >{{
                                                    $t(`change activity status`)
                                                }}</b-dropdown-item
                                            >
                                            <b-dropdown-item
                                                @click="
                                                    showBatchModal('password')
                                                "
                                                >{{
                                                    $t(`change password`)
                                                }}</b-dropdown-item
                                            >
                                        </b-dropdown>
                                        <button
                                            :class="`btn btn-success`"
                                            @click="exportData()">
                                            {{ $t('export') }}
                                        </button>
                                        <!-- <button
                                                :class="`btn btn-info`"
                                                @click="batch_update_modal = true"
                                                :disabled="user_ids.length<1"
                                            >
                                                {{ $t(`update handler bank`) }}
                                            </button> -->
                                        <b-modal
                                            v-model="batch_update_modal"
                                            centered
                                            hide-footer
                                            :title="`${$t(
                                                `batch update`
                                            )} - ${$t(
                                                batch_update_column == 'status'
                                                    ? 'deactivate'
                                                    : batch_update_column.replace(
                                                          '-',
                                                          ' '
                                                      )
                                            )}`"
                                            body-class="pt-0">
                                            <b-form>
                                                <div
                                                    class="row mb-3 mt-0"
                                                    v-if="
                                                        batch_update_column ==
                                                        'handler-bank'
                                                    ">
                                                    <div v-if="0"
                                                        class="col bg-soft pt-2"
                                                        :class="`${
                                                            bank.physical
                                                                .default
                                                                ? 'bg-primary'
                                                                : 'bg-light'
                                                        }`">
                                                        <div
                                                            class="d-flex justify-content-between">
                                                            <b-form-radio
                                                                name="default-handler-bank"
                                                                v-model="
                                                                    bank
                                                                        .physical
                                                                        .default
                                                                ">
                                                                <h4
                                                                    class="card-title">
                                                                    {{
                                                                        $t(
                                                                            'physical'
                                                                        )
                                                                    }}
                                                                    <small
                                                                        v-if="
                                                                            bank
                                                                                .physical
                                                                                .default
                                                                        "
                                                                        class="text-muted"
                                                                        >({{
                                                                            $t(
                                                                                'default'
                                                                            )
                                                                        }})</small
                                                                    >
                                                                </h4>
                                                            </b-form-radio>
                                                            <b-form-checkbox
                                                                switch
                                                                v-model="
                                                                    bank
                                                                        .physical
                                                                        .enabled
                                                                ">
                                                                {{
                                                                    bank
                                                                        .physical
                                                                        .enabled
                                                                        ? $t(
                                                                              'enabled'
                                                                          )
                                                                        : $t(
                                                                              'disabled'
                                                                          )
                                                                }}
                                                            </b-form-checkbox>
                                                        </div>
                                                        <b-form-group
                                                            class="mb-3"
                                                            :label="
                                                                $t('bank name')
                                                            ">
                                                            <input
                                                                v-model="
                                                                    bank[
                                                                        'physical'
                                                                    ][
                                                                        'bank_name'
                                                                    ]
                                                                "
                                                                type="text"
                                                                class="form-control" />
                                                        </b-form-group>
                                                        <b-form-group
                                                            class="mb-3"
                                                            :label="
                                                                $t(
                                                                    'account holder'
                                                                )
                                                            ">
                                                            <input
                                                                v-model="
                                                                    bank[
                                                                        'physical'
                                                                    ][
                                                                        'account_holder'
                                                                    ]
                                                                "
                                                                type="text"
                                                                class="form-control" />
                                                        </b-form-group>
                                                        <b-form-group
                                                            class="mb-3"
                                                            :label="
                                                                $t(
                                                                    'account number'
                                                                )
                                                            ">
                                                            <input
                                                                v-model="
                                                                    bank[
                                                                        'physical'
                                                                    ][
                                                                        'account_number'
                                                                    ]
                                                                "
                                                                type="text"
                                                                class="form-control" />
                                                        </b-form-group>
                                                    </div>
                                                    <div
                                                        class="col bg-soft pt-2"
                                                        :class="`${
                                                            bank.virtual.default
                                                                ? 'bg-primary'
                                                                : 'bg-light'
                                                        }`">
                                                        <div
                                                            class="d-flex justify-content-between">
                                                            <b-form-radio
                                                                name="default-handler-bank"
                                                                v-model="
                                                                    bank.virtual
                                                                        .default
                                                                ">
                                                                <h4
                                                                    class="card-title">
                                                                    {{
                                                                        $t(
                                                                            'virtual'
                                                                        )
                                                                    }}
                                                                    <small
                                                                        v-if="
                                                                            bank
                                                                                .virtual
                                                                                .default
                                                                        "
                                                                        class="text-muted"
                                                                        >({{
                                                                            $t(
                                                                                'default'
                                                                            )
                                                                        }})</small
                                                                    >
                                                                </h4>
                                                            </b-form-radio>
                                                            <b-form-checkbox
                                                                switch
                                                                v-model="
                                                                    bank.virtual
                                                                        .enabled
                                                                ">
                                                                {{
                                                                    bank.virtual
                                                                        .enabled
                                                                        ? $t(
                                                                              'enabled'
                                                                          )
                                                                        : $t(
                                                                              'disabled'
                                                                          )
                                                                }}
                                                            </b-form-checkbox>
                                                        </div>

                                                        <b-form-group
                                                            class="mb-3"
                                                            :label="
                                                                $t('bank name')
                                                            ">
                                                            <input
                                                                v-model="
                                                                    bank[
                                                                        'virtual'
                                                                    ][
                                                                        'bank_name'
                                                                    ]
                                                                "
                                                                type="text"
                                                                class="form-control" />
                                                        </b-form-group>
                                                        <b-form-group
                                                            class="mb-3"
                                                            :label="
                                                                $t(
                                                                    'account holder'
                                                                )
                                                            ">
                                                            <input
                                                                v-model="
                                                                    bank[
                                                                        'virtual'
                                                                    ][
                                                                        'account_holder'
                                                                    ]
                                                                "
                                                                type="text"
                                                                class="form-control" />
                                                        </b-form-group>
                                                        <b-form-group
                                                            class="mb-3"
                                                            :label="
                                                                $t(
                                                                    'account number'
                                                                )
                                                            ">
                                                            <input
                                                                v-model="
                                                                    bank[
                                                                        'virtual'
                                                                    ][
                                                                        'account_number'
                                                                    ]
                                                                "
                                                                type="text"
                                                                class="form-control" />
                                                        </b-form-group>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row mb-3 mt-0"
                                                    v-else-if="
                                                        batch_update_column ==
                                                        'password'
                                                    ">
                                                    <div class="col bg-soft pt-2">
                                                        <b-form-group class="mb-3" :label=" $t('new password') ">
                                                            <input
                                                                v-model="password.new_password"
                                                                type="password"
                                                                class="form-control" />
                                                        </b-form-group>
                                                        <b-form-group class="mb-3" :label=" $t('confirm password') ">
                                                            <input
                                                                v-model="password.confirm_new_password"
                                                                type="password"
                                                                class="form-control" />
                                                        </b-form-group>
                                                    </div>
                                                </div>
                                                <div
                                                    class="table-responsive mb-3 border"
                                                    style="
                                                        max-height: 300px;
                                                        min-height: 300px;
                                                    ">
                                                    <table
                                                        class="table table-bordered table-hover table-striped table-sm">
                                                        <thead>
                                                            <tr
                                                                class="bg-white"
                                                                style="
                                                                    position: sticky;
                                                                    top: -1px;
                                                                    z-index: 2;
                                                                ">
                                                                <th colspan="2">
                                                                    {{
                                                                        $t(
                                                                            'selected users'
                                                                        )
                                                                    }}
                                                                    <span
                                                                        class="text-info ms-3"
                                                                        >{{
                                                                            selected_users.length
                                                                        }}</span
                                                                    >
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                v-for="(
                                                                    r, i
                                                                ) in selected_users"
                                                                :key="r._id">
                                                                <td
                                                                    class="text-center">
                                                                    {{ i + 1 }}
                                                                </td>
                                                                <td>
                                                                    <i
                                                                        :class="`mdi mdi-numeric-${r.player.level}-box fs-5  level-${r.player.level}`"></i>
                                                                    {{
                                                                        r.username
                                                                    }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div
                                                    class="button-items float-end">
                                                    <button
                                                        v-if="
                                                            batch_update_column ==
                                                            'status'
                                                        "
                                                        type="button"
                                                        class="btn btn-success"
                                                        @click="
                                                            batchUpdate(
                                                                'active'
                                                            )
                                                        ">
                                                        {{ $t('activate') }}
                                                    </button>
                                                    <button
                                                        v-if="
                                                            batch_update_column ==
                                                            'status'
                                                        "
                                                        type="button"
                                                        class="btn btn-danger"
                                                        @click="
                                                            batchUpdate(
                                                                'inactive'
                                                            )
                                                        ">
                                                        {{ $t('deactivate') }}
                                                    </button>
                                                    <button
                                                        v-if="['handler-bank','password'].includes(batch_update_column)"
                                                        type="button"
                                                        class="btn btn-warning"
                                                        @click="batchUpdate()">
                                                        {{ $t('submit') }}
                                                    </button>
                                                </div>
                                            </b-form>
                                        </b-modal>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div
                        class="d-flex justify-content-between align-items-baseline">
                        <ul
                            class="nav nav-tabs flex-fill"
                            v-if="!$route.query.window">
                            <li
                                class="nav-item"
                                v-for="s in status_options"
                                :key="s.value">
                                <a
                                    :disabled="loading"
                                    class="nav-link"
                                    :class="{
                                        active: filters.status === s.value,
                                    }"
                                    aria-current="page"
                                    href="#"
                                    @click="filters.status = s.value"
                                    >{{ $t(s.name) }}</a
                                >
                            </li>
                        </ul>
                        <multiselect
                            style="width: 120px"
                            class="multiselect-sm me-0"
                            :placeholder="`${$t('limit')}`"
                            v-model="filters.limit"
                            :options="[50, 100, 500, 1000]"
                            :preselect-first="true"
                            :can-deselect="false"
                            :can-clear="false">
                            <template v-slot:singlelabel="{ value }">
                                <span class="mx-auto">{{
                                    $t('view') + ' ' + value.value
                                }}</span>
                            </template>
                        </multiselect>
                    </div>
                    <table
                        class="table table-bordered mb-0 table-grid"
                        v-if="!$route.query.window">
                        <tbody>
                            <tr class="bg-white">
                                <td>
                                    <div class="text-muted">
                                        {{ $t('total users') }}
                                    </div>
                                    <div class="text-end text-black">
                                        {{ numberString(data.total) }}
                                        {{ $t('people') }}
                                    </div>
                                </td>
                                <td>
                                    <div class="text-muted">
                                        {{ $t('live users') }}
                                    </div>
                                    <div class="text-end text-danger">
                                        {{ numberString(total.live_users) }}
                                        {{ $t('people') }}
                                    </div>
                                </td>
                                <td>
                                    <div class="text-muted">
                                        {{ $t('visitors today') }}
                                    </div>
                                    <div class="text-end text-primary">
                                        {{ numberString(total.login_one_day) }}
                                        {{ $t('people') }}
                                    </div>
                                </td>
                                <td>
                                    <div class="text-muted">
                                        {{ $t('monthly visitor') }}
                                    </div>
                                    <div class="text-end text-success">
                                        {{
                                            numberString(total.login_one_month)
                                        }}
                                        {{ $t('people') }}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </b-form>
            </div>
            <div class="card-body">
                <div ref="tableScroller" class="table-responsive">
                    <LoadingTable
                        :cols="10"
                        :rows="50"
                        :td_small_class="`d-none`"
                        class="table-bordered table-striped"
                        v-if="loading" />
                    <table
                        v-else
                        class="table table-bordered table-hover table-striped table-nowrap mb-0 table-list">
                        <thead class="hidden">
                            <tr class="fz">
                                <th scope="col" class="fz">
                                    <div class="form-check-dark">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            @input="addAllPlayerId" />
                                    </div>
                                </th>
                                <th scope="col" class="fz">
                                    <a
                                        href="javascript:void(0);"
                                        class="anchor-hover"
                                        @click="sort('username')">
                                        <i
                                            class="bx"
                                            :class="{
                                                'bx-caret-down bx-flashing':
                                                    filters.order == 'desc' &&
                                                    filters.sort == 'username',
                                                'bx-caret-up bx-flashing':
                                                    filters.order == 'asc' &&
                                                    filters.sort == 'username',
                                            }"></i>
                                        <span
                                            >{{ $t('level') }}/
                                            {{ $t('username') }}</span
                                        >
                                    </a>
                                </th>
                                <th scope="col">
                                    <a
                                        href="javascript:void(0);"
                                        class="anchor-hover"
                                        @click="sort('name')">
                                        <i
                                            class="bx"
                                            :class="{
                                                'bx-caret-down bx-flashing':
                                                    filters.order == 'desc' &&
                                                    filters.sort == 'name',
                                                'bx-caret-up bx-flashing':
                                                    filters.order == 'asc' &&
                                                    filters.sort == 'name',
                                            }"></i>
                                        <span>{{ $t('name') }}</span>
                                    </a>
                                </th>
                                <th scope="col">
                                    {{ $t('referrer') }}
                                </th>
                                <th scope="col">
                                    <div
                                        class="form-check-primary d-flex justify-content-center align-items-baseline gap-2">
                                        <span
                                            class="fw-normal"
                                            v-if="
                                                ['offline'].includes(
                                                    player_mode
                                                )
                                            "
                                            >{{ $t('physical') }}</span
                                        >
                                        <b-form-checkbox
                                            id="bank-details"
                                            v-model="show_account_details"
                                            class="text-muted"
                                            name="bank-details">
                                            <span class="fw-bold">{{
                                                $t('account details')
                                            }}</span>
                                        </b-form-checkbox>
                                        <span
                                            class="fw-normal"
                                            v-if="
                                                ['offline'].includes(
                                                    player_mode
                                                )
                                            "
                                            >{{ $t('virtual') }}</span
                                        >
                                    </div>
                                </th>
                                <th scope="col">
                                    {{ $t('phone number') }}
                                </th>
                                <!-- <th scope="col">
                                    {{ $t('rebate') }}
                                </th> -->
                                <th scope="col">
                                    <a
                                        href="javascript:void(0);"
                                        class="anchor-hover"
                                        @click="sort('player.assets.balance')">
                                        <i
                                            class="bx"
                                            :class="{
                                                'bx-caret-down bx-flashing':
                                                    filters.order == 'desc' &&
                                                    filters.sort ==
                                                        'player.assets.balance',
                                                'bx-caret-up bx-flashing':
                                                    filters.order == 'asc' &&
                                                    filters.sort ==
                                                        'player.assets.balance',
                                            }"></i>
                                        <span>{{ $t('balance') }}</span>
                                    </a>
                                </th>
                                <th scope="col">
                                    {{ $t('commission type') }}
                                </th>
                                <th scope="col">
                                    <a
                                        href="javascript:void(0);"
                                        class="anchor-hover"
                                        @click="sort('player.assets.points')">
                                        <i
                                            class="bx"
                                            :class="{
                                                'bx-caret-down bx-flashing':
                                                    filters.order == 'desc' &&
                                                    filters.sort ==
                                                        'player.assets.points',
                                                'bx-caret-up bx-flashing':
                                                    filters.order == 'asc' &&
                                                    filters.sort ==
                                                        'player.assets.points',
                                            }"></i>
                                        <span>{{ $t('points') }}</span>
                                    </a>
                                </th>
                                <th scope="col">
                                    <a
                                        href="javascript:void(0);"
                                        class="anchor-hover"
                                        @click="
                                            sort('player.total.deposit_amount')
                                        ">
                                        <i
                                            class="bx"
                                            :class="{
                                                'bx-caret-down bx-flashing':
                                                    filters.order == 'desc' &&
                                                    filters.sort ==
                                                        'player.total.deposit_amount',
                                                'bx-caret-up bx-flashing':
                                                    filters.order == 'asc' &&
                                                    filters.sort ==
                                                        'player.total.deposit_amount',
                                            }"></i>
                                        <span>{{ $t('recharge amount') }}</span>
                                    </a>
                                </th>
                                <th scope="col">
                                    <a
                                        href="javascript:void(0);"
                                        class="anchor-hover"
                                        @click="
                                            sort('player.total.withdraw_amount')
                                        ">
                                        <i
                                            class="bx"
                                            :class="{
                                                'bx-caret-down bx-flashing':
                                                    filters.order == 'desc' &&
                                                    filters.sort ==
                                                        'player.total.withdraw_amount',
                                                'bx-caret-up bx-flashing':
                                                    filters.order == 'asc' &&
                                                    filters.sort ==
                                                        'player.total.withdraw_amount',
                                            }"></i>
                                        <span>{{ $t('exchange amount') }}</span>
                                    </a>
                                </th>
                                <th scope="col">
                                    {{ $t('difference') }}
                                </th>
                                <!-- <th scope="col">
                                    <a
                                        href="javascript:void(0);"
                                        class="anchor-hover"
                                        @click="
                                            sort(
                                                'player.rebate.casino.table.value'
                                            )
                                        ">
                                        <i
                                            class="bx"
                                            :class="{
                                                'bx-caret-down bx-flashing':
                                                    filters.order == 'desc' &&
                                                    filters.sort ==
                                                        'player.rebate.casino.table.value',
                                                'bx-caret-up bx-flashing':
                                                    filters.order == 'asc' &&
                                                    filters.sort ==
                                                        'player.rebate.casino.table.value',
                                            }"></i>
                                        <span>{{ $t('casino rebate') }}</span>
                                    </a>
                                </th>
                                <th scope="col">
                                    <a
                                        href="javascript:void(0);"
                                        class="anchor-hover"
                                        @click="
                                            sort(
                                                'player.rebate.casino.slots.value'
                                            )
                                        ">
                                        <i
                                            class="bx"
                                            :class="{
                                                'bx-caret-down bx-flashing':
                                                    filters.order == 'desc' &&
                                                    filters.sort ==
                                                        'player.rebate.casino.slots.value',
                                                'bx-caret-up bx-flashing':
                                                    filters.order == 'asc' &&
                                                    filters.sort ==
                                                        'player.rebate.casino.slots.value',
                                            }"></i>
                                        <span>{{ $t('slot rebate') }}</span>
                                    </a>
                                </th>
                                <th scope="col">
                                    <a
                                        href="javascript:void(0);"
                                        class="anchor-hover"
                                        @click="
                                            sort('player.rebate.losing.value')
                                        ">
                                        <i
                                            class="bx"
                                            :class="{
                                                'bx-caret-down bx-flashing':
                                                    filters.order == 'desc' &&
                                                    filters.sort ==
                                                        'player.rebate.losing.value',
                                                'bx-caret-up bx-flashing':
                                                    filters.order == 'asc' &&
                                                    filters.sort ==
                                                        'player.rebate.losing.value',
                                            }"></i>
                                        <span>{{ $t('losing') }}</span>
                                    </a>
                                </th> -->
                                <th scope="col">
                                    {{ $t('login') }}
                                </th>
                                <th scope="col">
                                    <a
                                        href="javascript:void(0);"
                                        class="anchor-hover"
                                        @click="sort('last_login_at')">
                                        <i
                                            class="bx"
                                            :class="{
                                                'bx-caret-down bx-flashing':
                                                    filters.order == 'desc' &&
                                                    filters.sort ==
                                                        'last_login_at',
                                                'bx-caret-up bx-flashing':
                                                    filters.order == 'asc' &&
                                                    filters.sort ==
                                                        'last_login_at',
                                            }"></i>
                                        <span>{{ $t('last access') }}</span>
                                    </a>
                                </th>
                                <th scope="col">
                                    <a
                                        href="javascript:void(0);"
                                        class="anchor-hover"
                                        @click="sort('last_login_ip')">
                                        <i
                                            class="bx"
                                            :class="{
                                                'bx-caret-down bx-flashing':
                                                    filters.order == 'desc' &&
                                                    filters.sort ==
                                                        'last_login_ip',
                                                'bx-caret-up bx-flashing':
                                                    filters.order == 'asc' &&
                                                    filters.sort ==
                                                        'last_login_ip',
                                            }"></i>
                                        <span>{{ $t('access ip') }}</span>
                                    </a>
                                </th>
                                <th scope="col">
                                    <a
                                        href="javascript:void(0);"
                                        class="anchor-hover"
                                        @click="sort('created_at')">
                                        <i
                                            class="bx"
                                            :class="{
                                                'bx-caret-down bx-flashing':
                                                    filters.order == 'desc' &&
                                                    filters.sort ==
                                                        'created_at',
                                                'bx-caret-up bx-flashing':
                                                    filters.order == 'asc' &&
                                                    filters.sort ==
                                                        'created_at',
                                            }"></i>
                                        <span>{{
                                            $t('subscription date')
                                        }}</span>
                                    </a>
                                </th>
                                <th scope="col">
                                    {{ $t('access domain') }}
                                </th>
                                <th scope="col">
                                    {{ $t('management') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="r in data.list"
                                :key="r._id"
                                :class="{
                                    selected: user_ids.includes(r._id),
                                }">
                                <td class="fz text-center">
                                    <div class="form-check-dark">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            :value="r._id"
                                            :id="`row-${r._id}`"
                                            @input="addPlayerId" />
                                    </div>
                                </td>
                                <td class="fz">
                                    <div class="td-username">
                                        <a
                                            href="javascript:void(0)"
                                            class="text-secondary"
                                            @click="
                                                goToTicketForm(
                                                    r.username,
                                                    'player'
                                                )
                                            ">
                                            <i
                                                class="bx bx-envelope bx-tada-hover fs-4"></i
                                        ></a>

                                        <a
                                            :title="$t(`click to view`)"
                                            href="javascript:void(0)"
                                            @click="
                                                goToUserForm(r._id, 'player')
                                            "
                                            class="link-username">
                                            <i
                                                :class="`mdi ${
                                                    r.type == 'online'
                                                        ? 'mdi-web text-info'
                                                        : r.type == 'offline'
                                                        ? 'mdi-storefront text-success'
                                                        : ''
                                                } fs-5`"></i>

                                            <i
                                                :class="`mdi mdi-numeric-${r.player.level}-box fs-5  level-${r.player.level}`"></i>
                                            {{ r.username }}
                                            <i
                                                class="bx bxs-circle bx-flashing text-warning font-size-11"
                                                v-if="r.logged_in"></i>
                                        </a>
                                    </div>
                                </td>
                                <td class="text-center">
                                    {{ r.name }}
                                </td>
                                <td class="text-center">
                                    <div
                                        class="d-flex align-items-center gap-1"
                                        v-if="r.agent_referrer">
                                        <i
                                            class="mdi mdi-account-tie text-danger fs-5"></i>
                                        <span class="mb-0 lh-1">
                                            {{ r.agent_referrer.username }}
                                        </span>
                                    </div>
                                    <div
                                        class="d-flex align-items-center gap-1"
                                        v-if="r.player_referrer">
                                        <i
                                            class="mdi mdi-account-cowboy-hat text-primary fs-5"></i>
                                        <span class="mb-0 lh-1">
                                            {{ r.player_referrer.username }}
                                        </span>
                                    </div>
                                </td>
                                <td
                                    class="text-center p-0 bank-preview"
                                    :class="{ show: show_account_details }">
                                    <div class="label">
                                        {{ $t('view account details') }}
                                    </div>
                                    <div class="bank-info">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td
                                                        v-if="
                                                            [
                                                                'offline',
                                                            ].includes(
                                                                player_mode
                                                            )
                                                        ">
                                                        {{
                                                            r.handler_bank
                                                                .physical
                                                                .bank_name
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            r.handler_bank
                                                                .virtual
                                                                .bank_name
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        v-if="
                                                            [
                                                                'offline',
                                                            ].includes(
                                                                player_mode
                                                            )
                                                        ">
                                                        {{
                                                            r.handler_bank
                                                                .physical
                                                                .account_number
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            r.handler_bank
                                                                .virtual
                                                                .account_number
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        v-if="
                                                            [
                                                                'offline',
                                                            ].includes(
                                                                player_mode
                                                            )
                                                        ">
                                                        {{
                                                            r.handler_bank
                                                                .physical
                                                                .account_holder
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            r.handler_bank
                                                                .virtual
                                                                .account_holder
                                                        }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                                <td>
                                    {{ r.mobile_number }}
                                </td>

                                <!-- <td class="text-center">
                                    <div
                                        v-if="
                                            r.player.rebate.casino.table.enabled
                                        "
                                        class="text-danger d-flex justify-content-between gap-2 lh-1">
                                        {{ $t('casino')
                                        }}<span
                                            >{{
                                                numberStringDecimal(
                                                    r.player.rebate.casino.table
                                                        .value
                                                )
                                            }}%</span
                                        >
                                    </div>
                                    <div
                                        v-if="
                                            r.player.rebate.casino.slots.enabled
                                        "
                                        class="text-primary d-flex justify-content-between gap-2 lh-1">
                                        {{ $t('slot')
                                        }}<span
                                            >{{
                                                numberStringDecimal(
                                                    r.player.rebate.casino.slots
                                                        .value
                                                )
                                            }}%</span
                                        >
                                    </div>
                                    <div
                                        v-if="r.player.rebate.losing.enabled"
                                        class="text-success d-flex justify-content-between gap-2 lh-1">
                                        {{ $t('losing')
                                        }}<span
                                            >{{
                                                numberStringDecimal(
                                                    r.player.rebate.losing.value
                                                )
                                            }}%</span
                                        >
                                    </div>
                                </td> -->
                                <td>
                                    <a
                                        class="d-flex justify-content-end align-items-center"
                                        href="javascript:void(0)"
                                        @click="showAssetModal(r, 'balance')">
                                        {{
                                            numberString(
                                                r.player.assets.balance
                                            )
                                        }}
                                        <img
                                            class="won-icon"
                                            src="@/assets/images/won-cycle.png"
                                            alt="" />
                                        <!-- <i
                                            class="mdi mdi-plus-thick font-size-18 text-success"></i> -->
                                    </a>
                                </td>
                                <td class="text-center">
                                    <div
                                        v-if="
                                            r.player.commissions.casino.table
                                                .enabled
                                        "
                                        class="text-danger d-flex justify-content-between gap-2 lh-1">
                                        {{ $t('casino')
                                        }}<span
                                            >{{
                                                numberStringDecimal(
                                                    r.player.commissions.casino
                                                        .table.value
                                                )
                                            }}%</span
                                        >
                                    </div>
                                    <div
                                        v-if="
                                            r.player.commissions.casino.slots
                                                .enabled
                                        "
                                        class="text-primary d-flex justify-content-between gap-2 lh-1">
                                        {{ $t('slot')
                                        }}<span
                                            >{{
                                                numberStringDecimal(
                                                    r.player.commissions.casino
                                                        .slots.value
                                                )
                                            }}%</span
                                        >
                                    </div>
                                    <div
                                        v-if="
                                            r.player.commissions.casino.losing
                                                .enabled
                                        "
                                        class="text-success d-flex justify-content-between gap-2 lh-1">
                                        {{ $t('losing')
                                        }}<span
                                            >{{
                                                numberStringDecimal(
                                                    r.player.commissions.casino
                                                        .losing.value
                                                )
                                            }}%</span
                                        >
                                    </div>
                                </td>
                                <td>
                                    <a
                                        class="d-flex justify-content-end align-items-center"
                                        href="javascript:void(0)"
                                        @click="showAssetModal(r, 'points')">
                                        {{
                                            numberString(r.player.assets.points)
                                        }}
                                        <i
                                            class="mdi mdi-plus-thick font-size-18 text-success"></i>
                                    </a>
                                </td>
                                <td class="text-end">
                                    {{
                                        numberString(
                                            r.player.total.deposit_amount
                                        )
                                    }}
                                </td>
                                <td class="text-end">
                                    {{
                                        numberString(
                                            r.player.total.withdraw_amount
                                        )
                                    }}
                                </td>
                                <td class="text-end">
                                    {{
                                        numberString(
                                            r.player.total.deposit_amount -
                                                r.player.total.withdraw_amount
                                        )
                                    }}
                                </td>
                                <!-- <td class="text-end">
                                    {{
                                        numberStringDecimal(
                                            r.player.rebate.casino.table.value
                                        )
                                    }}%
                                </td>
                                <td class="text-end">
                                    {{
                                        numberStringDecimal(
                                            r.player.rebate.casino.slots.value
                                        )
                                    }}%
                                </td>
                                <td class="text-end">
                                    {{
                                        numberStringDecimal(
                                            r.player.rebate.losing.value
                                        )
                                    }}%
                                </td> -->
                                <td class="text-center">
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-outline-success"
                                        v-if="r.status == 'active'"
                                        @click="blockUser(r)">
                                        {{ $t('possible') }}
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-outline-danger"
                                        v-else-if="r.status == 'inactive'"
                                        @click="blockUser(r)">
                                        {{ $t('impossible') }}
                                    </button>
                                </td>
                                <td>
                                    {{
                                        r.last_login_at
                                            ? dateYmD(r.last_login_at, true)
                                            : ''
                                    }}
                                </td>
                                <td class="text-center">
                                    <div
                                        class="text-truncate d-flex align-items-center"
                                        v-if="r.last_login_ip">
                                        <i
                                            class="mdi mdi-ip-network-outline fs-5"></i>
                                        {{ r.last_login_ip }}
                                    </div>
                                </td>
                                <td class="text-center">
                                    {{ dateYmD(r.created_at, true) }}
                                </td>

                                <td>
                                    {{ playerDomain }}
                                </td>
                                <td>
                                    <div class="button-items">
                                        <button
                                            class="btn btn-sm btn-outline-info"
                                            @click="exitGame(r)"
                                            v-if="r.transfer_in_game">
                                            {{ $t('exit game') }}
                                        </button>
                                        <button
                                            class="btn btn-sm btn-outline-warning"
                                            @click="forceLogout(r)"
                                            v-if="r.logged_in">
                                            {{ $t('force logout') }}
                                        </button>

                                        <button
                                            class="btn btn-sm btn-outline-danger"
                                            @click="remove(r)">
                                            {{ $t('delete') }}
                                        </button>
                                    </div>
                                </td>
                                <td class="mobile">
                                    <div
                                        class="cell-card"
                                        @click="showViewModal(r._id)">
                                        <div class="form-check-dark">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                :value="r._id"
                                                :id="`row-${r._id}`"
                                                @input="addPlayerId" />
                                        </div>
                                        <div>
                                            <div class="td-username">
                                                <a
                                                    href="javascript:void(0)"
                                                    class="text-secondary"
                                                    @click="
                                                        goToTicketForm(
                                                            r.username,
                                                            'player'
                                                        )
                                                    ">
                                                    <i
                                                        class="bx bx-envelope bx-tada-hover fs-4"></i
                                                ></a>

                                                <a
                                                    :title="$t(`click to view`)"
                                                    href="javascript:void(0)"
                                                    @click="
                                                        goToUserForm(
                                                            r._id,
                                                            'player'
                                                        )
                                                    "
                                                    class="link-username fw-bold">
                                                    <i
                                                        :class="`mdi mdi-numeric-${r.player.level}-box fs-5  level-${r.player.level}`"></i>
                                                    {{ r.username }}
                                                    <i
                                                        class="bx bxs-circle bx-flashing text-warning font-size-11"
                                                        v-if="r.logged_in"></i>
                                                </a>
                                            </div>
                                            <div
                                                class="d-flex align-items-center gap-1"
                                                v-if="r.agent_referrer">
                                                <i
                                                    class="mdi mdi-account-tie text-danger fs-5"></i>
                                                <span class="mb-0 lh-1">
                                                    {{
                                                        r.agent_referrer
                                                            .username
                                                    }}
                                                </span>
                                            </div>
                                            <div
                                                class="d-flex align-items-center gap-1"
                                                v-if="r.player_referrer">
                                                <i
                                                    class="mdi mdi-account-cowboy-hat text-primary fs-5"></i>
                                                <span class="mb-0 lh-1">
                                                    {{
                                                        r.player_referrer
                                                            .username
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <a
                                                    class="d-flex justify-content-end align-items-center"
                                                    href="javascript:void(0)"
                                                    @click="
                                                        showAssetModal(
                                                            r,
                                                            'balance'
                                                        )
                                                    ">
                                                    <span class="flex-fill"
                                                        >{{
                                                            $t('balance')
                                                        }}:</span
                                                    >
                                                    {{
                                                        numberString(
                                                            r.player.assets
                                                                .balance
                                                        )
                                                    }}

                                                    <i
                                                        class="mdi mdi-plus-thick font-size-18"></i>
                                                </a>
                                            </div>
                                            <div>
                                                <a
                                                    class="d-flex justify-content-end align-items-center"
                                                    href="javascript:void(0)"
                                                    @click="
                                                        showAssetModal(
                                                            r,
                                                            'points'
                                                        )
                                                    ">
                                                    <span class="flex-fill"
                                                        >{{
                                                            $t('points')
                                                        }}:</span
                                                    >
                                                    {{
                                                        numberString(
                                                            r.player.assets
                                                                .points
                                                        )
                                                    }}
                                                    <i
                                                        class="mdi mdi-plus-thick font-size-18"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="button-items">
                                            <button
                                                type="button"
                                                class="btn btn-sm btn-outline-success"
                                                v-if="r.status == 'active'"
                                                @click="blockUser(r)">
                                                {{ $t('possible') }}
                                            </button>
                                            <button
                                                type="button"
                                                class="btn btn-sm btn-outline-danger"
                                                v-else
                                                @click="blockUser(r)">
                                                {{ $t('impossible') }}
                                            </button>
                                            <button
                                                class="btn btn-sm btn-outline-warning"
                                                @click="forceLogout(r)"
                                                v-if="r.logged_in">
                                                {{ $t('force logout') }}
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div
                        class="no-data-placeholder"
                        v-if="!data.list.length && !loading">
                        {{ $t('no data found') }}
                    </div>
                    <a
                        href="javascript:void(0);"
                        class="scroll-pad left"
                        @touchstart="startScrolling('left')"
                        @touchend="endScrolling"
                        @mousedown="startScrolling('left')"
                        @mouseup="endScrolling"
                        @mouseleave="endScrolling"
                        ><i class="bx bx-caret-left"></i
                    ></a>
                    <!-- @click="scroll('right')" -->
                    <a
                        href="javascript:void(0);"
                        class="scroll-pad right"
                        @touchstart="startScrolling('right')"
                        @touchend="endScrolling"
                        @mousedown="startScrolling('right')"
                        @mouseup="endScrolling"
                        @mouseleave="endScrolling"
                        ><i class="bx bx-caret-right"></i
                    ></a>
                </div>

                <Pagination :data="data" @emitPage="initList" />
            </div>
        </div>
        <b-modal
            v-model="asset_modal"
            @hidden="reset()"
            centered
            :title="`${$t(pl_asset.type)} ${$t('add')}/${$t('subtract')}`"
            title-class="font-18"
            hide-footer
            size="sm">
            <b-form>
                <table class="table table-bordered table-wrap table-form mb-2">
                    <tbody>
                        <tr>
                            <th class="bg-light bg-soft">
                                {{ $t('username') }}
                            </th>
                            <td>
                                <div class="mt-1">
                                    <i
                                        :class="`mdi mdi-numeric-${pl_asset.player.level}-box fs-5  level-${pl_asset.player.level}`"></i>
                                    {{ pl_asset.username }}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="bg-light bg-soft">
                                {{ $t(pl_asset.type) }}
                            </th>
                            <td>
                                <div class="mt-2">
                                    {{
                                        pl_asset.type == 'balance'
                                            ? numberString(
                                                  pl_asset.player.assets.balance
                                              )
                                            : numberString(
                                                  pl_asset.player.assets.points
                                              )
                                    }}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="bg-light bg-soft">
                                {{ $t('amount') }}
                            </th>
                            <td>
                                <VueNumberFormat
                                    class="form-control text-end rounded-end"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            v$.pl_asset.amount.$error,
                                    }"
                                    v-model:value="pl_asset.amount"
                                    :options="{
                                        precision: 0,
                                        prefix: '',
                                        suffix: '',
                                        thousand: ',',
                                        acceptNegative: false,
                                        isInteger: true,
                                    }"></VueNumberFormat>
                                <div
                                    v-for="(item, index) in v$.pl_asset.amount
                                        .$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <span v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <!-- <tr>
                            <th class="bg-light bg-soft">
                                {{ $t('note') }}
                            </th>
                            <td>
                                <textarea
                                    v-model="pl_asset.note"
                                    class="form-control"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            v$.pl_asset.note.$error,
                                    }"
                                    rows="2"
                                    style="resize: none"></textarea>
                                <div
                                    v-for="(item, index) in v$.pl_asset.note
                                        .$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <span v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </span>
                                </div>
                            </td>
                        </tr> -->
                    </tbody>
                </table>

                <div class="d-flex justify-content-evenly gap-1">
                    <button
                        class="btn btn-success flex-fill"
                        :class="{ disabled: loading }"
                        @click="updateAsset('add')">
                        <i class="mdi mdi-plus-thick font-size-15"></i>
                        {{ $t('add') }}
                    </button>
                    <button
                        class="btn btn-danger flex-fill"
                        :class="{ disabled: loading }"
                        @click="updateAsset('subtract')">
                        <i class="mdi mdi-minus-thick font-size-15"></i>
                        {{ $t('subtract') }}
                    </button>
                </div>
            </b-form>
        </b-modal>
    </Layout>
</template>

<script>
import Datepicker from 'vue3-datepicker';
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import LoadingTable from '@/components/loading-table';
import Pagination from '../../../components/pagination.vue';
import Multiselect from '@vueform/multiselect';
import Switches from 'vue-switches';
import Swal from 'sweetalert2';
import {
    required,
    minValue,
    maxValue,
    between,
    helpers,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { mapActions, mapGetters } from 'vuex';
import random from '../../../mixins/random';
import datetime from '../../../mixins/datetime';
import number from '../../../mixins/number';
import convert from '../../../mixins/convert';
import ui from '../../../mixins/ui';
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        Layout,
        PageHeader,
        Pagination,
        Switches,
        LoadingTable,
        Multiselect,
        Datepicker,
    },
    mixins: [datetime, number, random, ui, convert],
    data() {
        return {
            show_account_details: false,
            title: `players`,
            filters: {
                sort: null,
                order: null,
                keyword: null,
                class: null,
                referrer: null,
                username: null,
                limit: 50,
                status: this.$route.params.status || null,
                from: new Date(this.firstDateOftheMonth()),
                to: new Date(),
                levels: [],
                referrer_id: null,
                type: null,
            },
            referrer_ids: [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
            ],
            agents_options: [],
            level_options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            status_options: [
                // { name: this.$t('all'), value: '' },
                { name: this.$t('active'), value: 'active' },
                { name: this.$t('inactive'), value: 'inactive' },
                { name: this.$t('pending'), value: 'pending' },
            ],
            class_options: [
                { name: this.$t('new'), value: 'new' },
                { name: this.$t('normal'), value: 'normal' },
                { name: this.$t('vip'), value: 'vip' },
            ],
            referrer_options: [
                { name: this.$t('agent'), value: 'agent' },
                { name: this.$t('player'), value: 'player' },
            ],
            type_options: [
                { name: this.$t('all'), value: 'all' },
                { name: this.$t('online'), value: 'online' },
                { name: this.$t('offline'), value: 'offline' },
            ],
            data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            total: {
                live_users: 0,
                login_one_day: 0,
                login_one_month: 0,
            },
            submitted: false,
            asset_modal: false,
            pl_asset: {
                _id: null,
                username: null,
                name: null,
                nickname: null,
                player: {
                    level: null,
                    assets: {
                        balance: 0,
                        points: 0,
                    },
                },
                type: 'balance',
                amount: 0,
                note: null,
            },
            loading: false,
            total_loading: false,
            batch_update_modal: false,
            batch_update_column: '',
            user_ids: [],
            bank: {
                physical: {
                    enabled: false,
                    default: false,
                    bank_name: '',
                    account_holder: '',
                    account_number: '',
                },
                virtual: {
                    enabled: false,
                    default: true,
                    bank_name: '',
                    account_holder: '',
                    account_number: '',
                },
            },
            password: {
                new_password: null,
                confirm_new_password: null
            }
        };
    },
    validations() {
        return {
            pl_asset: {
                amount: {
                    required: helpers.withMessage('required', required),
                    minValueValue: helpers.withMessage('required', minValue(1)),
                    maxValueValue: helpers.withMessage(
                        'the maximum value is 9,999,999,999',
                        maxValue(9999999999)
                    ),
                },
                // note: {
                //     required: helpers.withMessage('required', required),
                // },
            },
        };
    },
    watch: {
        filters: {
            async handler(n) {
                this.filters.levels = this.filters.levels.sort(function (a, b) {
                    return a - b;
                });
                this.initList(1);
                this.initListTotal();
            },
            deep: true,
        },
        'filters.status'(status) {
            history.pushState({}, document.title, window.location.pathname);
        },
        referrer_ids: {
            async handler(agents) {
                var id = null;
                agents.forEach((agent, i) => {
                    if (agent != null) {
                        id = agent._id;
                    }
                });
                this.filters.referrer_id = id;
            },
            deep: true,
        },
        'bank.physical.default'(isDefault) {
            if (isDefault) {
                this.bank.virtual.default = false;
            }
        },
        'bank.virtual.default'(isDefault) {
            if (isDefault) {
                this.bank.physical.default = false;
            }
        },
        '$store.state.key'() {
            this.initList(1);
            this.initListTotal();
        },
    },
    computed: {
        f_levels() {
            return this.filters.levels.length
                ? this.filters.levels.toString() + ';'
                : null;
        },
        selected_users() {
            var arr = [];
            this.data.list.forEach((user) => {
                if (this.user_ids.includes(user._id)) {
                    arr.push(user);
                }
            });
            return arr;
        },
        playerDomain() {
            return window.location.host.replace('platform.', '');
        },
    },
    methods: {
        ...mapActions('auth', {
            authGetProfile: 'getProfile',
        }),
        ...mapActions('player', {
            playerGetList: 'getList',
            playerGetListTotal: 'getListTotal',
            playerUpdate: 'update',
            playerDelete: 'delete',
            playerLogout: 'logout',
            playerExitGame: 'exitGame',
            playerBatchUpdate: 'batchUpdate',
            playerUpdateAssets: 'updateAssets',
        }),
        ...mapActions('agent', {
            agentGetFilterList: 'getFilterList',
        }),
        sort(column) {
            if (this.filters.sort != column) {
                this.filters.order = 'desc';
            } else {
                if (this.filters.order == 'desc') this.filters.order = 'asc';
                else this.filters.order = 'desc';
            }
            this.filters.sort = column;
        },
        readQuery() {
            if (this.$route.query.referrer_id)
                this.filters.referrer_id = this.$route.query.referrer_id;
        },
        async initList(p) {
            this.user_ids = [];
            var scrollLeft = this.$refs.tableScroller.scrollLeft;
            var pl = {
                page_no: p ? p : 1,
                sort: this.filters.sort,
                order: this.filters.order,
                class: this.filters.class,
                referrer: this.filters.referrer,
                referrer_id: this.filters.referrer_id,
                limit: this.filters.limit,
                username: this.filters.username,
                status:
                    this.filters.status != 'all' ? this.filters.status : null,
                type: this.filters.type,
                search: this.filters.keyword ? this.filters.keyword : '',
                levels: this.f_levels,
                // from:
                //     this.client_name == 'dreamssolution'
                //         ? this.dateYmD(this.filters.from) + ' 00:00:00'
                //         : null,
                // to:
                //     this.client_name == 'dreamssolution'
                //         ? this.dateYmD(this.filters.to) + ' 23:59:59'
                //         : null,
            };
            this.loading = true;
            const data = await this.playerGetList(pl);
            this.data.list = data.data;
            this.data.links = data.links;
            this.data.current_page = data.current_page;
            this.data.per_page = data.per_page;
            this.data.last_page = data.last_page;
            this.data.total = data.total;
            this.loading = false;
            this.resetScroll(scrollLeft);
        },
        async initListTotal(p) {
            // this.resetScroll()
            var pl = {
                page_no: p ? p : 1,
                class: this.filters.class,
                referrer: this.filters.referrer,
                referrer_id: this.filters.referrer_id,
                username: this.filters.username,
                status:
                    this.filters.status != 'all' ? this.filters.status : null,
                type: this.filters.type,
                search: this.filters.keyword ? this.filters.keyword : '',
                levels: this.f_levels,
                // from:
                //     this.client_name == 'dreamssolution'
                //         ? this.dateYmD(this.filters.from) + ' 00:00:00'
                //         : null,
                // to:
                //     this.client_name == 'dreamssolution'
                //         ? this.dateYmD(this.filters.to) + ' 23:59:59'
                //         : null,
            };
            this.total_loading = true;
            const data = await this.playerGetListTotal(pl);
            this.total.live_users = data.live_users;
            this.total.login_one_day = data.login_one_day;
            this.total.login_one_month = data.login_one_month;
            this.total_loading = false;
        },
        resetFilter() {
            this.filters.username = null;
            this.filters.keyword = null;
            this.filters.referrer = null;
            this.filters.class = null;
            this.filters.status = '';
            this.filters.levels = [];
            this.filters.from = new Date(this.firstDateOftheYear());
            this.filters.to = new Date();
            // this.initList(1);
        },
        handleFilter(value) {
            if (!value) return;
            var startIndex = value.option_index + 1;
            var loop = this.agents_options.length;
            if (startIndex < loop) {
                for (var i = startIndex; i < loop; i++) {
                    this.agents_options.splice(startIndex, 1);
                    this.referrer_ids[i] = null;
                }
            }
            this.initFilter(value._id);
        },
        handleClear(index) {
            var loop = this.agents_options.length;
            var startIndex = index + 1;
            for (var i = startIndex; i < loop; i++) {
                this.agents_options.splice(startIndex, 1);
                this.referrer_ids[i] = null;
            }
            if (index == 0) this.referrer_ids[0] = null;
        },
        async initFilter(referrer_id) {
            const data = await this.agentGetFilterList({
                referrer: referrer_id || null,
            });
            var agents = [];
            data.forEach((agent) => {
                agent.option_index = this.agents_options.length;
                agents.push({
                    label: agent.username,
                    value: agent,
                });
            });
            // this.referrer_ids.push()
            if (agents.length) {
                this.agents_options.push(agents);
            }
        },
        view(id) {
            window.open(
                `/player/${id}`,
                '_blank',
                'width=' + 1720 + ',height=' + screen.height
            );
        },
        async blockUser(r) {
            if (!this.isAuthorized()) return;
            var swal_html = `
                        <div>${this.$t(
                            r.status == 'active' ? 'block user' : 'unblock user'
                        )}</div>
                        <table class="table table-sm table-bordered fs-6 mt-3">
                            <tbody>
                                <tr>
                                    <th>${this.$t('level')}<t/h>
                                    <td><i class="mdi mdi-numeric-${
                                        r.player.level
                                    }-box fs-4  level-${
                r.player.level
            }"></i> </td>
                                </tr>
                                <tr>
                                    <th>${this.$t('username')}</th>
                                    <td>${r.username}</td>
                                </tr>
                                <tr>
                                    <th>${this.$t('last access')}</th>
                                    <td>
                                        <div>${
                                            r.last_login_at
                                                ? this.dateYmD(
                                                      r.last_login_at,
                                                      true
                                                  )
                                                : ''
                                        }</div>
                                        <div class="text-primary">${
                                            r.last_login_ip || ''
                                        }</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    `;
            const confirmed = await Swal.fire({
                title: `${this.$t('is this what you want to change')}?`,
                html: swal_html,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f1b44c',
                cancelButtonColor: '#74788d',
                cancelButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('confirm'),
            }).then((result) => {
                return result.value;
            });

            if (confirmed) {
                var fd = new FormData();
                fd.append(
                    'status',
                    r.status == 'active' ? 'inactive' : 'active'
                );
                const res = await this.playerUpdate({
                    id: r._id,
                    form_data: this.formDataToUrlEncoded(fd),
                });

                if (res) {
                    Swal.fire({
                        icon: res.success ? 'success' : 'error',
                        title: res.success
                            ? this.$t(
                                  r.status == 'active'
                                      ? 'player has been blocked'
                                      : 'player has been unblocked'
                              )
                            : this.$t(
                                  blocked
                                      ? 'blocking failed'
                                      : 'unblocking failed'
                              ),
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    if (res.success) {
                        this.initList(1);
                    }
                }
            }
        },
        async forceLogout(r) {
            if (!this.isAuthorized()) return;
            var swal_html = `
                        <div>${this.$t('force logout')}</div>
                        <table class="table table-sm table-bordered fs-6 mt-3">
                            <tbody>
                                <tr>
                                    <th>${this.$t('level')}<t/h>
                                    <td><i class="mdi mdi-numeric-${
                                        r.player.level
                                    }-box fs-4  level-${
                r.player.level
            }"></i></td>
                                    </tr>
                                <tr>
                                    <th>${this.$t('username')}</th>
                                    <td>${r.username}</td>
                                </tr>
                                <tr>
                                    <th>${this.$t('last access')}</th>
                                    <td>
                                        <div>${
                                            r.last_login_at
                                                ? this.dateYmD(
                                                      r.last_login_at,
                                                      true
                                                  )
                                                : ''
                                        }</div>
                                        <div class="text-primary">${
                                            r.last_login_ip || ''
                                        }</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    `;
            const confirmed = await Swal.fire({
                title: `${this.$t('are you sure')}?`,
                html: swal_html,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f1b44c',
                cancelButtonColor: '#74788d',
                cancelButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('confirm'),
            }).then((result) => {
                return result.value;
            });

            if (confirmed) {
                const res = await this.playerLogout({ id: r._id });

                if (res) {
                    Swal.fire({
                        icon: res.success ? 'success' : 'error',
                        title: res.success
                            ? this.$t('player has been logged out')
                            : this.$t('an error occured'),
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    if (res.success) {
                        this.initList(1);
                    }
                }
            }
        },
        async exitGame(r) {
            if (!this.isAuthorized()) return;
            var swal_html = `
                        <div>${this.$t('exit game')}</div>
                        <table class="table table-sm table-bordered fs-6 mt-3">
                            <tbody>
                                <tr>
                                    <th>${this.$t('level')}<t/h>
                                    <td><i class="mdi mdi-numeric-${
                                        r.player.level
                                    }-box fs-4  level-${
                r.player.level
            }"></i></td>
                                    </tr>
                                <tr>
                                    <th>${this.$t('username')}</th>
                                    <td>${r.username}</td>
                                </tr>
                                <tr>
                                    <th>${this.$t('last access')}</th>
                                    <td>
                                        <div>${
                                            r.last_login_at
                                                ? this.dateYmD(
                                                      r.last_login_at,
                                                      true
                                                  )
                                                : ''
                                        }</div>
                                        <div class="text-primary">${
                                            r.last_login_ip || ''
                                        }</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    `;
            const confirmed = await Swal.fire({
                title: `${this.$t('are you sure')}?`,
                html: swal_html,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f1b44c',
                cancelButtonColor: '#74788d',
                cancelButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('confirm'),
            }).then((result) => {
                return result.value;
            });

            if (confirmed) {
                const res = await this.playerExitGame({ id: r._id });

                if (res) {
                    Swal.fire({
                        icon: res.success ? 'success' : 'error',
                        title: res.success
                            ? this.$t('player exited the game')
                            : this.$t('an error occured'),
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    if (res.success) {
                        this.initList(1);
                    }
                }
            }
        },
        async remove(r) {
            if (!this.isAuthorized()) return;
            var swal_html = `
                        <table class="table table-sm table-bordered fs-6 mt-3">
                            <tbody>
                                <tr>
                                    <th>${this.$t('level')}<t/h>
                                    <td><i class="mdi mdi-numeric-${
                                        r.player.level
                                    }-box fs-4  level-${
                r.player.level
            }"></i></td>
                                </tr>
                                <tr>
                                    <th>${this.$t('name')}</th>
                                    <td>${
                                        r.name != null
                                            ? r.name
                                            : this.$t('none')
                                    }</td>
                                </tr>
                                <tr>
                                    <th>${this.$t('username')}</th>
                                    <td>${r.username}</td>
                                </tr>
                            </tbody>
                        </table>
                    `;
            const confirmed = await Swal.fire({
                title: `${this.$t('Are you sure you want to delete it?')}`,
                html: swal_html,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f1b44c',
                cancelButtonColor: '#74788d',
                cancelButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('confirm'),
            }).then((result) => {
                return result.value;
            });

            if (confirmed) {
                const res = await this.playerDelete({ id: r._id });

                if (res) {
                    Swal.fire({
                        icon: res.success ? 'success' : 'error',
                        title: res.success
                            ? this.$t('account has been deleted.')
                            : this.$t('deleting failed'),
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    if (res.success) {
                        this.initList(1);
                    }
                }
            }
        },
        showBatchModal(column) {
            this.batch_update_modal = true;
            this.batch_update_column = column;
        },
        async batchUpdate(status) {
            if (!this.isAuthorized()) return;
            var fd = new FormData();
            if (this.batch_update_column == 'handler-bank') {
                // fd.append(
                //     `bank[physical][enabled]`,
                //     this.bank['physical']['enabled'] ? 1 : 0
                // );
                // fd.append(
                //     `bank[physical][default]`,
                //     this.bank['physical']['default'] ? 1 : 0
                // );
                // if(this.bank['physical']['bank_name']) {
                //     fd.append(
                //         `bank[physical][bank_name]`,
                //         this.bank['physical']['bank_name']
                //     );
                // }
                // if(this.bank['physical']['account_holder']) {
                //     fd.append(
                //         `bank[physical][account_holder]`,
                //         this.bank['physical']['account_holder']
                //     );
                // }
                // if(this.bank['physical']['account_number']) {
                //     fd.append(
                //         `bank[physical][account_number]`,
                //         this.bank['physical']['account_number']
                //     );
                // }
                fd.append(
                    `bank[virtual][enabled]`,
                    this.bank['virtual']['enabled'] ? 1 : 0
                );
                fd.append(
                    `bank[virtual][default]`,
                    this.bank['virtual']['default'] ? 1 : 0
                );
                if(this.bank['virtual']['bank_name']) {
                    fd.append(
                        `bank[virtual][bank_name]`,
                        this.bank['virtual']['bank_name']
                    );
                }
                if(this.bank['virtual']['account_holder']) {
                    fd.append(
                        `bank[virtual][account_holder]`,
                        this.bank['virtual']['account_holder']
                    );
                }
                if(this.bank['virtual']['account_number']) {
                    fd.append(
                        `bank[virtual][account_number]`,
                        this.bank['virtual']['account_number']
                    );
                }
            } else if (this.batch_update_column == 'status') {
                fd.append(`status`, status);
            } else if (this.batch_update_column == 'password') {
                if(!this.password.new_password || !this.password.confirm_new_password) { 
                    return;
                }
                if(this.password.new_password != this.password.confirm_new_password) { 
                    Swal.fire({
                        icon: 'error',
                        title: this.$t("password doesn't match"),
                        showConfirmButton: true,
                        // timer: 2000,
                    });
                    return;
                }
                fd.append(`password`, this.password.new_password);
                fd.append(`confirm_password`, this.password.confirm_new_password);
            }
            fd.append(`user_ids`, `${this.user_ids.toString()};`);
            const res = await this.playerBatchUpdate({
                column: this.batch_update_column,
                form_data: fd,
            });
            if (res) {
                Swal.fire({
                    icon: res.success ? 'success' : 'error',
                    title: res.success
                        ? this.$t('the information has been modified')
                        : this.$t('updating failed'),
                    showConfirmButton: true,
                    // timer: 2000,
                });
                if (res.success) {
                    this.user_ids.forEach(id => {
                        this.$store.commit('removeNotificationById', id);
                    });
                    this.batch_update_modal = false;
                    this.bank['physical']['bank_name'] = '';
                    this.bank['physical']['account_holder'] = '';
                    this.bank['physical']['account_number'] = '';
                    this.bank['virtual']['bank_name'] = '';
                    this.bank['virtual']['account_holder'] = '';
                    this.bank['virtual']['account_number'] = '';
                    this.password['new_password'] = null;
                    this.password['confirm_new_password'] = null;
                    this.addAllPlayerId();
                    this.authGetProfile();
                    this.initList(1);
                }
            }
        },
        addPlayerId(e) {
            var id = e.target.value;
            if (e.target.checked) {
                this.user_ids.push(id);
            } else {
                var index = this.user_ids.indexOf(id);
                this.user_ids.splice(index, 1);
            }
            this.bank.physical.bank_name =
                this.selected_users.length == 1
                    ? this.selected_users[0].handler_bank.physical.bank_name
                    : null;
            this.bank.physical.account_holder =
                this.selected_users.length == 1
                    ? this.selected_users[0].handler_bank.physical
                          .account_holder
                    : null;
            this.bank.physical.account_number =
                this.selected_users.length == 1
                    ? this.selected_users[0].handler_bank.physical
                          .account_number
                    : null;

            this.bank.virtual.bank_name =
                this.selected_users.length == 1
                    ? this.selected_users[0].handler_bank.virtual.bank_name
                    : null;
            this.bank.virtual.account_holder =
                this.selected_users.length == 1
                    ? this.selected_users[0].handler_bank.virtual.account_holder
                    : null;
            this.bank.virtual.account_number =
                this.selected_users.length == 1
                    ? this.selected_users[0].handler_bank.virtual.account_number
                    : null;
        },
        addAllPlayerId(e) {
            if (e) {
                this.data.list.forEach((user) => {
                    var user = document.getElementById(`row-${user._id}`);
                    if (e.target.checked != user.checked) {
                        user.click();
                    }
                });
            } else {
                this.data.list.forEach((user) => {
                    var user = document.getElementById(`row-${user._id}`);
                    if (user.checked) {
                        user.click();
                    }
                });
            }
        },
        showAssetModal(r, type) {
            this.pl_asset.type = type;
            this.pl_asset._id = r._id;
            this.pl_asset.username = r.username;
            this.pl_asset.player.level = r.player.level;
            this.pl_asset.player.assets.balance = r.player.assets.balance;
            this.pl_asset.player.assets.points = r.player.assets.points;
            this.asset_modal = true;
        },
        async updateAsset(action) {
            if (!this.isAuthorized()) return;
            this.submitted = true;
            this.v$.pl_asset.$touch();
            if (this.v$.pl_asset.$invalid) {
                return;
            } else {
                const confirmed = await Swal.fire({
                    title: `${this.$t('are you sure')}?`,
                    html: `<table class="table table-bordered table-sm ">
                        <tbody>
                            <tr>
                                <th class="text-lowercase">${this.$t(
                                    'amount of change'
                                )}</th>
                                <td>${this.$t(action)} ${this.numberString(
                        this.pl_asset.amount
                    )} ${this.$t(this.pl_asset.type)}</td>
                            </tr>
                            <tr>
                                <th class="text-lowercase">${this.$t(
                                    'username'
                                )}</th>
                                <td>${this.pl_asset.username}</td>
                            </tr>
                        </tbody>
                    </table>`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#f1b44c',
                    cancelButtonColor: '#74788d',
                    cancelButtonText: this.$t('cancel'),
                    confirmButtonText: this.$t('confirm'),
                }).then(async (result) => {
                    return result.value;
                });

                if (confirmed) {
                    var fd = new FormData();
                    fd.append('amount', this.pl_asset.amount);
                    // fd.append('note', this.pl_asset.note);
                    this.loading = true;
                    const res = await this.playerUpdateAssets({
                        id: this.pl_asset._id,
                        asset: this.pl_asset.type,
                        action: action,
                        form_data: fd,
                    });
                    this.loading = false;
                    if (res) {
                        Swal.fire({
                            html: this.$t(res.data.message.toLowerCase()),
                            icon: res.success ? 'success' : 'error',
                            timer: res.success ? 2000 : null,
                        });
                        this.initList(1);
                        this.reset();
                        this.asset_modal = false;
                    }
                }
            }
        },
        reset() {
            this.submitted = false;
            this.pl_asset.type = '';
            this.pl_asset._id = null;
            this.pl_asset.username = null;
            this.pl_asset.player.level = null;
            this.pl_asset.player.assets.balance = 0;
            this.pl_asset.player.assets.points = 0;
            this.pl_asset.amount = 0;
            this.pl_asset.note = null;
            this.password.new_password = null;
            this.password.confirm_new_password = null;
            this.v$.pl_asset.$reset();
        },
        setDay(mode) {
            if (mode == 'today') {
                this.filters.from = new Date();
                this.filters.to = new Date();
            } else {
                this.filters.from = this.addDaysToDate(-1);
                this.filters.to = this.addDaysToDate(-1);
            }
        },
        // start export excel
        convertToCsv(objArray) {
			var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;

			var str = "";

			for (var i = 0; i < array.length; i++) {
				var line = "";
				for (var index in array[i]) {
					if (line != "") line += ",";
					line += array[i][index];
				}

				str += line + "\r\n";
			}

			return str;
		},
		exportCsvFile(headers, item, fileTitle) {
			var vm = this;
			if (headers) {
				item.unshift(headers);
			}
			var jsonObject = JSON.stringify(item);
			var csv = vm.convertToCsv(jsonObject);

			var exportedFilenmae = fileTitle + ".csv" || "export.csv";

			var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
			if (navigator.msSaveBlob) {
				navigator.msSaveBlob(blob, exportedFilenmae);
			} else {
				var link = document.createElement("a");
				if (link.download !== undefined) {
					var url = URL.createObjectURL(blob);
					link.setAttribute("href", url);
					link.setAttribute("download", exportedFilenmae);
					link.style.visibility = "hidden";
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				}
			}
		},
		async exportData() {
			var current = new Date();
			var currentDate = `${current.getDate()}-${current.getMonth() + 1}-${current.getFullYear()}`;
			var headers = {
				username: `${this.$t('username')}`,
				name: `${this.$t('name')}`,
				type: `${this.$t('type')}`,
				balance: `${this.$t('balance')}`,
                points: `${this.$t('points')}`,
                bank_name: `${this.$t('bank name')}`,
                account_number: `${this.$t('account number')}`,
                account_holder: `${this.$t('account holder')}`,
                // level: `${this.$t('level')}`,
                // referrer: `${this.$t('referrer')}`,
				// phone_number: `${this.$t('phone number')}`,
				// commission_type: `${this.$t('commission type')}`,
				// deposit_amount: `${this.$t('recharge amount')}`,
				// withdraw_amount: `${this.$t('exchange amount')}`,
				// difference: `${this.$t('difference')}`,
				// difference: `${this.$t('difference')}`,
				// last_login_at: `${this.$t('last access')}`,
				// last_login_ip: `${this.$t('access ip')}`,
				// created_at: `${this.$t('subscription date')}`,
				// domain: `${this.$t('access domain')}`,
			};
			var itemsFormatted = [];
			const arr = await this.data.list;
			arr.forEach((item) => {
				itemsFormatted.push({
					username: item.username,
					name: item.name ? item.name : '-',
					type: item.type == 'online' ? `${this.$t('online')}` : item.type == 'offline' ? `${this.$t('offline')}` : '',
                    balance: item.player.assets.balance ? item.player.assets.balance : '0',
                    points: item.player.assets.points ? item.player.assets.points : '0',
                    bank_name: ['offline'].includes(this.player_mode) ? item.handler_bank.physical.bank_name : item.handler_bank.virtual.bank_name,
                    account_number: ['offline'].includes(this.player_mode) ? item.handler_bank.physical.account_number : item.handler_bank.virtual.account_number,
                    account_holder: ['offline'].includes(this.player_mode) ? item.handler_bank.physical.account_holder : item.handler_bank.virtual.account_holder,
                    // referrer: item.agent_referrer ? item.agent_referrer.username : item.player_referrer ? item.player_referrer.username : '-',
                    // phone_number: item.mobile_number ? item.mobile_number : '-',
                    // commission_type: item.player.commissions.casino.table.enabled ? item.player.commissions.casino.table.value+'%' : item.player.commissions.casino.slots.enabled ? item.player.commissions.casino.slots.value+'%' : item.player.commissions.casino.losing.enabled ? item.player.commissions.casino.losing.value+'%' : '-',
                    
                    // deposit_amount: item.player.total.deposit_amount ? item.player.total.deposit_amount : '0',
                    // withdraw_amount: item.player.total.withdraw_amount ? item.player.total.withdraw_amount : '0',
                    // difference: item.player.total.deposit_amount - item.player.total.withdraw_amount,
                    // last_login_at: item.last_login_at ? this.dateYmD(item.last_login_at, true): '-',
                    // last_login_ip: item.last_login_ip ? item.last_login_ip : '-',
                    // created_at: item.created_at ? this.dateYmD(item.created_at, true) : '-',
                    // domain: this.playerDomain,
				});
			});
			var fileTitle = `Member list ${currentDate} page ${this.data.current_page}`;
			this.exportCsvFile(headers, itemsFormatted, fileTitle);
		},
        // end export excel
    },
    mounted() {
        if (this.$route.query.window) {
            this.readQuery();
        } else {
            this.initFilter();
            this.initList(1);
            this.initListTotal();
        }
        window.addEventListener('message', (event) => {
            if (event.origin !== window.origin) {
                return;
            }
            if (event.data == 'reload-players') {
                this.initList(this.data.current_page);
                this.initListTotal();
            }
        });

        this.$watch(
            () => this.$route.query,
            (n, o) => {
                if (this.$route.query.window) {
                    this.readQuery();
                } else {
                    if (this.$route.name == 'player-management') {
                        if (this.$route.params.status) {
                            this.filters.status = this.$route.params.status;
                        }
                        this.initList(1);
                        this.initListTotal();
                    }
                }
            }
        );

        if (Boolean(window.PusherChannel)) {
            window.PusherChannel.bind('new-player-registration', (e) => {
                this.initList(1);
            });
        }
    },
    beforeUnmount() {
        if (Boolean(window.PusherChannel)) {
            window.PusherChannel.unbind('new-player-registration');
        }
    },
};
</script>

<style scoped></style>
