export default [{
    path: "/",
    redirect: '/dashboard'
},
{
    path: "/dashboard",
    name: "home",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/dashboard/home"),
},
{
    path: "/summary",
    name: "summary",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/dashboard/summary"),
},
{
    path: "/administrators",
    name: "administrators",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/administrator/list"),
},
{
    path: "/agents/:level(all|level-1|level-2|level-3|level-4|level-5)",
    name: "agent-management",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/agent/list"),
},
{
    path: "/agents-approval",
    name: "agents-approval",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/agent/approving-list"),
},
{
    path: "/agents-detailed",
    name: "agents-detailed",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/agent/detailed-list"),
},
{
    path: "/agent/transaction-summary",
    name: "agent-transaction-summary",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/agent/transaction-summary"),
},
{
    path: "/accounting-worksheet",
    name: "accounting-worksheet",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/agent/accounting-worksheet"),
},
{
    path: "/agent-deposit-worksheet",
    name: "agent-deposit-worksheet",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/agent/agent-deposit-worksheet"),
},
{
    path: "/agent/transaction/:transaction(deposit|withdraw)",
    name: "agent-transaction",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/agent/transaction/list"),
},
{
    path: "/agent/form",
    name: "agent-form",
    props: (route) => ({ id: route.query.id }),
    meta: {
        requiresAuth: true,
        queryValidation: {
            id: {
                validator: /^\w{24}$/,
                message: 'Invalid ID',
            },
        },
    },
    component: () =>
        import("./views/agent/form"),
},
{
    path: "/player/management/:status(all|pending)",
    name: "player-management",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/player/list"),
},
{
    path: "/player/transaction/:transaction(deposit|withdraw)/:type(online|offline)",
    name: "player-transaction",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/player/transaction/list"),
},
{
    path: "/transactions",
    name: "all-transactions",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/player/transaction/list-all"),
},
{
    path: "/player/transaction-summary",
    name: "player-transaction-summary",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/player/transaction-summary"),
},
// {
//     path: "/player/bets/:game(table|slot)/:range(all|week)",
//     name: "player-bets",
//     meta: {
//         requiresAuth: true,
//     },
//     component: () =>
//         import ("./views/player/bet/list"),
// },
{
    path: "/player/bets/:provider(honorlink|kplay|goldlion|petsplay|demo|hilton|mamahot)/:game(table|slot)/:range(all|week)",
    name: "player-bets",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/player/bet/list"),
},
{
    path: "/player/bets/skipped",
    name: "player-bets-skipped",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/player/bet/skipped-list"),
},
{
    path: "/player/bets/pending",
    name: "player-bets-pending",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/player/bet/pending-list"),
},
{
    path: "/player/ip-block",
    name: "player-ip-block",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/player/ip-block/list"),
},
{
    path: "/coupon/:role(agent|player)",
    name: "coupons",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/coupon/list"),
},
{
    path: "/player/ip-block/form",
    name: "player-ip-block-form",
    props: (route) => ({ id: route.query.id }),
    meta: {
        requiresAuth: true,
        queryValidation: {
            id: {
                validator: /^\w{24}$/,
                message: 'Invalid ID',
            },
        },
    },
    component: () =>
        import("./views/player/ip-block/form"),
},
{
    path: "/player/:id(\\w{24})",
    name: "player-view",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/player/view"),
},
{
    path: "/player/form",
    name: "player-form",
    props: (route) => ({ id: route.query.id }),
    meta: {
        requiresAuth: true,
        queryValidation: {
            id: {
                validator: /^\w{24}$/,
                message: 'Invalid ID',
            },
        },
    },
    component: () =>
        import("./views/player/form"),
},
{
    path: "/player/batch-form",
    name: "player-batch-form",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/player/batch-form"),
},
{
    path: "/player/:asset(balance|points)/:id(\\w{24})",
    name: "player-assets",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/player/asset"),
},
{
    path: "/bet/game-settings/:provider(honorlink|kplay|mamahot)",
    name: "bet-game-settings",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/player/bet/management/game-settings"),
},
{
    path: "/live-users/:role(agent|player)",
    name: "live-users",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/player/bet/management/live-users"),
},
{
    path: "/bet/monitor",
    name: "bet-monitor",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/player/bet/management/monitor"),
},


{
    path: "/tickets/:role(agent|player|public)",
    name: "ticket-management",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/ticket/list"),
},
{
    path: "/ticket/form/:role(agent|player)",
    name: "ticket-form",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/ticket/form"),
},
{
    path: "/ticket/view/:id(\\w{24})",
    name: "ticket-view",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/ticket/view"),
},
{
    path: "/template/management",
    name: "template-management",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/template/list"),
},
{
    path: "/template/form",
    name: "template-form",
    props: (route) => ({ id: route.query.id }),
    meta: {
        requiresAuth: true,
        queryValidation: {
            id: {
                validator: /^\w{24}$/,
                message: 'Invalid ID',
            },
        },
    },
    component: () =>
        import("./views/template/form"),
},
{
    path: "/announcement/management",
    name: "announcement-management",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/announcement/list"),
},
{
    path: "/announcement/form",
    name: "announcement-form",
    props: (route) => ({ id: route.query.id }),
    meta: {
        requiresAuth: true,
        queryValidation: {
            id: {
                validator: /^\w{24}$/,
                message: 'Invalid ID',
            },
        },
    },
    component: () =>
        import("./views/announcement/form"),
},
{
    path: "/event/management",
    name: "event-management",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/event/list"),
},
{
    path: "/event/form",
    name: "event-form",
    props: (route) => ({ id: route.query.id }),
    meta: {
        requiresAuth: true,
        queryValidation: {
            id: {
                validator: /^\w{24}$/,
                message: 'Invalid ID',
            },
        },
    },
    component: () =>
        import("./views/event/form"),
},
{
    path: "/popup/management",
    name: "popup-management",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/popup/list"),
},
{
    path: "/popup/form",
    name: "popup-form",
    props: (route) => ({ id: route.query.id }),
    meta: {
        requiresAuth: true,
        queryValidation: {
            id: {
                validator: /^\w{24}$/,
                message: 'Invalid ID',
            },
        },
    },
    component: () =>
        import("./views/popup/form"),
},

{
    path: "/setting/:section(provider|advance)",
    name: "setting",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/setting/form"),
},
{
    path: "/setting/uploader",
    name: "setting-uploader",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/setting/uploader"),
},
{
    path: "/money-logs/honorlink",
    name: "honorlink-money-logs",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/log/honorlink-money-logs"),
},
{
    path: "/asset-log/detailed",
    name: "asset-logs-detailed",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/log/asset-detailed"),
},
{
    path: "/asset-log/agent-commissions/:range(all|week)",
    name: "agent-commisions",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/log/agent-commissions"),
},
{
    path: "/log/asset/:role(agent|player)/:column(points|balance|commissions)/:type(all|online|offline)",
    name: "asset-logs",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/log/asset"),
},
{
    path: "/log/activity/:mode(login|block)",
    name: "activity-logs",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/log/activity"),
},
{
    path: "/report/monthly",
    name: "monthly-report",
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import("./views/report/monthly"),
},
{
    path: "/login",
    name: "login",
    meta: { requiresVisitor: true },
    component: () =>
        import("./views/account/login"),
},
// Redirect any unmatched routes to the 404 page. This may
// require some server configuration to work in production:
// https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
{
    path: "/:catchAll(.*)",
    redirect: "/not-found",
},
// {
//     path: "/pages/maintenance",
//     name: "Maintenance",
//     meta: { authRequired: true },
//     component: () =>
//         import ("./views/utility/maintenance"),
// },
{
    path: "/unauthorized",
    name: "unauthorized",
    component: () =>
        import("./views/utility/unauthorized"),
},
{
    path: "/not-found",
    name: "not-found",
    component: () =>
        import("./views/utility/not-found"),
},

];