<template>
    <Layout>
        <!-- <h4 class="mb-1 font-size-18">
                {{ $t(`asset logs`) }}
                <span
                    class="ms-1 badge bg-gradient"
                    :class="{
                        'bg-primary': $route.params.role == 'player',
                        'bg-danger': $route.params.role == 'agent',
                    }"
                    >{{ $t($route.params.role) }}</span
                >
                <span class="ms-1 badge bg-dark bg-gradient">{{
                    $t($route.params.column)
                }}</span>
            </h4> -->
        <MoneyManagementTabs />
        <div class="card data">
            <div class="card-header bg-light bg-soft filters">
                <b-form inline>
                    <table
                        class="table table-bordered table-nowrap mb-0 table-form">
                        <tbody>
                            <tr>
                                <td class="text-center hidden">
                                    {{ $t('date') }}
                                </td>
                                <td class="bg-white">
                                    <div class="d-flex gap-1">
                                        <datepicker
                                            v-model="filters.from"
                                            :first-day-of-week="0"
                                            lang="en"
                                            confirm
                                            class="form-control bg-white"></datepicker>

                                        <label class="input-group-text"
                                            >~</label
                                        >
                                        <datepicker
                                            v-model="filters.to"
                                            :first-day-of-week="0"
                                            lang="en"
                                            confirm
                                            class="form-control bg-white"></datepicker>
                                        <button
                                            class="btn btn-outline-info flex-grow-1"
                                            @click="setDay('today')">
                                            {{ $t('today') }}
                                        </button>
                                        <button
                                            class="btn btn-outline-success flex-grow-1"
                                            @click="setDay('yesterday')">
                                            {{ $t('yesterday') }}
                                        </button>
                                    </div>
                                </td>
                                <td
                                    class="text-center hidden"
                                    v-if="
                                        ['player', 'agent'].includes(
                                            this.filters.role
                                        )
                                    ">
                                    {{ $t('username') }}
                                </td>
                                <td
                                    class="bg-white"
                                    v-if="
                                        ['player', 'agent'].includes(
                                            this.filters.role
                                        )
                                    ">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="
                                            $t(`enter exact username`)
                                        "
                                        v-model="filters.username" />
                                </td>
                                <td class="text-center hidden">
                                    {{ $t('keyword') }}
                                </td>
                                <td class="bg-white">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t(`enter keyword`)"
                                        v-model="filters.search" />
                                </td>
                                <td class="text-center">
                                    <button
                                        :class="`btn btn-danger ${
                                            loading ? 'disabled' : ''
                                        }`"
                                        @click="resetFilter()">
                                        {{ $t('reset') }}
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </b-form>
            </div>
            <div class="card-body">
                <multiselect
                    style="width: 120px"
                    class="multiselect-sm me-0"
                    :placeholder="`${$t('limit')}`"
                    v-model="filters.limit"
                    :options="[50, 100, 500, 1000]"
                    :preselect-first="true"
                    :can-deselect="false"
                    :can-clear="false">
                    <template v-slot:singlelabel="{ value }">
                        <span class="mx-auto">{{
                            $t('view') + ' ' + value.value
                        }}</span>
                    </template>
                </multiselect>
                <div ref="tableScroller" class="table-responsive">
                    <LoadingTable
                        :cols="8"
                        :rows="50"
                        :td_small_class="`d-none`"
                        class="table-bordered table-striped"
                        v-if="loading" />
                    <table
                        v-else
                        class="table table-bordered table-hover table-striped table-nowrap mb-0 table-list">
                        <thead class="hidden">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">
                                    {{ $t('user') }}
                                </th>
                                <th scope="col">
                                    {{ $t('type') }}
                                </th>
                                <th
                                    scope="col"
                                    v-if="$route.params.role == 'player'">
                                    {{ $t('game result') }}
                                </th>
                                <th scope="col">
                                    {{
                                        $t(
                                            $route.params.column == 'points'
                                                ? 'previous points'
                                                : 'previous balance'
                                        )
                                    }}
                                </th>
                                <th scope="col">
                                    {{
                                        $t(
                                            $route.params.column == 'points'
                                                ? 'occurrence points'
                                                : 'incurred amount'
                                        )
                                    }}
                                </th>
                                <th scope="col">
                                    {{
                                        $t(
                                            $route.params.column == 'points'
                                                ? 'accumulated points'
                                                : 'current balance'
                                        )
                                    }}
                                </th>

                                <th scope="col">
                                    {{ $t('note') }}
                                </th>
                                <th scope="col" v-if="$route.params.role == 'agent'">
                                    {{ $t('user') }}
                                </th>
                                <th scope="col">
                                    {{ $t('created at') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(r, i) in data.list" :key="r._id">
                                <td class="text-center">
                                    {{
                                        i +
                                        1 +
                                        (data.current_page - 1) * data.per_page
                                    }}
                                </td>
                                <td class="text-start">
                                    <div class="td-username">
                                        <a
                                            v-if="r.user"
                                            href="javascript:void(0)"
                                            @click="
                                                goToTicketForm(
                                                    r.user.username,
                                                    $route.params.role
                                                )
                                            ">
                                            <i
                                                class="bx bx-envelope bx-tada-hover fs-4"></i
                                        ></a>

                                        <a
                                            v-if="r.user"
                                            :title="$t(`click to view`)"
                                            href="javascript:void(0)"
                                            @click="
                                                goToUserForm(
                                                    r.user_id,
                                                    $route.params.role
                                                )
                                            "
                                            class="link-username"
                                            >
                                            <span v-if="r.role=='agent'" :class="`lvl lvl-${r.user[$route.params.role].level}`">{{$t(`level ${r.user[$route.params.role].level}`)}}</span>
                                            <i v-else :class="`mdi mdi-numeric-${r.user[$route.params.role].level}-box level-${r.user[$route.params.role].level} fs-5`"></i>
                                         
                                            {{ r.user.username }}</a
                                        >
                                    </div>
                                </td>
                                <td class="text-center" v-if="r.bet">
                                    {{ r.bet.title ? $t(r.bet.title) : null }}
                                </td>
                                <td class="text-center" v-else>
                                    {{ $t(r.type == 'agent-to-player-subtract-balance' ? 'agent-to-player-add-balance' : r.type == 'agent-to-player-add-balance' ? 'agent-to-player-subtract-balance' : r.type) }}
                                </td>
                                <td
                                    class="text-center"
                                    v-if="$route.params.role == 'player'">
                                    <div
                                        v-if="
                                            r.bet &&
                                            r.bet.reference_details &&
                                            r.bet.reference_details.external &&
                                            r.bet.reference_details.external
                                                .detail &&
                                            r.bet.reference_details.external
                                                .detail.data
                                        ">
                                        {{
                                            r.bet.provider == 'honorlink' &&
                                            r.bet.game == 'baccarat' &&
                                            r.bet.reference_details
                                                ? $t(
                                                      [
                                                          'Player',
                                                          'Banker',
                                                      ].includes(
                                                          r.bet
                                                              .reference_details
                                                              .external.detail
                                                              .data.result
                                                              .outcome
                                                      )
                                                          ? r.bet
                                                                .reference_details
                                                                .external.detail
                                                                .data.result
                                                                .outcome +
                                                                ' Wins'
                                                          : r.bet
                                                                .reference_details
                                                                .external.detail
                                                                .data.result
                                                                .outcome
                                                  )
                                                : null
                                        }}
                                    </div>
                                </td>
                                <td class="text-end">
                                    {{ numberString(r.initial_value) }}
                                </td>
                                <td class="text-end">
                                    <!-- {{ numberString(r.amount) }}
                                    <br> -->
                                    {{ numberString(r.type == 'agent-to-player-subtract-balance' ? -(r.amount) : r.type == 'agent-to-player-add-balance' ? -(r.amount) : r.amount) }}
                                </td>
                                <td class="text-end">
                                    {{ numberString(r.updated_value) }}
                                </td>
                                <td class="text-start text-muted">
                                    {{ r.note }}
                                </td>
                                <th class="text-center" v-if="$route.params.role == 'agent'">
                                    <div v-if="r.note == 'Subtract balance' || r.note == 'Add balance'">
                                        <div v-if="r.transaction">
                                            <div v-if="r.transaction.user">
                                                {{ r.transaction.user.username ?  r.transaction.user.username : ''}}
                                            </div>
                                        </div>
                                    </div>
                                </th>
                                <td class="text-center">
                                    {{ dateYmD(r.created_at, true) }}
                                </td>
                                <td class="mobile">
                                    <div v-if="!r.bet" class="fw-bold mb-2">
                                        {{ $t(r.type) }}
                                    </div>
                                    <div class="cell-card align-items-baseline">
                                        <div>
                                            <div class="td-username">
                                                <a
                                                    v-if="r.user"
                                                    href="javascript:void(0)"
                                                    @click="
                                                        goToTicketForm(
                                                            r.user.username,
                                                            $route.params.role
                                                        )
                                                    ">
                                                    <i
                                                        class="bx bx-envelope bx-tada-hover fs-4"></i
                                                ></a>

                                                <a
                                                    v-if="r.user"
                                                    :title="$t(`click to view`)"
                                                    href="javascript:void(0)"
                                                    @click="
                                                        goToUserForm(
                                                            r.user_id,
                                                            $route.params.role
                                                        )
                                                    "
                                                    class="link-username"
                                                    ><span v-if="r.role=='agent'" :class="`lvl lvl-${r.user[$route.params.role].level}`">{{$t(`level ${r.user[$route.params.role].level}`)}}</span>
                                            <i v-else :class="`mdi mdi-numeric-${r.user[$route.params.role].level}-box level-${r.user[$route.params.role].level} fs-5`"></i>
                                                    {{ r.user.username }}</a
                                                >
                                            </div>
                                            <div v-if="r.bet" class="fw-bold">
                                                {{
                                                    r.bet.title
                                                        ? $t(r.bet.title)
                                                        : null
                                                }}
                                            </div>

                                            <div>
                                                {{
                                                    dateYmD(r.created_at, true)
                                                }}
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                class="text-end d-flex justify-content-between text-muted">
                                                <span>
                                                    {{
                                                        $t(
                                                            $route.params
                                                                .column ==
                                                                'points'
                                                                ? 'occurrence points'
                                                                : 'incurred amount'
                                                        )
                                                    }}:</span
                                                >
                                                {{
                                                    numberStringDecimal(
                                                        r.amount
                                                    )
                                                }}
                                            </div>
                                            <div
                                                class="text-end d-flex justify-content-between text-muted">
                                                <span>
                                                    {{
                                                        $t(
                                                            $route.params
                                                                .column ==
                                                                'points'
                                                                ? 'previous points'
                                                                : 'previous balance'
                                                        )
                                                    }}:</span
                                                >
                                                {{
                                                    numberStringDecimal(
                                                        r.initial_value
                                                    )
                                                }}
                                            </div>

                                            <div
                                                class="text-end d-flex justify-content-between text-muted">
                                                <span>
                                                    {{
                                                        $t(
                                                            $route.params
                                                                .column ==
                                                                'points'
                                                                ? 'accumulated points'
                                                                : 'current balance'
                                                        )
                                                    }}:</span
                                                >
                                                {{
                                                    numberStringDecimal(
                                                        r.updated_value
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div
                        class="no-data-placeholder"
                        v-if="!data.list.length && !loading">
                        {{ $t('no data found') }}
                    </div>
                </div>
                <Pagination v-if="!loading" :data="data" @emitPage="initList" />
            </div>
        </div>
    </Layout>
</template>

<script>
import Datepicker from 'vue3-datepicker';
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import LoadingTable from '@/components/loading-table';
import Pagination from '../../../components/pagination.vue';
import Multiselect from '@vueform/multiselect';
import Switches from 'vue-switches';
import MoneyManagementTabs from '@/components/money-management-tabs';

import { mapActions, mapGetters } from 'vuex';
import random from '../../../mixins/random';
import datetime from '../../../mixins/datetime';
import number from '../../../mixins/number';
import ui from '../../../mixins/ui';
export default {
    components: {
        Layout,
        PageHeader,
        Pagination,
        Switches,
        LoadingTable,
        Multiselect,
        Datepicker,
        MoneyManagementTabs,
    },
    mixins: [datetime, number, random, ui],
    data() {
        return {
            test: null,
            title: `players`,
            filters: {
                limit: 50,
                from: new Date(this.firstDateOftheMonth()),
                to: new Date(),
                search: null,
                username: null,
                column: this.$route.params.column,
                role: this.$route.params.role,
            },
            column_options: [
                { name: this.$t('points'), value: 'points' },
                { name: this.$t('balance'), value: 'balance' },
                { name: this.$t('commissions'), value: 'commissions' },
            ],
            role_options: [
                { name: this.$t('player'), value: 'player' },
                { name: this.$t('agent'), value: 'agent' },
            ],
            data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            loading: false,
        };
    },
    watch: {
        filters: {
            async handler() {
                this.initList(1);
            },
            deep: true,
        },
        '$store.state.key'() {
            this.initList(1);
        },
    },
    methods: {
        ...mapActions('log', {
            assetGetList: 'getList',
        }),
        async initList(p) {
            this.resetScroll();
            var pl = {
                limit: this.filters.limit,
                online:
                    this.$route.params.type == 'online'
                        ? true
                        : this.$route.params.type == 'all'
                        ? 'all'
                        : false,
                log_type: 'asset',
                page_no: p ? p : 1,
                from: this.dateYmD(this.filters.from) + ' 00:00:00',
                to: this.dateYmD(this.filters.to) + ' 23:59:59',
                search: this.filters.search,
                username: ['player', 'agent'].includes(this.filters.role)
                    ? this.filters.username
                    : null,
                role: this.$route.params.role,
                column: this.$route.params.column,
            };
            this.loading = true;
            const data = await this.assetGetList(pl);
            this.data.list = data.data;
            this.data.links = data.links;
            this.data.current_page = data.current_page;
            this.data.per_page = data.per_page;
            this.data.last_page = data.last_page;
            this.data.total = data.total;
            this.loading = false;
        },
        resetFilter() {
            this.filters.column = null;
            this.filters.role = null;
            this.filters.username = null;
            this.filters.search = null;
        },
        setDay(mode) {
            if (mode == 'today') {
                this.filters.from = new Date();
                this.filters.to = new Date();
            } else {
                this.filters.from = this.addDaysToDate(-1);
                this.filters.to = this.addDaysToDate(-1);
            }
        },
    },
    mounted() {
        this.$watch(
            () => this.$route.params,
            (n, o) => {
                if (this.$route.query.window) {
                    this.readQuery();
                } else {
                    if (this.$route.name == 'asset-logs') this.initList(1);
                }
            }
        );
        this.initList(1);
    },
};
</script>
