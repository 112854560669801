saction.id<template>
    <Layout>
        <ul class="nav nav-tabs nav-fill custom-tabs">
            <li
                v-show="$route.params.provider == 'hilton'"
                class="nav-item"
                >
                <a
                    :disabled="loading"
                    class="nav-link active"
                    aria-current="page"
                    href="javascript:void(0);"
                    >{{ $t('hilton') }}</a
                >
            </li>
            <li
                v-show="$route.params.provider == 'mamahot'"
                class="nav-item"
                >
                <a
                    :disabled="loading"
                    class="nav-link active"
                    aria-current="page"
                    href="javascript:void(0);"
                    >{{ $t('mamahot') }}</a
                >
            </li>
            <li
                v-show="!['hilton','mamahot'].includes($route.params.provider)"
                class="nav-item"
                v-for="v in vendors[$route.params.game]"
                :key="v">
                <a
                    :disabled="loading"
                    class="nav-link"
                    :class="{ active: filters.vendor === v }"
                    aria-current="page"
                    href="#"
                    @click="filters.vendor = v"
                    >{{ $t(v) }}</a
                >
            </li>
        </ul>
        <div
            class="card data"
            style="border-top-left-radius: 0; border-top-right-radius: 0">
            <div class="card-header bg-light bg-soft filters">
                <b-form inline>
                    <table
                        class="table table-bordered table-nowrap mb-0 table-form">
                        <tbody>
                            <tr v-if="agents_options.length">
                                <td class="text-center hidden">
                                    {{ $t('agent') }}
                                </td>
                                <td class="bg-white" colspan="7">
                                    <div
                                        class="d-flex justify-content-start gap-1 flex-wrap">
                                        <multiselect
                                            style="flex: 0 1 150px"
                                            class="multiselect-sm m-0"
                                            v-for="(
                                                agents, i
                                            ) in agents_options"
                                            :key="i"
                                            :placeholder="
                                                agents.length
                                                    ? $t(`select referrer`)
                                                    : $t(`no sub agents`)
                                            "
                                            :options="agents"
                                            v-model="referrer_ids[i]"
                                            @change="handleFilter"
                                            @clear="handleClear(i)"
                                            :can-deselect="false"
                                            :searchable="true"
                                            :clear-on-blur="true">
                                            <template
                                                v-slot:singlelabel="{ value }">
                                                <div
                                                    class="mlabel flex-fill ps-2">
                                                    <i
                                                        :class="`mdi mdi-numeric-${value.value.agent.level}-box fs-5  level-${value.value.agent.level}`"></i>
                                                    <span>{{
                                                        value.label
                                                    }}</span>
                                                </div>
                                            </template>
                                            <template
                                                v-slot:option="{ option }">
                                                <div>
                                                    <i
                                                        :class="`mdi mdi-numeric-${option.value.agent.level}-box fs-5 level-${option.value.agent.level} me-1`">
                                                    </i>
                                                    <span>{{
                                                        option.value.username
                                                    }}</span>
                                                </div>
                                                <!-- <div>{{option.value.label}}</div> -->
                                            </template>
                                        </multiselect>
                                        <button
                                            class="btn btn-secondary btn-sm"
                                            @click="handleClear(0)">
                                            {{ $t('clear') }}
                                        </button>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <!-- <td
                                            class="bg-white text-center"
                                            v-if="show_filter"
                                        >
                                            <multiselect
                                                :placeholder="`${$t('limit')}`"
                                                v-model="filters.limit"
                                                :options="[50,100,500,1000]"
                                                :preselect-first="true"
                                                :can-deselect="false"
                                                :can-clear="false"
                                            >
                                                <template v-slot:singlelabel="{value}">
                                                    <span class="mx-auto">{{ $t('view')+' '+ value.value }}</span>
                                                </template>
                                            </multiselect>
                                        </td> -->
                                <td
                                    class="text-center hidden"
                                    v-if="show_filter">
                                    {{ $t('date') }}
                                </td>
                                <td class="bg-white" v-if="show_filter">
                                    <div class="d-flex gap-1 w-100">
                                        <datepicker
                                            v-model="filters.from"
                                            :first-day-of-week="0"
                                            lang="en"
                                            confirm
                                            class="form-control bg-white"></datepicker>

                                        <label class="input-group-text"
                                            >~</label
                                        >
                                        <datepicker
                                            v-model="filters.to"
                                            :first-day-of-week="0"
                                            lang="en"
                                            confirm
                                            class="form-control bg-white"></datepicker>
                                        <button
                                            class="btn btn-outline-info flex-grow-1"
                                            @click="setDay('today')">
                                            {{ $t('today') }}
                                        </button>
                                        <button
                                            class="btn btn-outline-success flex-grow-1"
                                            @click="setDay('yesterday')">
                                            {{ $t('yesterday') }}
                                        </button>
                                        <button
                                            class="btn btn-outline-warning flex-grow-1"
                                            @click="setDay('2 weeks')">
                                            {{ $t('2 weeks') }}
                                        </button>
                                    </div>
                                </td>

                                <td
                                    class="text-center hidden"
                                    v-if="show_filter">
                                    {{ $t('sortation') }}
                                </td>
                                <td
                                    class="bg-white"
                                    v-if="show_filter"
                                    style="width: 15%">
                                    <multiselect
                                        :placeholder="`${$t('select status')}`"
                                        v-model="filters.status"
                                        :options="status_options">
                                        <template
                                            v-slot:singlelabel="{ value }">
                                            <span
                                                class="mlabel flex-fill ps-2"
                                                >{{ $t(value.value) }}</span
                                            >
                                        </template>
                                        <template v-slot:option="{ option }">
                                            {{ $t(option.value) }}
                                        </template>
                                    </multiselect>
                                </td>
                                <td
                                    class="text-center hidden"
                                    v-if="show_filter">
                                    {{ $t('keyword') }}
                                </td>
                                <td class="bg-white" v-if="show_filter">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t(`enter keyword`)"
                                        v-model="filters.keyword" />
                                </td>
                                <!-- <td
                                            class="text-center"
                                            v-if="show_filter"
                                        >
                                            {{ $t('username') }}
                                        </td>
                                        <td
                                            class="bg-white"
                                            v-if="show_filter"
                                        >
                                            <input
                                                type="text"
                                                class="form-control"
                                                :placeholder="
                                                        $t(`enter exact username`)
                                                    "
                                                v-model="filters.username"
                                            />
                                        </td> -->
                                <td
                                    class="bg-white text-center reset"
                                    v-if="show_filter">
                                    <div class="button-items">
                                        <button
                                            :class="`btn btn-danger ${
                                                loading ? 'disabled' : ''
                                            }`"
                                            @click="resetFilter()">
                                            {{ $t('reset') }}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <LoadingTable
                        :hide_thead="true"
                        :cols="6"
                        :rows="1"
                        class="table-bordered bg-white mb-0 table-grid"
                        v-if="total_loading" />
                    <table v-else class="table table-bordered mb-0 table-grid">
                        <tbody>
                            <tr class="bg-white">
                                <td>
                                    <div class="text-muted">
                                        {{ $t('total bet count') }}
                                    </div>
                                    <div class="text-end">
                                        {{ numberString(total['total_bets']) }}
                                    </div>
                                </td>
                                <td>
                                    <div class="text-muted">
                                        {{ $t('total bet amount') }}
                                    </div>
                                    <div class="text-end text-primary">
                                        {{
                                            numberString(
                                                total['total_bet_amount']
                                            )
                                        }}
                                        {{ $t('KRW') }}
                                    </div>
                                </td>
                                <td>
                                    <div class="text-muted">
                                        {{ $t('total dividend amount') }}
                                    </div>
                                    <div class="text-end text-danger">
                                        {{
                                            numberString(total['total_bet_win'])
                                        }}
                                        {{ $t('KRW') }}
                                    </div>
                                </td>
                                <!-- <td>
                                    <div class="text-muted">
                                        {{ $t('total payment points') }}
                                    </div>
                                    <div class="text-end text-danger">
                                        {{
                                            numberString(
                                                total['total_player_rebate']
                                            )
                                        }}
                                        {{ $t('KRW') }}
                                        {{
                                            total['total_commission']
                                                ? `(${numberString(
                                                      total['total_commission']
                                                  )})`
                                                : ''
                                        }}
                                    </div>
                                </td> -->
                                <td>
                                    <div class="text-muted">
                                        {{ $t('total player points') }}
                                    </div>
                                    <div class="text-end text-danger">
                                        {{
                                            numberString(
                                                total['total_player_rebate'] +
                                                    total[
                                                        'total_player_commission'
                                                    ]
                                            )
                                        }}
                                        {{ $t('KRW') }}
                                    </div>
                                </td>
                                <td>
                                    <div class="text-muted">
                                        {{ $t('total agent commision') }}
                                    </div>
                                    <div class="text-end text-danger">
                                        {{
                                            numberString(
                                                total['total_agent_commission']
                                            )
                                        }}
                                        {{ $t('KRW') }}
                                    </div>
                                </td>
                                <td>
                                    <div class="text-muted">
                                        {{ $t('profit') }}
                                    </div>
                                    <div class="text-end text-success">
                                        {{
                                            numberString(
                                                total['total_difference']
                                            )
                                        }}
                                        {{ $t('KRW') }}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </b-form>
            </div>
            <div class="card-body pt-1">
                <multiselect
                    style="width: 120px"
                    class="multiselect-sm me-0"
                    :placeholder="`${$t('limit')}`"
                    v-model="filters.limit"
                    :options="[50, 100, 500, 1000]"
                    :preselect-first="true"
                    :can-deselect="false"
                    :can-clear="false">
                    <template v-slot:singlelabel="{ value }">
                        <span class="mx-auto">{{
                            $t('view') + ' ' + value.value
                        }}</span>
                    </template>
                </multiselect>
                <div ref="tableScroller" class="table-responsive">
                    <LoadingTable
                        :cols="7"
                        :rows="50"
                        class="table-bordered table-striped"
                        :td_small_class="`d-none`"
                        v-if="loading" />
                    <table
                        v-else
                        class="table table-bordered table-hover table-striped table-nowrap mb-0 table-list">
                        <thead class="hidden">
                            <tr>
                                <th scope="col">{{ $t('betting number') }}</th>
                                <th scope="col">
                                    {{ $t('date') }}
                                </th>
                                <th scope="col">
                                    {{ $t('username') }}
                                </th>
                                <th scope="col">
                                    {{ $t('product') }}
                                </th>
                                <th scope="col">
                                    {{ $t('game name') }}
                                </th>
                                <th
                                    scope="col"
                                    v-if="
                                        $route.params.provider == 'honorlink'
                                    ">
                                    {{ $t('game id') }}
                                </th>
                                <th scope="col">
                                    {{ $t('bet id') }}
                                </th>
                                <th scope="col">
                                    {{ $t('bet amount') }}
                                </th>
                                <!-- <th scope="col">
                                    {{ $t('result time') }}
                                </th> -->
                                <th scope="col">
                                    {{ $t('payment amount') }}
                                </th>
                                <th scope="col">
                                    {{ $t('difference') }}
                                </th>
                                <th scope="col">
                                    {{ $t('balance') }}
                                </th>
                                <th scope="col">
                                    {{ $t('player points') }}
                                </th>
                                <th
                                    scope="col"
                                    v-if="!['dolwin'].includes(client_name)">
                                    {{ $t('agent commission') }}
                                </th>
                                <th scope="col">
                                    {{ $t('game result') }}
                                </th>
                                <th scope="col">
                                    {{ $t('betting result') }}
                                </th>
                                <th scope="col">
                                    {{ $t('details') }}
                                </th>
                                <!-- <th scope="col">
                                            {{ $t('asset log') }}
                                        </th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="r in data.list"
                                :key="r._id"
                                :class="{ skipped: r.agent_claim_commission_skipped || (r.difference == 0 && r.game!='slot')}">
                                <td class="text-center">
                                    <!-- {{ r.difference == 0}} -->
                                    <div v-if="!['hilton','mamahot'].includes($route.params.provider)">
                                        {{
                                            [
                                                'kplay',
                                                'goldlion',
                                                'petsplay',
                                            ].includes($route.params.provider)
                                                ? r.reference_debit.txn_id
                                                : r.reference_debit.transaction.id
                                        }}
                                    </div>
                                </td>
                                <td class="text-center">
                                    {{ dateYmD(r.created_at, true) }}
                                </td>
                                <td class="text-start">
                                    <div class="td-username" v-if="r.user">
                                        <a
                                            href="javascript:void(0)"
                                            @click="
                                                goToTicketForm(
                                                    r.user.username,
                                                    r.user.role
                                                )
                                            ">
                                            <i
                                                class="bx bx-envelope bx-tada-hover fs-4"></i
                                        ></a>
                                        <a
                                            :title="$t(`click to view`)"
                                            href="javascript:void(0)"
                                            @click="
                                                goToUserForm(
                                                    r.user_id,
                                                    r.user.role
                                                )
                                            "
                                            class="link-username"
                                            ><i
                                                :class="`mdi ${
                                                    r.user.type == 'online'
                                                        ? 'mdi-web text-info'
                                                        : r.user.type ==
                                                          'offline'
                                                        ? 'mdi-storefront text-success'
                                                        : ''
                                                } fs-5`"></i>
                                            <i
                                                :class="`mdi ${
                                                    r.user.role == 'agent'
                                                        ? 'mdi-account-tie text-danger'
                                                        : r.user.role ==
                                                          'player'
                                                        ? 'mdi-account-cowboy-hat text-primary'
                                                        : ''
                                                } fs-5`"></i>
                                            <i
                                                :class="`mdi mdi-numeric-${
                                                    r.user[r.user.role].level
                                                }-box level-${
                                                    r.user[r.user.role].level
                                                } fs-5`"></i>
                                            {{ r.user.username }}</a
                                        >
                                    </div>
                                </td>
                                <td class="text-center">
                                    {{ r.vendor ? $t(r.vendor) : '' }}
                                </td>
                                <td class="text-center">
                                    {{ r.title }}
                                </td>
                                <td>
                                    {{ r.reference_debit.username }}
                                </td>
                                <td
                                    class="text-center"
                                    v-if="
                                        $route.params.provider == 'honorlink'
                                    ">
                                    {{
                                        r.reference_debit.transaction.details
                                            .game.id
                                    }}
                                </td>
                                <td class="text-end">
                                    {{ numberString(r.amount) }}
                                </td>
                                <!-- <td>
                                    {{ dateYmD(r.created_at, true) }}
                                </td> -->
                                <td class="text-end text-danger">
                                    {{
                                        r.win_amount && r.status != 'cancel'
                                            ? numberString(r.win_amount)
                                            : ''
                                    }}
                                </td>
                                <td class="text-end">
                                    {{ numberString(r.difference) }}
                                </td>
                                <td class="text-end">
                                    <!-- <div v-if="r.user">
                                        <div v-if="$route.params.provider=='mamahot'">
                                            {{ r[`reference_credit`] ? numberString(parseInt(r[`reference_credit`].afterMoney)): '' }}
                                        </div>
                                        <div v-else>
                                            PRIMARY
                                            {{
                                                r[`${r.user.role}_asset_log`]
                                                    ? numberString(
                                                        r[
                                                            `${r.user.role}_asset_log`
                                                        ].updated_value
                                                    )
                                                    : ''
                                            }}
                                        </div>
                                    </div> 
                                     
                                    -->
                                    <div v-if="r['reference_debit']['transaction'] && r['reference_debit']['transaction']['target']">
                                        {{ numberString(r['reference_debit']['transaction']?.target.balance - r['amount'] + r['win_amount']) }}
                                    </div>
                                    <div  v-else>
                                        {{ r[`${r.user.role}_asset_log`]? numberString(r[`${r.user.role}_asset_log`].updated_value): '' }}
                                    </div>
                                </td>
                                <td class="text-end">
                                    {{
                                        r.player_claim_rebate_amount
                                            ? `${numberString(
                                                  r.player_claim_rebate_amount
                                              )}`
                                            : ''
                                    }}
                                    {{
                                        r.player_claim_commission_amount
                                            ? `(${numberString(
                                                  r.player_claim_commission_amount
                                              )})`
                                            : ''
                                    }}
                                </td>
                                <td
                                    class="text-end"
                                    v-if="!['dolwin'].includes(client_name)">
                                    {{
                                        r.agent_claim_commission_amount
                                            ? numberString(
                                                  r.agent_claim_commission_amount
                                              )
                                            : ''
                                    }}
                                </td>
                                <td class="text-center">
                                    <span v-if="r.card_info">
                                        {{
                                            $t(
                                                ['Player', 'Banker'].includes(
                                                    r.card_info.outcome
                                                )
                                                    ? r.card_info.outcome +
                                                          ' Wins'
                                                    : r.card_info.outcome
                                            )
                                        }}</span
                                    >
                                </td>
                                <td class="text-center">
                                    <span
                                        :class="{
                                            'text-primary':
                                                r.status == 'pending',
                                            'text-danger': r.status == 'win',
                                            'text-info': r.status == 'lose',
                                            'text-secondary':
                                                r.status == 'cancel',
                                        }"
                                        >{{ $t(r.status) }}</span
                                    >
                                </td>

                                <td class="text-center">
                                    <div class="button-items">
                                        <button
                                            :disabled="view_loading"
                                            class="btn btn-outline-info btn-sm"
                                            @click="showViewModal(r._id)">
                                            {{ $t('view') }}
                                        </button>
                                        <button
                                            :disabled="view_loading"
                                            v-if="r.status == 'pending'"
                                            class="btn btn-outline-success btn-sm"
                                            @click="showResultModal(r)">
                                            {{ $t('update result') }}
                                        </button>
                                        <button
                                            v-if="
                                                r.agent_claim_commission_skipped &&
                                                r.game_result != 'Tie' &&
                                                ['all', 'online'].includes(
                                                    player_mode
                                                )
                                            "
                                            class="btn btn-outline-secondary btn-sm"
                                            @click="
                                                distributeCommission(r._id)
                                            ">
                                            {{ $t('to restore') }}
                                        </button>
                                    </div>
                                </td>
                                <td class="mobile">
                                    <div
                                        class="cell-card"
                                        @click="showViewModal(r._id)">
                                        <div>
                                            <div v-if="!['hilton','mamahot'].includes($route.params.provider)">
                                                {{
                                                    [
                                                        'kplay',
                                                        'goldlion',
                                                        'petsplay',
                                                    ].includes(
                                                        $route.params.provider
                                                    )
                                                        ? r.reference_debit
                                                              .txn_id
                                                        : r.reference_debit
                                                              .transaction.id
                                                }}
                                            </div>
                                            <div class="fw-bold">
                                                {{ r.title }}
                                            </div>
                                            <div
                                                class="td-username"
                                                v-if="r.user && r.user.player">
                                                <a
                                                    href="javascript:void(0)"
                                                    @click="
                                                        goToTicketForm(
                                                            r.user.username,
                                                            'player'
                                                        )
                                                    ">
                                                    <i
                                                        class="bx bx-envelope bx-tada-hover fs-4"></i
                                                ></a>
                                                <a
                                                    :title="$t(`click to view`)"
                                                    href="javascript:void(0)"
                                                    @click="
                                                        goToUserForm(
                                                            r.user_id,
                                                            'player'
                                                        )
                                                    "
                                                    class="link-username"
                                                    ><i
                                                        :class="`mdi mdi-numeric-${r.user.player.level}-box level-${r.user.player.level} fs-5`"></i>
                                                    {{ r.user.username }}</a
                                                >
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                {{ numberString(r.amount) }}
                                            </div>
                                            <div class="text-muted">
                                                {{
                                                    dateYmD(r.created_at, true)
                                                }}
                                            </div>
                                            <div
                                                :class="{
                                                    'text-primary':
                                                        r.status == 'pending',
                                                    'text-danger':
                                                        r.status == 'win',
                                                    'text-info':
                                                        r.status == 'lose',
                                                    'text-secondary':
                                                        r.status == 'cancel',
                                                }">
                                                {{ $t(r.status) }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div
                        class="no-data-placeholder"
                        v-if="!data.list.length && !loading">
                        {{ $t('no data found') }}
                    </div>
                    <a
                        href="javascript:void(0);"
                        class="scroll-pad left"
                        @mousedown="startScrolling('left')"
                        @mouseup="endScrolling"
                        @mouseleave="endScrolling"
                        ><i class="bx bx-caret-left"></i
                    ></a>
                    <a
                        href="javascript:void(0);"
                        class="scroll-pad right"
                        @mousedown="startScrolling('right')"
                        @mouseup="endScrolling"
                        @mouseleave="endScrolling"
                        ><i class="bx bx-caret-right"></i
                    ></a>
                </div>
                <Pagination v-if="!loading" :data="data" @emitPage="initList" />
            </div>
        </div>
        <b-modal
            v-model="view_modal"
            @hidden="reset()"
            centered
            :title="$t(`details`)"
            title-class="font-18"
            body-class="pt-0"
            size="xl"
            hide-footer>
            <div
                class="loader-wrapper"
                v-if="
                    view_loading ||
                    (iframe_loading && Boolean(bet_detail.kplay_bet_detail))
                ">
                <b-spinner variant="dark"></b-spinner>
                <p class="fw-bold fs-5">{{ $t('loading') }}...</p>
            </div>
            <div class="bet-details-header">
                <div :class="`flex-fill`">
                    <table class="table table-sm table-bordered mb-0">
                        <tbody>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('date') }}
                                </th>
                                <td style="width: 35%">
                                    {{ dateYmD(bet_detail.created_at, true) }}
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('status') }}
                                </th>
                                <td style="width: 35%">
                                    <span
                                        :class="{
                                            'text-primary':
                                                bet_detail.status == 'pending',
                                            'text-danger':
                                                bet_detail.status == 'win',
                                            'text-info':
                                                bet_detail.status == 'lose',
                                            'text-secondary':
                                                bet_detail.status == 'cancel',
                                        }"
                                        >{{ $t(bet_detail.status) }}</span
                                    >
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('username') }}
                                </th>
                                <td style="width: 35%">
                                    {{ bet_detail.username }}
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('bet amount') }}
                                </th>
                                <td style="width: 35%">
                                    {{ numberStringDecimal(bet_detail.amount) }}
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('game') }}
                                </th>
                                <td style="width: 35%">
                                    {{
                                        bet_detail.game
                                            ? $t(bet_detail.game)
                                            : null
                                    }}
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('payment amount') }}
                                </th>
                                <td style="width: 35%">
                                    {{
                                        numberStringDecimal(
                                            bet_detail.win_amount
                                        )
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="baccarat-cards" v-if="bet_detail.card_info">
                    <span
                        :class="`tie-indicator ${
                            bet_detail.card_info.outcome == 'Tie'
                                ? 'result'
                                : ''
                        }`"
                        >{{ $t('Tie') }}</span
                    >
                    <div
                        :class="`text-start d-flex align-items-center gap-1 player ${
                            bet_detail.card_info.outcome == 'Player'
                                ? 'result'
                                : ''
                        }`">
                        <div class="side">
                            {{ $t('baccarat player') }}
                        </div>
                        <div class="cards">
                            <img
                                v-for="(c, i) in bet_detail.card_info.player
                                    .cards"
                                :key="i"
                                :src="require(`@/assets/images/cards/${c}.png`)"
                                :alt="`${c}.png`" />
                        </div>
                        <div class="score">
                            {{ bet_detail.card_info.player.score }}
                        </div>
                    </div>
                    <div
                        :class="`text-start d-flex align-items-center gap-1 banker ${
                            bet_detail.card_info.outcome == 'Banker'
                                ? 'result'
                                : ''
                        }`">
                        <div class="score">
                            {{ bet_detail.card_info.banker.score }}
                        </div>
                        <div class="cards">
                            <img
                                v-for="(c, i) in bet_detail.card_info.banker
                                    .cards"
                                :key="i"
                                :src="require(`@/assets/images/cards/${c}.png`)"
                                :alt="`${c}.png`" />
                        </div>
                        <div class="side">
                            {{ $t('baccarat banker') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table
                    class="table table-bordered table-striped table-nowrap table-sm mb-0">
                    <thead v-show="bet_detail.agent_commissions.length">
                        <tr class="bg-light bg-soft fs-5">
                            <th colspan="6" class="text-start">
                                {{ $t('agent commission') }}
                            </th>
                        </tr>
                        <tr>
                            <th>
                                {{ $t('username') }}
                            </th>
                            <th>
                                {{ $t('commission') }}
                            </th>
                            <th>
                                {{ $t('previous holding amount') }}
                            </th>
                            <th>
                                {{ $t('incurred amount') }}
                            </th>
                            <th>
                                {{ $t('current amount') }}
                            </th>
                            <th>
                                {{ $t('details') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="p in bet_detail.agent_commissions"
                            :key="p._id">
                            <td>
                                {{ p.user ? p.user.username : '' }}
                            </td>
                            <td class="text-end text-success">
                                {{
                                    numberStringDecimal(
                                        p.commission_percentage
                                    )
                                }}%
                            </td>
                            <td class="text-end">
                                {{ numberStringDecimal(p.initial_value) }}
                            </td>
                            <td class="text-end fw-medium">
                                {{ numberStringDecimal(p.amount) }}
                            </td>
                            <td class="text-end">
                                {{ numberStringDecimal(p.updated_value) }}
                            </td>
                            <td class="text-start text-muted">
                                {{ p.note }}
                            </td>
                        </tr>
                    </tbody>
                    <thead v-show="bet_detail.player_commissions.length">
                        <tr class="bg-light bg-soft fs-5">
                            <th colspan="6" class="text-start">
                                {{ $t('player commission') }}
                            </th>
                        </tr>
                        <tr>
                            <th>
                                {{ $t('username') }}
                            </th>
                            <th>
                                {{ $t('commission') }}
                            </th>
                            <th>
                                {{ $t('previous holding amount') }}
                            </th>
                            <th>
                                {{ $t('incurred amount') }}
                            </th>
                            <th>
                                {{ $t('current amount') }}
                            </th>
                            <th>
                                {{ $t('details') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="p in bet_detail.player_commissions"
                            :key="p._id">
                            <td>
                                {{ p.user ? p.user.username : '' }}
                            </td>
                            <td class="text-end text-success">
                                {{
                                    numberStringDecimal(
                                        p.commission_percentage
                                    )
                                }}%
                            </td>
                            <td class="text-end">
                                {{ numberStringDecimal(p.initial_value) }}
                            </td>
                            <td class="text-end fw-medium">
                                {{ numberStringDecimal(p.amount) }}
                            </td>
                            <td class="text-end">
                                {{ numberStringDecimal(p.updated_value) }}
                            </td>
                            <td class="text-start text-muted">
                                {{ p.note }}
                            </td>
                        </tr>
                    </tbody>
                    <thead v-show="bet_detail.player_rebate.length">
                        <tr class="bg-light bg-soft fs-5">
                            <th colspan="6" class="text-start">
                                {{ $t('player rebate') }}
                            </th>
                        </tr>
                        <tr>
                            <th>
                                {{ $t('username') }}
                            </th>
                            <th>
                                {{ $t('commission') }}
                            </th>
                            <th>
                                {{ $t('previous holding amount') }}
                            </th>
                            <th>
                                {{ $t('incurred amount') }}
                            </th>
                            <th>
                                {{ $t('current amount') }}
                            </th>
                            <th>
                                {{ $t('details') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="p in bet_detail.player_rebate" :key="p._id">
                            <td>
                                {{ p.user ? p.user.username : '' }}
                            </td>
                            <td class="text-end text-success">
                                {{
                                    numberStringDecimal(
                                        p.commission_percentage
                                    )
                                }}%
                            </td>
                            <td class="text-end">
                                {{ numberStringDecimal(p.initial_value) }}
                            </td>
                            <td class="text-end fw-medium">
                                {{ numberStringDecimal(p.amount) }}
                            </td>
                            <td class="text-end">
                                {{ numberStringDecimal(p.updated_value) }}
                            </td>
                            <td class="text-start text-muted">
                                {{ p.note }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <iframe
                @load="iframe_loading = false"
                v-if="bet_detail.kplay_bet_detail"
                class="kplay-bet-detail"
                :src="bet_detail.kplay_bet_detail"
                frameborder="0"></iframe>
        </b-modal>
        <b-modal
            v-model="result_modal"
            @hidden="reset()"
            centered
            :title="$t('update result')"
            title-class="font-18"
            hide-footer>
            <b-form>
                <table class="table table-bordered table-wrap table-form mb-2">
                    <tbody>
                        <tr>
                            <th class="bg-light bg-soft">
                                {{ $t('username') }}
                            </th>
                            <td>
                                <div class="mt-2">
                                    <i
                                        :class="`mdi ${
                                            pl_result.user_type == 'online'
                                                ? 'mdi-web text-info'
                                                : pl_result.user_type ==
                                                  'offline'
                                                ? 'mdi-storefront text-success'
                                                : ''
                                        } fs-5`"></i>
                                    <i
                                        :class="`mdi ${
                                            pl_result.user_role == 'agent'
                                                ? 'mdi-account-tie text-danger'
                                                : pl_result.user_role ==
                                                  'player'
                                                ? 'mdi-account-cowboy-hat text-primary'
                                                : ''
                                        } fs-5`"></i>
                                    <i
                                        :class="`mdi mdi-numeric-${pl_result.user_level}-box level-${pl_result.user_level} fs-5`"></i>
                                    {{ pl_result.username }}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="bg-light bg-soft">
                                {{ $t('game type') }}
                            </th>
                            <td>
                                <div class="mt-2">
                                    {{ $t($route.params.game) }}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="bg-light bg-soft">
                                {{ $t('game name') }}
                            </th>
                            <td>
                                <div class="mt-2">
                                    {{ pl_result.game_title }}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="bg-light bg-soft">
                                {{ $t('bet amount') }}
                            </th>
                            <td>
                                <div class="mt-2">
                                    {{
                                        numberStringDecimal(
                                            pl_result.bet_amount
                                        )
                                    }}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="bg-light bg-soft">
                                {{ $t('result') }}
                            </th>
                            <td>
                                <multiselect
                                    :placeholder="$t('select result')"
                                    v-model="pl_result.result"
                                    :options="result_options"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            v$.pl_result.result.$error,
                                    }">
                                    <template v-slot:singlelabel="{ value }">
                                        <span
                                            class="mlabel flex-fill ps-2"
                                            :class="{
                                                'text-danger':
                                                    value.value == 'win',
                                                'text-info':
                                                    value.value == 'lose',
                                                'text-secondary':
                                                    value.value == 'cancel',
                                            }"
                                            >{{ $t(value.value) }}</span
                                        >
                                    </template>
                                    <template v-slot:option="{ option }">
                                        <span
                                            :class="{
                                                'text-danger':
                                                    option.value == 'win',
                                                'text-info':
                                                    option.value == 'lose',
                                                'text-secondary':
                                                    option.value == 'cancel',
                                            }">
                                            {{ $t(option.value) }}
                                        </span>
                                    </template>
                                </multiselect>
                                <div
                                    v-for="(item, index) in v$.pl_result.result
                                        .$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <span v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="bg-light bg-soft">
                                {{ $t('win amount') }}
                            </th>
                            <td>
                                <VueNumberFormat
                                    class="form-control text-end rounded-end"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            v$.pl_result.win_amount.$error,
                                    }"
                                    v-model:value="pl_result.win_amount"
                                    :options="{
                                        precision: 0,
                                        prefix: '',
                                        suffix: '',
                                        thousand: ',',
                                        acceptNegative: false,
                                        isInteger: true,
                                    }"></VueNumberFormat>
                                <div
                                    v-for="(item, index) in v$.pl_result
                                        .win_amount.$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <span v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="d-flex justify-content-evenly gap-1">
                    <button
                        class="btn btn-success flex-fill"
                        :class="{ disabled: loading }"
                        @click="updateResult('submit')">
                        {{ $t('submit') }}
                    </button>
                    <button
                        class="btn btn-danger flex-fill"
                        :class="{ disabled: loading }"
                        @click="result_modal = false">
                        {{ $t('cancel') }}
                    </button>
                </div>
            </b-form>
        </b-modal>
    </Layout>
</template>

<script>
import Datepicker from 'vue3-datepicker';
import Layout from '../../../layouts/main';
import PageHeader from '@/components/page-header';
import LoadingTable from '@/components/loading-table';
import Pagination from '../../../../components/pagination.vue';
import Multiselect from '@vueform/multiselect';
import Switches from 'vue-switches';
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';
import convert from '../../../../mixins/convert';
import datetime from '../../../../mixins/datetime';
import number from '../../../../mixins/number';
import ui from '../../../../mixins/ui';
import {
    requiredIf,
    required,
    minValue,
    maxValue,
    between,
    helpers,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        Layout,
        PageHeader,
        Pagination,
        Switches,
        LoadingTable,
        Multiselect,
        Datepicker,
    },
    mixins: [datetime, number, convert, ui],
    data() {
        return {
            test: null,
            title: `players`,
            show_filter: true,
            filters: {
                keyword: null,
                agent_level: null,
                referrer: null,
                referrer_username: null,
                username: null,
                from: new Date(this.addDaysToDate(-2)),
                to: new Date(),
                status: null,
                game: this.$route.params.game,
                live: false,
                vendor: this.$route.params.provider =='hilton'?'hilton':'all',
                provider: this.$route.params.provider || null,
                limit: 50,
            },
            referrer_ids: [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
            ],
            agents_options: [],
            level_options: [
                { name: this.$t('level 1'), value: 1 },
                { name: this.$t('level 2'), value: 2 },
                { name: this.$t('level 3'), value: 3 },
                { name: this.$t('level 4'), value: 4 },
                { name: this.$t('level 5'), value: 5 },
                { name: this.$t('level 6'), value: 6 },
                { name: this.$t('level 7'), value: 7 },
                { name: this.$t('level 8'), value: 8 },
                { name: this.$t('level 9'), value: 9 },
                { name: this.$t('level 10'), value: 10 },
            ],
            status_options: [
                { name: this.$t('pending'), value: 'pending' },
                { name: this.$t('win'), value: 'win' },
                { name: this.$t('lose'), value: 'lose' },
                { name: this.$t('cancel'), value: 'cancel' },
                { name: this.$t('skipped'), value: 'skipped' },
            ],
            referrer_options: [
                { name: this.$t('all'), value: 'all' },
                { name: this.$t('agent'), value: 'agent' },
                { name: this.$t('player'), value: 'player' },
            ],
            game_options: [
                { name: this.$t('slot'), value: 'slot' },
                { name: this.$t('table'), value: 'table' },
            ],
            vendors: {
                table: [
                    'all',
                    'evolution',
                    'DreamGame',
                    'WM Live',
                    'PragmaticPlay Live',
                    'Asia Gaming',
                    'bota',
                    'Betgames.tv',
                    'Skywind Live',
                ],
                slot: [
                    'all',
                    'PragmaticPlay',
                    'Booongo',
                    'CQ9',
                    'Habanero',
                    'redtiger',
                    'netent',
                    'PlaySon',
                    'PlayStar',
                    'Dragoon Soft',
                    'Blueprint Gaming',
                    'GameArt',
                    'Wazdan',
                    'EVOPLAY',
                    'PlayPearls',
                    'Mobilots',
                    'Nolimit City',
                    'Thunderkick',
                ],
            },
            data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            total: {
                total_bet_amount: 0,
                total_bet_win: 0,
                total_bets: 0,
                total_player_commission: 0,
                total_agent_commission: 0,
                total_player_rebate: 0,
                total_difference: 0,
            },
            bet_detail: {
                _id: '',
                created_at: '',
                username: '',
                game: '',
                status: '',
                amount: '',
                win_amount: '',
                player_commissions: [],
                agent_commissions: [],
                player_rebate: [],
                card_info: null,
                kplay_bet_detail: null,
                reference_credit: null,
            },
            view_loading: false,
            view_modal: false,
            iframe_loading: false,
            loading: false,
            total_loading: false,
            hoverDivLeft: 0,
            hoverDivTop: 0,
            selected_result: null,
            dropdownVisible: false,
            result_modal: false,
            result_options: [
                { name: this.$t('win'), value: 'win' },
                { name: this.$t('lose'), value: 'lose' },
                { name: this.$t('cancel'), value: 'cancel' },
            ],
            pl_result: {
                _id: null,
                game_title: null,
                bet_amount: null,
                username: null,
                user_role: null,
                user_level: null,
                user_type: null,
                result: null,
                win_amount: 0,
            },
            submitted: false,
        };
    },
    validations() {
        return {
            pl_result: {
                result: {
                    required: helpers.withMessage('required', required),
                },
                win_amount: {
                    requiredIf: helpers.withMessage(
                        'required',
                        requiredIf(function (value) {
                            if (value == 0 && this.pl_result.result == 'win') {
                                return required(value);
                            }
                            return true;
                        })
                    ),
                    minValueValue: helpers.withMessage('required', minValue(0)),
                    maxValueValue: helpers.withMessage(
                        'the maximum value is 9,999,999,999',
                        maxValue(9999999999)
                    ),
                },
            },
        };
    },
    watch: {
        filters: {
            handler() {
                this.initList(1);
                this.initListTotal();
            },
            deep: true,
        },
        referrer_ids: {
            async handler(agents) {
                var username = null;
                agents.forEach((agent, i) => {
                    if (agent != null) {
                        username = agent.username;
                    }
                });
                this.filters.referrer_username = username;
            },
            deep: true,
        },
        '$store.state.key'() {
            this.initList(1);
            this.initListTotal();
        },
    },
    // computed: {
    //     totals() {
    //         var p = 0,
    //             a = 0;
    //         this.data.list.forEach((r) => {
    //             p = p + r.player_claim_commission_amount;
    //             a = a + r.agent_claim_commission_amount;
    //         });

    //         return {
    //             total_player_points: this.numberString(p),
    //             total_agent_commissions: this.numberString(a),
    //         };
    //     },
    // },
    methods: {
        ...mapActions('agent', {
            agentGetFilterList: 'getFilterList',
        }),
        ...mapActions('bet', {
            betGetList: 'getList',
            betView: 'view',
            betGetListTotal: 'getListTotal',
            betUpdate: 'update',
            betDistributeCommission: 'distributeCommission',
            betSetResult: 'setResult',
        }),
        readQuery() {
            this.filters.status = this.$route.query.status || null;
            if (this.$route.query.username)
                this.filters.username = this.$route.query.username;
            if (this.$route.query.from)
                this.filters.from = new Date(this.$route.query.from);
            if (this.$route.query.to)
                this.filters.to = new Date(this.$route.query.to);
            this.filters.game = this.$route.query.game || null;
        },
        async initList(p) {
            this.resetScroll();
            var pl = {
                user: 'player',
                limit: this.filters.limit,
                vendor: this.filters.vendor,
                provider: this.filters.provider,
                referrer: this.filters.referrer_username ? 'agent' : null,
                referrer_username:
                    this.filters.referrer == 'all'
                        ? null
                        : this.filters.referrer_username,
                agent_level: this.filters.agent_level,
                username: this.filters.username,
                status: this.filters.status,
                live: this.filters.live,
                game: this.filters.game,
                from: this.dateYmD(this.filters.from) + ' 00:00:00',
                to: this.dateYmD(this.filters.to) + ' 23:59:59',
                page_no: p ? p : 1,
                search: this.filters.keyword ? this.filters.keyword : '',
            };

            this.loading = true;
            const data = await this.betGetList(pl);
            this.data.list = data.data.data;
            this.data.links = data.data.links;
            this.data.current_page = data.data.current_page;
            this.data.per_page = data.data.per_page;
            this.data.last_page = data.data.last_page;
            this.data.total = data.data.total;

            this.loading = false;
        },
        async initListTotal() {
            var pl = {
                user: 'player',
                vendor: this.filters.vendor,
                provider: this.filters.provider,
                referrer: this.filters.referrer_username ? 'agent' : null,
                referrer_username:
                    this.filters.referrer == 'all'
                        ? null
                        : this.filters.referrer_username,
                agent_level: this.filters.agent_level,
                username: this.filters.username,
                status: this.filters.status,
                live: this.filters.live,
                game: this.filters.game,
                from: this.dateYmD(this.filters.from) + ' 00:00:00',
                to: this.dateYmD(this.filters.to) + ' 23:59:59',
                search: this.filters.keyword ? this.filters.keyword : '',
            };
            this.total_loading = true;
            const total_data = await this.betGetListTotal(pl);
            this.total.total_bet_amount = total_data.total_bet_amount;
            this.total.total_bet_win = total_data.total_bet_win;
            this.total.total_bets = total_data.total_bets;
            this.total.total_agent_commission =
                total_data.total_agent_commission;
            this.total.total_player_commission =
                total_data.total_player_commission;
            this.total.total_player_rebate = total_data.total_player_rebate;
            this.total.total_difference = total_data.total_difference;
            this.total_loading = false;
        },
        handleFilter(value) {
            if (!value) return;
            var startIndex = value.option_index + 1;
            var loop = this.agents_options.length;
            if (startIndex < loop) {
                for (var i = startIndex; i < loop; i++) {
                    this.agents_options.splice(startIndex, 1);
                    this.referrer_ids[i] = null;
                }
            }
            this.initFilter(value._id);
        },
        handleClear(index) {
            var loop = this.agents_options.length;
            var startIndex = index + 1;
            for (var i = startIndex; i < loop; i++) {
                this.agents_options.splice(startIndex, 1);
                this.referrer_ids[i] = null;
            }
            if (index == 0) this.referrer_ids[0] = null;
        },
        async initFilter(referrer_id) {
            const data = await this.agentGetFilterList({
                referrer: referrer_id || null,
            });
            var agents = [];
            data.forEach((agent) => {
                agent.option_index = this.agents_options.length;
                agents.push({
                    label: agent.username,
                    value: agent,
                });
            });
            if (agents.length) {
                this.agents_options.push(agents);
            }
        },
        resetFilter() {
            this.filters.from = new Date(this.addDaysToDate(-2));
            this.filters.to = new Date();
            this.filters.referrer_username = null;
            this.filters.agent_level = null;
            this.filters.username = null;
            this.filters.keyword = null;
            this.filters.status = null;
        },
        showHoverDiv(event, id, index) {
            const hoverDiv = document.getElementById(`hover-div-${id}`);
            if (!hoverDiv) return;
            hoverDiv.style.display = 'block';
            this.hoverDivLeft = this.$refs.tableScroller.clientWidth / 2;
            this.hoverDivTop = event.pageY / 2;
        },
        hideHoverDiv(id) {
            const hoverDiv = document.getElementById(`hover-div-${id}`);
            if (!hoverDiv) return;
            hoverDiv.style.display = 'none';
        },
        async showViewModal(id) {
            if (this.view_loading) return;
            this.view_modal = true;
            this.view_loading = true;
            const res = await this.betView(id);
            if (res) {
                this.bet_detail._id = res._id;
                this.bet_detail.created_at = res.created_at;
                this.bet_detail.username = res.user.username;
                this.bet_detail.game = res.game;
                this.bet_detail.status = res.status;
                this.bet_detail.amount = res.amount;
                this.bet_detail.win_amount = res.win_amount;
                this.bet_detail.agent_commissions = res.agent_commissions;
                this.bet_detail.player_commissions = res.player_commissions;
                this.bet_detail.player_rebate = res.player_rebate;
                this.bet_detail.card_info = res.card_info;
                this.bet_detail.kplay_bet_detail = res.kplay_bet_detail;
                if (res.kplay_bet_detail) this.iframe_loading = true;
                this.bet_detail.reference_credit = res.reference_credit;

                if(res.reference_credit && res.provider == 'mamahot') {
                     this.bet_detail.card_info  = {
                        player: { cards: [] },
                        banker: { cards: [] },
                        outcome: res.reference_credit.result,
                    }
                    if(res.reference_credit.playerHand) {
                        res.reference_credit.playerHand.forEach(card => {
                            card = card.replace(/\b(\w{2})\b/g, (match) => {
                                this.bet_detail.card_info.player.cards.push(match[1] + match[0])
                            })
                        });
                    }
                    if(res.reference_credit.bankerHand) {
                        res.reference_credit.bankerHand.forEach(card => {
                            card = card.replace(/\b(\w{2})\b/g, (match) => {
                                this.bet_detail.card_info.banker.cards.push(match[1] + match[0])
                            })
                        });
                    }
                }
            }
            this.view_loading = false;
        },
        reset() {
            this.bet_detail._id = '';
            this.bet_detail.created_at = '';
            this.bet_detail.username = '';
            this.bet_detail.game = '';
            this.bet_detail.status = '';
            this.bet_detail.amount = '';
            this.bet_detail.win_amount = '';
            this.bet_detail.agent_commissions = [];
            this.bet_detail.player_commissions = [];
            this.bet_detail.player_rebate = [];
            this.bet_detail.card_info = null;
            this.bet_detail.kplay_bet_detail = null;

            this.pl_result._id = null;
            this.pl_result.game_title = null;
            this.pl_result.bet_amount = 0;
            this.pl_result.username = null;
            this.pl_result.user_role = null;
            this.pl_result.user_level = null;
            this.pl_result.user_type = null;
            this.pl_result.result = null;
            this.pl_result.win_amount = 0;
            this.submitted = true;
            this.v$.pl_result.$reset();
        },
        setDay(mode) {
            if (mode == 'today') {
                this.filters.from = new Date();
                this.filters.to = new Date();
            } else if (mode == 'yesterday') {
                this.filters.from = this.addDaysToDate(-1);
                this.filters.to = this.addDaysToDate(-1);
            } else {
                this.filters.from = this.addDaysToDate(-14);
                this.filters.to = new Date();
            }
        },
        async distributeCommission(id) {
            if (!this.isAuthorized()) return;
            const confirmed = await Swal.fire({
                title: this.$t('Would you like to restore the bet?'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f1b44c',
                cancelButtonColor: '#74788d',
                cancelButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('confirm'),
            }).then((result) => {
                return result.value;
            });

            if (confirmed) {
                const res = await this.betDistributeCommission(id);
                Swal.fire({
                    icon: res.success ? 'success' : 'error',
                    title: this.$t(res.data.message),
                    confirmButtonText: this.$t('ok'),
                });
                if (res.success) {
                    this.initList(1);
                }
            }
        },
        showResultModal(r) {
            this.result_modal = true;

            this.pl_result._id = r._id;
            this.pl_result.game_title = r.title;
            this.pl_result.bet_amount = r.amount;
            this.pl_result.username = r.user.username;
            this.pl_result.user_role = r.user.role;
            this.pl_result.user_level = r.user.level;
            this.pl_result.user_type = r.user.type;
        },
        async updateResult(r) {
            if (!this.isAuthorized()) return;
            this.submitted = true;
            this.v$.pl_result.$touch();
            if (this.v$.pl_result.$invalid) {
                return;
            } else {
                const confirmed = await Swal.fire({
                    title: this.$t('set the result manually?'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#f1b44c',
                    cancelButtonColor: '#74788d',
                    cancelButtonText: this.$t('cancel'),
                    confirmButtonText: this.$t('confirm'),
                }).then((result) => {
                    return result.value;
                });

                if (confirmed) {
                    var fd = new FormData();
                    fd.append('result', this.pl_result.result);
                    fd.append('win_amount', this.pl_result.win_amount);
                    const res = await this.betSetResult({
                        id: this.pl_result._id,
                        form_data: fd,
                    });
                    Swal.fire({
                        icon: res.success ? 'success' : 'error',
                        title: this.$t(res.data.message),
                        confirmButtonText: this.$t('ok'),
                    });
                    if (res.success) {
                        this.initList(1);
                        this.result_modal = false;
                        this.reset();
                    }
                }
            }
        },
    },
    mounted() {
        this.initFilter();
        if (this.$route.query.window) {
            this.readQuery();
        } else {
            if (this.$route.query.status) {
                this.filters.status = this.$route.query.status;
            }
            this.initList(1);
            this.initListTotal();
        }

        this.$watch(
            () => this.$route.params.range,
            (n, o) => {
                if (this.$route.name == 'player-bets')
                    this.filters.from = new Date(this.addDaysToDate(-2));
            }
        );
        this.$watch(
            () => this.$route.params.game,
            (n, o) => {
                if (this.$route.name == 'player-bets') this.filters.game = n;
            }
        );

        this.$watch(
            () => this.$route.params.provider,
            (n, o) => {
                if (this.$route.name == 'player-bets')
                    this.filters.provider = n;

                if (this.$route.params.provider == 'hilton') {
                    this.filters.vendor = 'hilton';
                } else {
                    this.filters.vendor = 'all';
                }
            }
        );

        if (Boolean(window.PusherChannel)) {
            if (this.filters.live) {
                window.PusherChannel.bind('honorlink', () => {
                    this.initList(1);
                });
            }
        }
    },
    beforeUnmount() {
        if (Boolean(window.PusherChannel)) {
            window.PusherChannel.unbind('honorlink');
        }
    },
};
</script>
