<template>
    <Layout>
        <AgentManagementTabs v-if="!$route.query.window" />
        <!-- <div class="d-flex align-items-center gap-1">
            <div class="flex-fill"></div>
        </div> -->

        <div class="card data">
            <div
                class="card-header bg-light bg-soft filters"
                v-if="!$route.query.upline_id">
                <b-form inline>
                    <table
                        class="table table-bordered table-nowrap mb-0"
                        v-if="agents_options.length">
                        <tbody>
                            <tr v-if="$route.params.level == 'all'">
                                <td
                                    class="text-center hidden"
                                    style="width: 5%">
                                    {{ $t('agent') }}
                                </td>
                                <td class="bg-white">
                                    <div
                                        class="d-flex justify-content-start gap-1 flex-wrap">
                                        <multiselect
                                            style="flex: 0 1 150px"
                                            class="m-0 multiselect-sm"
                                            v-for="(
                                                agents, i
                                            ) in agents_options"
                                            :key="i"
                                            :placeholder="
                                                agents.length
                                                    ? $t(`select referrer`)
                                                    : $t(`no sub agents`)
                                            "
                                            :options="agents"
                                            v-model="referrer_ids[i]"
                                            @change="handleFilter"
                                            @clear="handleClear(i)"
                                            :can-deselect="false"
                                            :searchable="true"
                                            :clear-on-blur="true">
                                            <template
                                                v-slot:singlelabel="{ value }">
                                                <div
                                                    class="mlabel flex-fill ps-2">
                                                    <span :class="`lvl lvl-${value.value.agent.level}`">{{$t(`level ${value.value.agent.level}`)}}</span>
                                                    <!-- <i :class="`mdi mdi-numeric-${value.value.agent.level}-box fs-5  level-${value.value.agent.level}`"></i> -->
                                                    <span>{{
                                                        value.label
                                                    }}</span>
                                                </div>
                                            </template>
                                            <template
                                                v-slot:option="{ option }">
                                                <div>
                                                    <span :class="`lvl lvl-${option.value.agent.level}`">{{$t(`level ${option.value.agent.level}`)}}</span>
                                                    <!-- <i:class="`mdi mdi-numeric-${option.value.agent.level}-box fs-5  level-${option.value.agent.level}`"></i> -->
                                                    <span>{{
                                                        option.value.username
                                                    }}</span>
                                                </div>
                                                <!-- <div>{{option.value.label}}</div> -->
                                            </template>
                                        </multiselect>
                                        <button
                                            class="btn btn-secondary btn-sm"
                                            @click="handleClear(0)">
                                            {{ $t('clear') }}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table
                        class="table table-bordered table-nowrap mb-0 table-form">
                        <tbody>
                            <tr>
                                <td class="text-center" colspan="1">
                                    {{ $t('date') }}
                                </td>
                                <td colspan="3" class="bg-white">
                                    <div class="d-flex gap-1">
                                        <datepicker
                                            v-model="filters.from"
                                            :first-day-of-week="0"
                                            lang="en"
                                            confirm
                                            class="form-control bg-white"></datepicker>

                                        <label class="input-group-text"
                                            >~</label
                                        >
                                        <datepicker
                                            v-model="filters.to"
                                            :first-day-of-week="0"
                                            lang="en"
                                            confirm
                                            class="form-control bg-white"></datepicker>
                                        <button
                                            class="btn btn-outline-info flex-grow-1"
                                            @click="setDay('today')">
                                            {{ $t('today') }}
                                        </button>
                                        <button
                                            class="btn btn-outline-success flex-grow-1"
                                            @click="setDay('yesterday')">
                                            {{ $t('yesterday') }}
                                        </button>
                                    </div>
                                </td>
                                <!-- <td class="text-center">
                                            {{ $t('username') }}
                                        </td>
                                        <td class="bg-white">
                                            <input
                                                type="text"
                                                class="form-control"
                                                :placeholder="
                                                        $t(`enter exact username`)
                                                    "
                                                v-model="filters.username"
                                            />
                                        </td> -->
                                <td class="text-center hidden">
                                    {{ $t('keyword') }}
                                </td>
                                <td class="bg-white">
                                    <!-- <div class="input-group"> -->
                                    <!-- <select class="form-control">
                                                    <option
                                                        value=""
                                                        selected
                                                    >
                                                        {{ $t('all') }}
                                                    </option>
                                                </select> -->
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t(`enter keyword`)"
                                        v-model="filters.keyword" />
                                    <!-- <button
                                                    :class="`btn btn-success ${!loading||'disabled'}`"
                                                    @click="initList(1)"
                                                >
                                                    {{ $t('search') }}
                                                </button> -->

                                    <!-- </div> -->
                                </td>
                                <td class="text-center">
                                    <div class="button-items">
                                        <button
                                            :class="`btn btn-danger ${
                                                loading ? 'disabled' : ''
                                            }`"
                                            @click="resetFilter()">
                                            {{ $t('reset') }}
                                        </button>
                                        <!-- <b-dropdown
                                            id="dropdown-1"
                                            :text="$t('change')"
                                            :variant="
                                                user_ids.length < 1
                                                    ? 'light'
                                                    : 'warning'
                                            "
                                            :disabled="user_ids.length < 1">
                                            <b-dropdown-item
                                                @click="
                                                    showBatchModal(
                                                        'handler-bank'
                                                    )
                                                "
                                                >{{
                                                    $t(`update handler bank`)
                                                }}</b-dropdown-item
                                            >
                                            <b-dropdown-item
                                                @click="
                                                    showBatchModal('status')
                                                "
                                                >{{
                                                    $t(`deactivate`)
                                                }}</b-dropdown-item
                                            >
                                        </b-dropdown> -->
                                       
                                    </div>
                                    <!-- <button
                                                :class="`btn btn-info`"
                                                @click="batch_update_modal = true"
                                                :disabled="user_ids.length<1"
                                            >
                                                {{ $t(`update handler bank`) }}
                                            </button> -->
                                    <b-modal
                                        v-model="batch_update_modal"
                                        centered
                                        hide-footer
                                        :title="`${$t(`batch update`)} - ${$t(
                                            batch_update_column.replace(
                                                '-',
                                                ' '
                                            )
                                        )}`"
                                        body-class="pt-0">
                                        <b-form>
                                            <div
                                                class="row mb-3 mt-0"
                                                v-if="
                                                    batch_update_column ==
                                                    'handler-bank'
                                                ">
                                                <div
                                                    class="col bg-soft pt-2"
                                                    :class="`${
                                                        bank.physical.default
                                                            ? 'bg-primary'
                                                            : 'bg-light'
                                                    }`">
                                                    <div
                                                        class="d-flex justify-content-between">
                                                        <b-form-radio
                                                            name="default-handler-bank"
                                                            v-model="
                                                                bank.physical
                                                                    .default
                                                            ">
                                                            <h4
                                                                class="card-title">
                                                                {{
                                                                    $t(
                                                                        'physical'
                                                                    )
                                                                }}
                                                                <small
                                                                    v-if="
                                                                        bank
                                                                            .physical
                                                                            .default
                                                                    "
                                                                    class="text-muted"
                                                                    >({{
                                                                        $t(
                                                                            'default'
                                                                        )
                                                                    }})</small
                                                                >
                                                            </h4>
                                                        </b-form-radio>
                                                        <b-form-checkbox
                                                            switch
                                                            v-model="
                                                                bank.physical
                                                                    .enabled
                                                            ">
                                                            {{
                                                                bank.physical
                                                                    .enabled
                                                                    ? $t(
                                                                          'enabled'
                                                                      )
                                                                    : $t(
                                                                          'disabled'
                                                                      )
                                                            }}
                                                        </b-form-checkbox>
                                                    </div>
                                                    <b-form-group
                                                        class="mb-3"
                                                        :label="
                                                            $t('bank name')
                                                        ">
                                                        <input
                                                            v-model="
                                                                bank[
                                                                    'physical'
                                                                ]['bank_name']
                                                            "
                                                            type="text"
                                                            class="form-control" />
                                                    </b-form-group>
                                                    <b-form-group
                                                        class="mb-3"
                                                        :label="
                                                            $t('account holder')
                                                        ">
                                                        <input
                                                            v-model="
                                                                bank[
                                                                    'physical'
                                                                ][
                                                                    'account_holder'
                                                                ]
                                                            "
                                                            type="text"
                                                            class="form-control" />
                                                    </b-form-group>
                                                    <b-form-group
                                                        class="mb-3"
                                                        :label="
                                                            $t('account number')
                                                        ">
                                                        <input
                                                            v-model="
                                                                bank[
                                                                    'physical'
                                                                ][
                                                                    'account_number'
                                                                ]
                                                            "
                                                            type="text"
                                                            class="form-control" />
                                                    </b-form-group>
                                                </div>
                                                <div
                                                    class="col bg-soft pt-2"
                                                    :class="`${
                                                        bank.virtual.default
                                                            ? 'bg-primary'
                                                            : 'bg-light'
                                                    }`">
                                                    <div
                                                        class="d-flex justify-content-between">
                                                        <b-form-radio
                                                            name="default-handler-bank"
                                                            v-model="
                                                                bank.virtual
                                                                    .default
                                                            ">
                                                            <h4
                                                                class="card-title">
                                                                {{
                                                                    $t(
                                                                        'virtual'
                                                                    )
                                                                }}
                                                                <small
                                                                    v-if="
                                                                        bank
                                                                            .virtual
                                                                            .default
                                                                    "
                                                                    class="text-muted"
                                                                    >({{
                                                                        $t(
                                                                            'default'
                                                                        )
                                                                    }})</small
                                                                >
                                                            </h4>
                                                        </b-form-radio>
                                                        <b-form-checkbox
                                                            switch
                                                            v-model="
                                                                bank.virtual
                                                                    .enabled
                                                            ">
                                                            {{
                                                                bank.virtual
                                                                    .enabled
                                                                    ? $t(
                                                                          'enabled'
                                                                      )
                                                                    : $t(
                                                                          'disabled'
                                                                      )
                                                            }}
                                                        </b-form-checkbox>
                                                    </div>
                                                    <b-form-group
                                                        class="mb-3"
                                                        :label="
                                                            $t('bank name')
                                                        ">
                                                        <input
                                                            v-model="
                                                                bank['virtual'][
                                                                    'bank_name'
                                                                ]
                                                            "
                                                            type="text"
                                                            class="form-control" />
                                                    </b-form-group>
                                                    <b-form-group
                                                        class="mb-3"
                                                        :label="
                                                            $t('account holder')
                                                        ">
                                                        <input
                                                            v-model="
                                                                bank['virtual'][
                                                                    'account_holder'
                                                                ]
                                                            "
                                                            type="text"
                                                            class="form-control" />
                                                    </b-form-group>
                                                    <b-form-group
                                                        class="mb-3"
                                                        :label="
                                                            $t('account number')
                                                        ">
                                                        <input
                                                            v-model="
                                                                bank['virtual'][
                                                                    'account_number'
                                                                ]
                                                            "
                                                            type="text"
                                                            class="form-control" />
                                                    </b-form-group>
                                                </div>
                                            </div>
                                            <div
                                                class="table-responsive mb-3 border"
                                                style="
                                                    max-height: 300px;
                                                    min-height: 300px;
                                                ">
                                                <table
                                                    class="table table-bordered table-hover table-striped table-sm">
                                                    <thead>
                                                        <tr
                                                            class="bg-white"
                                                            style="
                                                                position: sticky;
                                                                top: -1px;
                                                                z-index: 2;
                                                            ">
                                                            <th colspan="2">
                                                                {{
                                                                    $t(
                                                                        'selected users'
                                                                    )
                                                                }}
                                                                <span
                                                                    class="text-info ms-3"
                                                                    >{{
                                                                        selected_users.length
                                                                    }}</span
                                                                >
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            v-for="(
                                                                r, i
                                                            ) in selected_users"
                                                            :key="r._id">
                                                            <td
                                                                class="text-center">
                                                                {{ i + 1 }}
                                                            </td>
                                                            <td> 
                                                                <span :class="`lvl lvl-${r.agent.level}`">{{$t(`level ${r.agent.level}`)}}</span>
                                                                <!-- <i
                                                                    :class="`mdi mdi-numeric-${r.agent.level}-box level-${r.agent.level} me-1`">
                                                                </i> -->
                                                                {{ r.username }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="button-items float-end">
                                                <button
                                                    v-if="
                                                        batch_update_column ==
                                                        'status'
                                                    "
                                                    type="button"
                                                    class="btn btn-danger"
                                                    @click="
                                                        batchUpdate('inactive')
                                                    ">
                                                    {{ $t('deactivate') }}
                                                </button>
                                                <button
                                                    v-if="
                                                        batch_update_column ==
                                                        'handler-bank'
                                                    "
                                                    type="button"
                                                    class="btn btn-warning"
                                                    @click="batchUpdate()">
                                                    {{ $t('submit') }}
                                                </button>
                                            </div>
                                        </b-form>
                                    </b-modal>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                  
                </b-form>
            </div>
            <div class="card-body">
                <div ref="tableScroller" class="table-responsive">
                    <LoadingTable
                        :cols="8"
                        :rows="50"
                        :td_small_class="`d-none`"
                        class="table-bordered table-striped"
                        v-if="loading" />
                    <table
                        v-else
                        class="table table-bordered table-hover table-striped table-nowrap mb-0 table-list">
                        <thead class="hidden">
                            <tr>
                                <!-- <th scope="col" class="fz">
                                    <div class="form-check-dark">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            @input="addAllPlayerId" />
                                    </div>
                                </th> -->
                                <th scope="col" class="fz">
                                    <a
                                        href="javascript:void(0);"
                                        class="anchor-hover"
                                        @click="sort('username')">
                                        <i
                                            class="bx"
                                            :class="{
                                                'bx-caret-down bx-flashing':
                                                    filters.order == 'desc' &&
                                                    filters.sort == 'username',
                                                'bx-caret-up bx-flashing':
                                                    filters.order == 'asc' &&
                                                    filters.sort == 'username',
                                            }"></i>
                                        <span
                                            >{{ $t('level') }}/
                                            {{ $t('username') }}</span
                                        >
                                    </a>
                                </th>
                                <th scope="col">
                                    <a
                                        href="javascript:void(0);"
                                        class="anchor-hover"
                                        @click="sort('name')">
                                        <i
                                            class="bx"
                                            :class="{
                                                'bx-caret-down bx-flashing':
                                                    filters.order == 'desc' &&
                                                    filters.sort == 'name',
                                                'bx-caret-up bx-flashing':
                                                    filters.order == 'asc' &&
                                                    filters.sort == 'name',
                                            }"></i>
                                        <span>{{ $t('store name') }}</span>
                                    </a>
                                </th>
                              
                                
                                <th scope="col">
                                    <a
                                        href="javascript:void(0);"
                                        class="anchor-hover"
                                        @click="sort('created_at')">
                                        <i
                                            class="bx"
                                            :class="{
                                                'bx-caret-down bx-flashing':
                                                    filters.order == 'desc' &&
                                                    filters.sort ==
                                                        'created_at',
                                                'bx-caret-up bx-flashing':
                                                    filters.order == 'asc' &&
                                                    filters.sort ==
                                                        'created_at',
                                            }"></i>
                                        <span>{{
                                            $t('business start date')
                                        }}</span>
                                    </a>
                                </th>
                                
                                <th scope="col">
                                    {{ $t('status') }}
                                </th>
                              
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="r in data.list"
                                :key="r._id"
                                :class="{
                                    selected: user_ids.includes(r._id),
                                }">
                                <!-- <td class="text-center fz"> {{ i + 1 + (data.current_page - 1) * data.per_page }}</td> -->
                                <!-- <td class="fz text-center">
                                    <div class="form-check-dark">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            :value="r._id"
                                            :id="`row-${r._id}`"
                                            @input="addPlayerId" />
                                    </div>
                                </td> -->
                                <td class="fz">
                                    <div class="td-username">
                                        <a
                                            href="javascript:void(0)"
                                            class="text-secondary"
                                            @click="
                                                goToTicketForm(
                                                    r.username,
                                                    'agent'
                                                )
                                            ">
                                            <i
                                                class="bx bx-envelope bx-tada-hover fs-4"></i
                                        ></a>
                                        <div
                                            class="note-icon"
                                            v-if="r.agent.note">
                                            <i class="bx bxs-note fs-4"></i>
                                            <span class="tooltip">
                                                <p>{{ r.agent.note }}</p>
                                            </span>
                                        </div>
                                        <a
                                            href="javascript:void(0)"
                                            @click="
                                                goToUserForm(r._id, 'agent')
                                            "
                                            class="link-username"
                                            ><i
                                                :class="`mdi ${
                                                    r.type == 'online'
                                                        ? 'mdi-web text-info'
                                                        : r.type == 'offline'
                                                        ? 'mdi-storefront text-success'
                                                        : ''
                                                } fs-5`"></i
                                            >
                                            <!-- <i :class="`mdi mdi-numeric-${r.agent.level}-box fs-5  level-${r.agent.level}`"></i> -->
                                            <span :class="`lvl lvl-${r.agent.level}`">{{$t(`level ${r.agent.level}`)}}</span>
                                            {{ r.username }}
                                            <i
                                                class="bx bxs-circle bx-flashing text-warning font-size-11"
                                                v-if="r.logged_in"></i
                                        ></a>
                                    </div>
                                </td>
                                <td class="text-center">
                                    {{ r.name }}
                                </td>
                               
                                <td class="text-center">
                                    {{ dateYmD(r.created_at, true) }}
                                </td>

                             
                              
                                <td class="text-center">
                                    <div class="button-items">
                                        <!-- <button
                                            class="btn btn-sm btn-outline-warning"
                                            @click="forceLogout(r)"
                                            v-if="r.logged_in">
                                            {{ $t('force logout') }}
                                        </button>

                                        <button
                                            class="btn btn-outline-danger btn-sm"
                                            @click="remove(r)">
                                            {{ $t('delete') }}
                                        </button> -->
                                        <!-- <button
                                            type="button"
                                            class="btn btn-sm btn-outline-danger"
                                            v-if="r.status == 'active'"
                                            @click="blockUser(r)">
                                            {{ $t('active') }}
                                        </button> -->
                                        <!-- v-else-if="r.status == 'inactive'" -->
                                        <button
                                            type="button"
                                            class="btn btn-sm btn-outline-success"
                                            @click="blockUser(r)">
                                            {{ $t('approve') }}
                                        </button>
                                    </div>
                                </td>
                              
                            </tr>
                        </tbody>
                    </table>
                    <div
                        class="no-data-placeholder"
                        v-if="!data.list.length && !loading">
                        {{ $t('no data found') }}
                    </div>
                    <a
                        href="javascript:void(0);"
                        class="scroll-pad left"
                        @mousedown="startScrolling('left')"
                        @mouseup="endScrolling"
                        @mouseleave="endScrolling"
                        ><i class="bx bx-caret-left"></i
                    ></a>
                    <!-- @click="scroll('right')" -->
                    <a
                        href="javascript:void(0);"
                        class="scroll-pad right"
                        @mousedown="startScrolling('right')"
                        @mouseup="endScrolling"
                        @mouseleave="endScrolling"
                        ><i class="bx bx-caret-right"></i
                    ></a>
                </div>
                <Pagination :data="data" @emitPage="initList" />
            </div>
        </div>
        <b-modal
            v-model="asset_modal"
            @hidden="reset()"
            centered
            :title="`${$t(pl_asset.type)} ${$t('add')}/${$t('subtract')}`"
            title-class="font-18"
            hide-footer
            size="sm">
            <b-form>
                <table class="table table-bordered table-wrap table-form mb-2">
                    <tbody>
                        <tr>
                            <th class="bg-light bg-soft">
                                {{ $t('username') }}
                            </th>
                            <td>
                                <div class="mt-1">
                                    <i
                                        :class="`mdi mdi-numeric-${pl_asset.agent.level}-box fs-5  level-${pl_asset.agent.level}`"></i>
                                    {{ pl_asset.username }}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="bg-light bg-soft">
                                {{ $t(pl_asset.type) }}
                            </th>
                            <td>
                                <div class="mt-2">
                                    {{
                                        pl_asset.type == 'balance'
                                            ? numberString(
                                                  pl_asset.agent.assets.balance
                                              )
                                            : numberString(
                                                  pl_asset.agent.assets.points
                                              )
                                    }}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="bg-light bg-soft">
                                {{ $t('amount') }}
                            </th>
                            <td>
                                <VueNumberFormat
                                    class="form-control text-end rounded-end"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            v$.pl_asset.amount.$error,
                                    }"
                                    v-model:value="pl_asset.amount"
                                    :options="{
                                        precision: 0,
                                        prefix: '',
                                        suffix: '',
                                        thousand: ',',
                                        acceptNegative: false,
                                        isInteger: true,
                                    }"></VueNumberFormat>
                                <div
                                    v-for="(item, index) in v$.pl_asset.amount
                                        .$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <span v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <!-- <tr>
                            <th class="bg-light bg-soft">
                                {{ $t('note') }}
                            </th>
                            <td>
                                <textarea
                                    v-model="pl_asset.note"
                                    class="form-control"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            v$.pl_asset.note.$error,
                                    }"
                                    rows="2"
                                    style="resize: none"></textarea>
                                <div
                                    v-for="(item, index) in v$.pl_asset.note
                                        .$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <span v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </span>
                                </div>
                            </td>
                        </tr> -->
                    </tbody>
                </table>

                <div class="d-flex justify-content-evenly gap-1">
                    <button
                        class="btn btn-success flex-fill"
                        :class="{ disabled: loading }"
                        @click="updateAsset('add')">
                        {{ $t('add') }}
                    </button>
                    <button
                        class="btn btn-danger flex-fill"
                        :class="{ disabled: loading }"
                        @click="updateAsset('subtract')">
                        {{ $t('subtract') }}
                    </button>
                </div>
            </b-form>
        </b-modal>
    </Layout>
</template>

<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import LoadingTable from '@/components/loading-table';
import Pagination from '../../../components/pagination.vue';
import AgentManagementTabs from '@/components/agent-management-tabs';
import Switches from 'vue-switches';
import Swal from 'sweetalert2';
import Datepicker from 'vue3-datepicker';
import Multiselect from '@vueform/multiselect';
import { required, minValue, maxValue, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { mapActions } from 'vuex';
import random from '../../../mixins/random';
import datetime from '../../../mixins/datetime';
import number from '../../../mixins/number';
import ui from '../../../mixins/ui';
import convert from '../../../mixins/convert';

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        Layout,
        PageHeader,
        Pagination,
        Switches,
        LoadingTable,
        Datepicker,
        Multiselect,
        AgentManagementTabs,
    },
    mixins: [datetime, number, random, ui, convert],
    data() {
        return {
            show_account_details: false,
            title: `agents`,
            filters: {
                limit: 50,
                sort: null,
                order: null,
                keyword: null,
                username: null,
                status: null,
                from: new Date(this.firstDateOftheYear()),
                to: new Date(),
                referrer_id: null,
                upline_id: this.$route.query.upline_id || null,
            },
            referrer_ids: [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
            ],
            agents_options: [],
            data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            total: {
                total_deposit_player_amount: 0,
                total_withdraw_player_amount: 0,
                total_difference_amount: 0,
                total_player_bet_amount: 0,
                total_player_win_amount: 0,
                total_player_profit_amount: 0,
                total_agent_balance: 0,
                total_deposit_agent_amount: 0,
                total_withdraw_agent_amount: 0,
                total_commission_earned: 0,
            },
            submitted: false,
            asset_modal: false,
            pl_asset: {
                _id: null,
                username: null,
                name: null,
                nickname: null,
                agent: {
                    level: null,
                    assets: {
                        balance: 0,
                        points: 0,
                    },
                },
                type: 'balance',
                amount: 0,
                note: null,
            },
            loading: false,
            total_loading: false,
            hoverDivLeft: 0,
            hoverDivTop: 0,
            batch_update_modal: false,
            batch_update_column: '',
            user_ids: [],
            bank: {
                physical: {
                    enabled: false,
                    default: true,
                    bank_name: '',
                    account_holder: '',
                    account_number: '',
                },
                virtual: {
                    enabled: false,
                    default: false,
                    bank_name: '',
                    account_holder: '',
                    account_number: '',
                },
            },
            childWindow: null,
        };
    },
    validations() {
        return {
            pl_asset: {
                amount: {
                    required: helpers.withMessage('required', required),
                    minValueValue: helpers.withMessage('required', minValue(1)),
                    maxValueValue: helpers.withMessage(
                        'the maximum value is 9,999,999,999',
                        maxValue(9999999999)
                    ),
                },
                // note: {
                //     required: helpers.withMessage('required', required),
                // },
            },
        };
    },
    watch: {
        filters: {
            async handler() {
                // this.referrer_ids[this.referrer_ids.length - 1]
                this.initList(1);
                this.initListTotal();
            },
            deep: true,
        },
        referrer_ids: {
            async handler(agents) {
                var id = null;
                agents.forEach((agent, i) => {
                    if (agent != null) {
                        id = agent._id;
                    }
                });
                this.filters.referrer_id = id;
            },
            deep: true,
        },
        'bank.physical.default'(isDefault) {
            if (isDefault) {
                this.bank.virtual.default = false;
            }
        },
        'bank.virtual.default'(isDefault) {
            if (isDefault) {
                this.bank.physical.default = false;
            }
        },
        '$store.state.key'() {
            this.initList(1);
            this.initListTotal();
        },
    },
    computed: {
        selected_users() {
            var arr = [];
            this.data.list.forEach((user) => {
                if (this.user_ids.includes(user._id)) {
                    arr.push(user);
                }
            });
            return arr;
        },
    },
    methods: {
        ...mapActions('agent', {
            agentGetFilterList: 'getFilterList',
            agentGetList: 'getList',
            agentGetListTotal: 'getListTotal',
            agentDelete: 'delete',
            agentBatchUpdate: 'batchUpdate',
            agentUpdate: 'update',
            agentUpdateAssets: 'updateAssets',
            agentLogout: 'logout',
        }),
        handleFilter(value) {
            if (!value) return;
            var startIndex = value.option_index + 1;
            var loop = this.agents_options.length;
            if (startIndex < loop) {
                for (var i = startIndex; i < loop; i++) {
                    this.agents_options.splice(startIndex, 1);
                    this.referrer_ids[i] = null;
                }
            }
            this.initFilter(value._id);
        },
        sort(column) {
            if (this.filters.sort != column) {
                this.filters.order = 'desc';
            } else {
                if (this.filters.order == 'desc') this.filters.order = 'asc';
                else this.filters.order = 'desc';
            }
            this.filters.sort = column;
        },
        handleClear(index) {
            var loop = this.agents_options.length;
            var startIndex = index + 1;
            for (var i = startIndex; i < loop; i++) {
                this.agents_options.splice(startIndex, 1);
                this.referrer_ids[i] = null;
            }
            if (index == 0) this.referrer_ids[0] = null;
        },
        async initFilter(referrer_id) {
            const data = await this.agentGetFilterList({
                referrer: referrer_id || null,
            });
            var agents = [];
            data.forEach((agent) => {
                agent.option_index = this.agents_options.length;
                agents.push({
                    label: agent.username,
                    value: agent,
                });
            });
            // this.referrer_ids.push()
            if (agents.length) {
                this.agents_options.push(agents);
            }
        },
        async initList(p) {
            this.user_ids = [];
            var scrollLeft = this.$refs.tableScroller.scrollLeft;
            var pl = {
                detailed: 0,
                status: 'inactive',
                sort: this.filters.sort,
                order: this.filters.order,
                limit: this.filters.limit,
                // from: this.dateYmD(this.filters.from) + ' 00:00:00',
                // to: this.dateYmD(this.filters.to) + ' 23:59:59',
                level: [
                    'level-1',
                    'level-2',
                    'level-3',
                    'level-4',
                    'level-5',
                ].includes(this.$route.params.level)
                    ? this.$route.params.level.replace('level-', '')
                    : null,
                page_no: p ? p : 1,
                username: this.filters.username,
                search: this.filters.keyword || null,
                referrer_id:
                    this.$route.params.level == 'all'
                        ? this.filters.referrer_id
                        : null,
                upline_id: this.filters.upline_id,
            };
            this.loading = true;
            const data = await this.agentGetList(pl);
            this.data.list = data.data;
            this.data.links = data.links;
            this.data.current_page = data.current_page;
            this.data.per_page = data.per_page;
            this.data.last_page = data.last_page;
            this.data.total = data.total;
            this.loading = false;

            this.resetScroll(scrollLeft);
        },
        async initListTotal() {
            this.user_ids = [];
            var scrollLeft = this.$refs.tableScroller.scrollLeft;
            var pl = {
                from: this.dateYmD(this.filters.from) + ' 00:00:00',
                to: this.dateYmD(this.filters.to) + ' 23:59:59',
                level: [
                    'level-1',
                    'level-2',
                    'level-3',
                    'level-4',
                    'level-5',
                ].includes(this.$route.params.level)
                    ? this.$route.params.level.replace('level-', '')
                    : null,
                username: this.filters.username,
                search: this.filters.keyword || null,
                referrer_id:
                    this.$route.params.level == 'all'
                        ? this.filters.referrer_id
                        : null,
                upline_id: this.filters.upline_id,
            };

            this.total_loading = true;
            const data = await this.agentGetListTotal(pl);
            this.total.total_deposit_player_amount =
                data.total_deposit_player_amount;
            this.total.total_withdraw_player_amount =
                data.total_withdraw_player_amount;
            this.total.total_difference_amount = data.total_difference_amount;
            this.total.total_player_bet_amount = data.total_player_bet_amount;
            this.total.total_player_win_amount = data.total_player_win_amount;
            this.total.total_player_profit_amount =
                data.total_player_profit_amount;
            this.total.total_agent_balance = data.total_agent_balance;
            this.total.total_deposit_agent_amount =
                data.total_deposit_agent_amount;
            this.total.total_withdraw_agent_amount =
                data.total_withdraw_agent_amount;
            this.total.total_commission_earned =
                data.total_commission_earned;
            this.total_loading = false;

            this.resetScroll(scrollLeft);
        },
        resetFilter() {
            this.filters.from = new Date(this.firstDateOftheYear());
            this.filters.to = new Date();
            this.filters.keyword = null;
            this.filters.username = null;
            this.filters.status = null;
            // this.initList(1);
        },
        view(id) {
            window.open(
                `/agent/${id}`,
                '_blank',
                'width=' + 1720 + ',height=' + screen.height
            );
        },
        async blockUser(r) {
            if (!this.isAuthorized()) return;
            var swal_html = `
                        <div>${this.$t(
                            r.status == 'active' ? 'block user' : 'unblock user'
                        )}</div>
                        <table class="table table-sm table-bordered fs-6 mt-3">
                            <tbody>
                                <tr>
                                    <th>${this.$t('level')}<t/h>
                                    <td><i class="mdi mdi-numeric-${
                                        r.agent.level
                                    }-box fs-4  level-${
                r.agent.level
            }"></i> </td>
                                </tr>
                                <tr>
                                    <th>${this.$t('username')}</th>
                                    <td>${r.username}</td>
                                </tr>
                                <tr>
                                    <th>${this.$t('last access')}</th>
                                    <td>
                                        <div>${
                                            r.last_login_at
                                                ? this.dateYmD(
                                                      r.last_login_at,
                                                      true
                                                  )
                                                : ''
                                        }</div>
                                        <div class="text-primary">${
                                            r.last_login_ip || ''
                                        }</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    `;
            const confirmed = await Swal.fire({
                title: `${this.$t('is this what you want to change')}?`,
                html: swal_html,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f1b44c',
                cancelButtonColor: '#74788d',
                cancelButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('confirm'),
            }).then((result) => {
                return result.value;
            });

            if (confirmed) {
                var fd = new FormData();
                fd.append(
                    'status',
                    r.status == 'active' ? 'inactive' : 'active'
                );
                const res = await this.agentUpdate({
                    id: r._id,
                    form_data: this.formDataToUrlEncoded(fd),
                });

                if (res) {
                    Swal.fire({
                        icon: res.success ? 'success' : 'error',
                        title: res.success
                            ? this.$t(
                                  r.status == 'active'
                                      ? 'store has been blocked'
                                      : 'store has been unblocked'
                              )
                            : this.$t(
                                  blocked
                                      ? 'blocking failed'
                                      : 'unblocking failed'
                              ),
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    if (res.success) {
                        this.initList(1);
                    }
                }
            }
        },

        async forceLogout(r) {
            var swal_html = `
                        <table class="table table-sm table-bordered fs-6 mt-3">
                            <tbody>
                                <tr>
                                    <th>${this.$t('level')}<t/h>
                                    <td><i class="mdi mdi-numeric-${
                                        r.agent.level
                                    }-box fs-4  level-${
                r.agent.level
            }"></i></td>
                                    </tr>
                                <tr>
                                    <th>${this.$t('username')}</th>
                                    <td>${r.username}</td>
                                </tr>
                                <tr>
                                    <th>${this.$t('last access')}</th>
                                    <td>
                                        <div>${
                                            r.last_login_at
                                                ? this.dateYmD(
                                                      r.last_login_at,
                                                      true
                                                  )
                                                : ''
                                        }</div>
                                        <div class="text-primary">${
                                            r.last_login_ip || ''
                                        }</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    `;
            const confirmed = await Swal.fire({
                title: `${this.$t('are you sure')}?`,
                html: swal_html,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f1b44c',
                cancelButtonColor: '#74788d',
                cancelButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('confirm'),
            }).then((result) => {
                return result.value;
            });

            if (confirmed) {
                const res = await this.agentLogout({ id: r._id });

                if (res) {
                    Swal.fire({
                        icon: res.data.status,
                        title: this.$t(res.data.message),
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    if (res.success) {
                        this.initList(1);
                    }
                }
            }
        },
        async remove(r) {
            if (!this.isAuthorized()) return;
            var swal_html = `
                        <table class="table table-sm table-bordered fs-6 mt-3">
                            <tbody>
                                <tr>
                                    <th>${this.$t('level')}<t/h>
                                    <td><i class="mdi mdi-numeric-${
                                        r.agent.level
                                    }-box fs-4 level-${r.agent.level}"></i></td>
                                </tr>
                                <tr>
                                    <th>${this.$t('store name')}</th>
                                    <td>${r.name || ''}</td>
                                </tr>
                                <tr>
                                    <th>${this.$t('username')}</th>
                                    <td>${r.username}</td>
                                </tr>
                            </tbody>
                        </table>
                    `;
            const confirmed = await Swal.fire({
                title: this.$t('Are you sure you want to delete it?'),
                html: swal_html,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f1b44c',
                cancelButtonColor: '#74788d',
                cancelButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('confirm'),
            }).then((result) => {
                return result.value;
            });

            if (confirmed) {
                const res = await this.agentDelete({ id: r._id });
                if (res) {
                    Swal.fire({
                        icon: res.success ? 'success' : 'error',
                        title: res.success
                            ? this.$t('account has been deleted.')
                            : this.$t('deleting failed'),
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    if (res.success) {
                        this.initList(1);
                    }
                }
            }
        },
        showHoverDiv(event, id, index) {
            const hoverDiv = document.getElementById(`hover-div-${id}`);
            if (!hoverDiv) return;
            hoverDiv.style.display = 'block';
            this.hoverDivLeft = this.$refs.tableScroller.clientWidth / 2;
            this.hoverDivTop = event.pageY / 2;
        },
        hideHoverDiv(id) {
            const hoverDiv = document.getElementById(`hover-div-${id}`);
            if (!hoverDiv) return;
            hoverDiv.style.display = 'none';
        },
        showBatchModal(column) {
            this.batch_update_modal = true;
            this.batch_update_column = column;
        },
        async batchUpdate(status) {
            if (!this.isAuthorized()) return;
            var fd = new FormData();
            if (this.batch_update_column == 'handler-bank') {
                fd.append(
                    `bank[physical][enabled]`,
                    this.bank['physical']['enabled'] ? 1 : 0
                );
                fd.append(
                    `bank[physical][default]`,
                    this.bank['physical']['default'] ? 1 : 0
                );
                 if(this.bank['physical']['bank_name']) {
                    fd.append(
                        `bank[physical][bank_name]`,
                        this.bank['physical']['bank_name']
                    );
                }
                if(this.bank['physical']['account_holder']) {
                    fd.append(
                        `bank[physical][account_holder]`,
                        this.bank['physical']['account_holder']
                    );
                }
                if(this.bank['physical']['account_number']) {
                    fd.append(
                        `bank[physical][account_number]`,
                        this.bank['physical']['account_number']
                    );
                }
                fd.append(
                    `bank[virtual][enabled]`,
                    this.bank['virtual']['enabled'] ? 1 : 0
                );
                fd.append(
                    `bank[virtual][default]`,
                    this.bank['virtual']['default'] ? 1 : 0
                );
                if(this.bank['virtual']['bank_name']) {
                    fd.append(
                        `bank[virtual][bank_name]`,
                        this.bank['virtual']['bank_name']
                    );
                }
                if(this.bank['virtual']['account_holder']) {
                    fd.append(
                        `bank[virtual][account_holder]`,
                        this.bank['virtual']['account_holder']
                    );
                }
                if(this.bank['virtual']['account_number']) {
                    fd.append(
                        `bank[virtual][account_number]`,
                        this.bank['virtual']['account_number']
                    );
                }
                
            } else if (this.batch_update_column == 'status') {
                fd.append(`status`, status);
            }
            fd.append(`user_ids`, `${this.user_ids.toString()};`);
            const res = await this.agentBatchUpdate({
                column: this.batch_update_column,
                form_data: fd,
            });
            if (res) {
                Swal.fire({
                    icon: res.success ? 'success' : 'error',
                    title: res.success
                        ? this.$t('the information has been modified')
                        : this.$t('updating failed'),
                    showConfirmButton: false,
                    timer: 2000,
                });
                if (res.success) {
                    this.initList(1);
                    this.batch_update_modal = false;
                    this.bank['physical']['bank_name'] = '';
                    this.bank['physical']['account_holder'] = '';
                    this.bank['physical']['account_number'] = '';
                    this.bank['virtual']['bank_name'] = '';
                    this.bank['virtual']['account_holder'] = '';
                    this.bank['virtual']['account_number'] = '';
                    this.addAllPlayerId();
                }
            }
        },
        addPlayerId(e) {
            var id = e.target.value;
            if (e.target.checked) {
                this.user_ids.push(id);
            } else {
                var index = this.user_ids.indexOf(id);
                this.user_ids.splice(index, 1);
            }
            this.bank.physical.bank_name =
                this.selected_users.length == 1
                    ? this.selected_users[0].handler_bank.physical.bank_name
                    : null;
            this.bank.physical.account_holder =
                this.selected_users.length == 1
                    ? this.selected_users[0].handler_bank.physical
                          .account_holder
                    : null;
            this.bank.physical.account_number =
                this.selected_users.length == 1
                    ? this.selected_users[0].handler_bank.physical
                          .account_number
                    : null;

            this.bank.virtual.bank_name =
                this.selected_users.length == 1
                    ? this.selected_users[0].handler_bank.virtual.bank_name
                    : null;
            this.bank.virtual.account_holder =
                this.selected_users.length == 1
                    ? this.selected_users[0].handler_bank.virtual.account_holder
                    : null;
            this.bank.virtual.account_number =
                this.selected_users.length == 1
                    ? this.selected_users[0].handler_bank.virtual.account_number
                    : null;
        },
        addAllPlayerId(e) {
            if (e) {
                this.data.list.forEach((user) => {
                    var user = document.getElementById(`row-${user._id}`);
                    if (e.target.checked != user.checked) {
                        user.click();
                    }
                });
            } else {
                this.data.list.forEach((user) => {
                    var user = document.getElementById(`row-${user._id}`);
                    if (user.checked) {
                        user.click();
                    }
                });
            }
        },
        goToPlayerList(id) {
            window.open(
                `/player/management/all?window=true&referrer_id=${id}`,
                'player-list',
                'width=' + 1300 + ',height=' + (screen.height - 200)
            );
        },
        goToSubAgentList(id) {
            window.open(
                `/agents/all?window=true&upline_id=${id}`,
                `agent-list-${id}`,
                'width=' + 1300 + ',height=' + (screen.height - 200)
            );
        },
        showAssetModal(r, type) {
            this.pl_asset.type = type;
            this.pl_asset._id = r._id;
            this.pl_asset.username = r.username;
            this.pl_asset.agent.level = r.agent.level;
            this.pl_asset.agent.assets.balance = r.agent.assets.balance;
            this.pl_asset.agent.assets.points = r.agent.assets.points;
            this.asset_modal = true;
        },
        async updateAsset(action) {
            if (!this.isAuthorized()) return;
            this.submitted = true;
            this.v$.pl_asset.$touch();
            if (this.v$.pl_asset.$invalid) {
                return;
            } else {
                const confirmed = await Swal.fire({
                    title: `${this.$t('are you sure you want to change it')}?`,
                    html: `<table class="table table-bordered table-sm ">
                        <tbody>
                            <tr>
                                <th class="text-lowercase">${this.$t(
                                    'amount of change'
                                )}</th>
                                <td>${this.$t(action)} ${this.numberString(
                        this.pl_asset.amount
                    )} ${this.$t(this.pl_asset.type)}</td>
                            </tr>
                            <tr>
                                <th class="text-lowercase">${this.$t(
                                    'username'
                                )}</th>
                                <td>${this.pl_asset.username}</td>
                            </tr>
                        </tbody>
                    </table>`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#f1b44c',
                    cancelButtonColor: '#74788d',
                    cancelButtonText: this.$t('cancel'),
                    confirmButtonText: this.$t('confirm'),
                }).then(async (result) => {
                    return result.value;
                });

                if (confirmed) {
                    var fd = new FormData();
                    fd.append('amount', this.pl_asset.amount);
                    // fd.append('note', this.pl_asset.note);
                    this.loading = true;
                    const res = await this.agentUpdateAssets({
                        id: this.pl_asset._id,
                        asset: this.pl_asset.type,
                        action: action,
                        form_data: fd,
                    });
                    this.loading = false;
                    if (res) {
                        Swal.fire({
                            html: this.$t(res.data.message.toLowerCase()),
                            icon: res.success ? 'success' : 'error',
                            timer: res.success ? 2000 : null,
                        });
                        this.initList(1);
                        this.reset();
                        this.asset_modal = false;
                    }
                }
            }
        },
        reset() {
            this.submitted = false;
            this.pl_asset.type = '';
            this.pl_asset._id = null;
            this.pl_asset.username = null;
            this.pl_asset.agent.level = null;
            this.pl_asset.agent.assets.balance = 0;
            this.pl_asset.agent.assets.points = 0;
            this.pl_asset.amount = 0;
            this.pl_asset.note = null;
            this.v$.pl_asset.$reset();
        },
        setDay(mode) {
            if (mode == 'today') {
                this.filters.from = new Date();
                this.filters.to = new Date();
            } else {
                this.filters.from = this.addDaysToDate(-1);
                this.filters.to = this.addDaysToDate(-1);
            }
        },
       // start export excel
       convertToCsv(objArray) {
			var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;

			var str = "";

			for (var i = 0; i < array.length; i++) {
				var line = "";
				for (var index in array[i]) {
					if (line != "") line += ",";
					line += array[i][index];
				}

				str += line + "\r\n";
			}

			return str;
		},
		exportCsvFile(headers, item, fileTitle) {
			var vm = this;
			if (headers) {
				item.unshift(headers);
			}
			var jsonObject = JSON.stringify(item);
			var csv = vm.convertToCsv(jsonObject);

			var exportedFilenmae = fileTitle + ".csv" || "export.csv";

			var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
			if (navigator.msSaveBlob) {
				navigator.msSaveBlob(blob, exportedFilenmae);
			} else {
				var link = document.createElement("a");
				if (link.download !== undefined) {
					var url = URL.createObjectURL(blob);
					link.setAttribute("href", url);
					link.setAttribute("download", exportedFilenmae);
					link.style.visibility = "hidden";
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				}
			}
		},
		async exportData() {
			var current = new Date();
			var currentDate = `${current.getDate()}-${current.getMonth() + 1}-${current.getFullYear()}`;
			var headers = {
				username: `${this.$t('username')}`,
				name: `${this.$t('name')}`,
				type: `${this.$t('type')}`,
				balance: `${this.$t('balance')}`,
                points: `${this.$t('points')}`,
                bank_name: `${this.$t('bank name')}`,
                account_number: `${this.$t('account number')}`,
                account_holder: `${this.$t('account holder')}`,
                // level: `${this.$t('level')}`,
                // referrer: `${this.$t('referrer')}`,
				// phone_number: `${this.$t('phone number')}`,
				// commission_type: `${this.$t('commission type')}`,
				// deposit_amount: `${this.$t('recharge amount')}`,
				// withdraw_amount: `${this.$t('exchange amount')}`,
				// difference: `${this.$t('difference')}`,
				// difference: `${this.$t('difference')}`,
				// last_login_at: `${this.$t('last access')}`,
				// last_login_ip: `${this.$t('access ip')}`,
				// created_at: `${this.$t('subscription date')}`,
				// domain: `${this.$t('access domain')}`,
			};
			var itemsFormatted = [];
			const arr = await this.data.list;
			arr.forEach((item) => {
				itemsFormatted.push({
					username: item.username,
					name: item.name ? item.name : '-',
					type: item.type == 'online' ? `${this.$t('online')}` : item.type == 'offline' ? `${this.$t('offline')}` : '',
                    balance: item.agent.assets.balance ? item.agent.assets.balance : '0',
                    points: item.agent.assets.points ? item.agent.assets.points : '0',
                    bank_name: ['offline'].includes(this.player_mode) ? item.handler_bank.physical.bank_name : item.handler_bank.virtual.bank_name,
                    account_number: ['offline'].includes(this.player_mode) ? item.handler_bank.physical.account_number : item.handler_bank.virtual.account_number,
                    account_holder: ['offline'].includes(this.player_mode) ? item.handler_bank.physical.account_holder : item.handler_bank.virtual.account_holder,
                    // referrer: item.agent_referrer ? item.agent_referrer.username : item.player_referrer ? item.player_referrer.username : '-',
                    // phone_number: item.mobile_number ? item.mobile_number : '-',
                    // commission_type: item.player.commissions.casino.table.enabled ? item.player.commissions.casino.table.value+'%' : item.player.commissions.casino.slots.enabled ? item.player.commissions.casino.slots.value+'%' : item.player.commissions.casino.losing.enabled ? item.player.commissions.casino.losing.value+'%' : '-',
                    
                    // deposit_amount: item.player.total.deposit_amount ? item.player.total.deposit_amount : '0',
                    // withdraw_amount: item.player.total.withdraw_amount ? item.player.total.withdraw_amount : '0',
                    // difference: item.player.total.deposit_amount - item.player.total.withdraw_amount,
                    // last_login_at: item.last_login_at ? this.dateYmD(item.last_login_at, true): '-',
                    // last_login_ip: item.last_login_ip ? item.last_login_ip : '-',
                    // created_at: item.created_at ? this.dateYmD(item.created_at, true) : '-',
                    // domain: this.playerDomain,
				});
			});
			var fileTitle = `Member list ${currentDate} page ${this.data.current_page}`;
			this.exportCsvFile(headers, itemsFormatted, fileTitle);
		},
        // end export excel
    },
    mounted() {
        this.initFilter();
        this.initList(1);
        this.initListTotal();

        this.$watch(
            () => this.$route.params,
            (n, o) => {
                if (this.$route.query.window) {
                    this.readQuery();
                } else {
                    if (this.$route.name == 'agent-management') {
                        this.initList(1);
                        this.initListTotal();
                    }
                }
            }
        );

        window.addEventListener('message', (event) => {
            if (event.origin !== window.origin) {
                return;
            }
            if (event.data == 'reload-agents') {
                this.initList(this.data.current_page);
                this.initListTotal();
            }
        });
    },
};
</script>
