<template>
    <div class="px-1 mt-1">
        <div class="card mb-0">
            <div class="card-body">
                <div v-if="loading">
                    <LoadingTableForm
                        :tables="1"
                        :cols="1"
                        :rows="10"
                        class="table-bordered bg-white" />
                </div>
                <b-form v-else>
                    <table
                        class="table table-bordered table-wrap table-form mb-2">
                        <thead>
                            <tr class="fs-5 bg-light bg-soft">
                                <th class="text-start" colspan="6">
                                    {{ $t('customer care center') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th
                                    class="bg-light bg-soft hidden"
                                    style="width: 11%">
                                    {{ $t('customer') }}
                                </th>
                                <td style="width: 20%">
                                    <input
                                        readonly
                                        type="text"
                                        class="form-control"
                                        :value="pl.customer.username" />
                                </td>
                                <th
                                    class="bg-light bg-soft hidden"
                                    style="width: 11%">
                                    {{ $t('status') }}
                                </th>
                                <td style="width: 20%">
                                    <input
                                        readonly
                                        type="text"
                                        :class="{
                                            'text-success': [
                                                'open',
                                                '확인',
                                            ].includes(pl.status),
                                            'text-danger': [
                                                'closed',
                                                '차단',
                                            ].includes(pl.status),
                                        }"
                                        class="form-control fw-bold"
                                        :value="pl.status" />
                                </td>
                            </tr>
                            <!-- <tr>
                                <th
                                    class="bg-light bg-soft hidden"
                                    style="width: 11%">
                                    {{ $t('category') }}
                                </th>
                                <td style="width: 20%" colspan="3">
                                    <input
                                        readonly
                                        type="text"
                                        class="form-control"
                                        :value="pl.category" />
                                </td>
                            </tr> -->
                            <tr>
                                <th
                                    class="bg-light bg-soft hidden"
                                    style="width: 11%">
                                    {{ $t('subject') }}
                                </th>
                                <td style="width: 20%" colspan="3">
                                    <input
                                        readonly
                                        type="text"
                                        class="form-control"
                                        :value="pl.subject" />
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft hidden">
                                    {{ $t('content') }}
                                </th>
                                <td colspan="3">
                                    <textarea
                                        disabled
                                        type="text"
                                        :placeholder="`${$t(
                                            'enter content here'
                                        )}`"
                                        :value="pl.content"
                                        class="form-control"
                                        rows="10"
                                        style="resize: none"></textarea>
                                </td>
                            </tr>
                             <tr v-if="pl.user_id == pl.customer_id && pl.comments.length<1">
                                <td colspan="4">
                                    <div class="template-buttons">
                                        <b-button  
                                            v-for="t,i in templates" :key="t._id"
                                            variant="outline-secondary"
                                            size="sm"
                                            class="w-100"
                                            @click.prevent="useTemplate(t)">
                                        {{ $t('auto message')}} {{ i + 1}}</b-button>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="pl.user_id == pl.customer_id">
                                <th class="bg-light bg-soft hidden">
                                    <div>{{ $t('reply') }}</div>
                                    <div
                                        class="fw-normal d-flex align-items-center justify-content-center"
                                        v-if="pl.comments.length">
                                        [<i
                                            class="bx bxs-user font-size-13 text-primary"></i>
                                        {{ pl.comments[0].user.username }}]
                                    </div>
                                </th>
                                <td colspan="3">
                                    <div class="d-flex gap-2">
                                        <textarea
                                            :disabled="pl.comments.length"
                                            type="text"
                                            :placeholder="`${$t('reply here')}`"
                                            v-model="comment"
                                            class="form-control"
                                            rows="5"
                                            style="resize: none"></textarea>
                                        <button
                                            v-if="!pl.comments.length"
                                            :disabled="loading"
                                            @click="submitComment"
                                            type="button"
                                            class="btn btn-warning"
                                            style="flex: 0 1 100px">
                                            <i class="bx bx-send"></i>
                                            {{ $t('send') }}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                           
                            <tr
                                v-if="
                                    pl.user_id != pl.customer_id &&
                                    pl.comments.length > 0
                                ">
                                <th class="bg-light bg-soft hidden">
                                    <div>{{ $t('reply') }}</div>
                                    <div
                                        class="fw-normal d-flex align-items-center justify-content-center"
                                        v-if="pl.comments.length">
                                        [<i
                                            class="bx bxs-user font-size-13 text-primary"></i>
                                        {{ pl.comments[0].user.username }}]
                                    </div>
                                </th>
                                <td colspan="3">
                                    <div class="d-flex gap-2">
                                        <textarea
                                            disabled
                                            type="text"
                                            :placeholder="`${$t('reply here')}`"
                                            v-model="comment"
                                            class="form-control"
                                            rows="5"
                                            style="resize: none"></textarea>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="text-end"></div>
                </b-form>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingTableForm from '../../../components/loading-table-form.vue';
import appConfig from '@/app.config';
import { required, minLength, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { mapActions } from 'vuex';
import ui from '../../../mixins/ui';
import datetime from '../../../mixins/datetime';
import number from '../../../mixins/number';
import random from '../../../mixins/random';
import convert from '../../../mixins/convert';
import Swal from 'sweetalert2';
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    page: {
        title: 'Message',
        meta: [{ name: 'description', content: appConfig.description }],
    },
    components: { LoadingTableForm },
    mixins: [datetime, number, random, convert, ui],
    data() {
        return {
            title: 'Message',
            _id: this.$route.params.id,
            loading: false,
            pl: {
                type: null,
                customer_id: null,
                user_id: null,
                category: null,
                subject: null,
                content: null,
                status: 'closed',
                client_id: null,
                handler_id: null,
                role: null,
                handler_read: null,
                customer_read: null,
                updated_at: null,
                created_at: null,
                comments: [],
                handler: {
                    _id: null,
                    username: null,
                    name: null,
                },
                customer: {
                    _id: null,
                    username: null,
                    name: null,
                    player: null,
                    level: null,
                },
            },
            templates: [],
            comment: null,
        };
    },
    computed: {},
    methods: {
        ...mapActions('ticket', {
            ticketView: 'view',
            ticketUpdate: 'update',
            templateGetList: 'templateGetList',
            commentCreate: 'commentCreate',
            commentDelete: 'commentDelete',
        }),
        useTemplate(template) {
            this.comment = template.content
        },
        async initThread() {
            this.loading = true;
            this.templates = await this.templateGetList({});
            const data = await this.ticketView(this._id);
            if (data['user_id'] != null) this.pl['user_id'] = data['user_id'];
            if (data['customer_id'] != null)
                this.pl['customer_id'] = data['customer_id'];
            if (data['customer'] != null)
                this.pl['customer'] = data['customer'];
            if (data['status']) this.pl['status'] = this.$t(data['status']);
            if (data['category'])
                this.pl['category'] = this.$t(data['category']);
            if (data['subject']) this.pl['subject'] = data['subject'];
            this.pl['content'] = data['content'];
            this.pl['comments'] = data['comments'];
            if (data['comments'].length)
                this.comment = data['comments'][0]['content'];
            this.pl['created_at'] = data['created_at'];
            window.opener.postMessage('reload-tickets');
            this.loading = false;
        },
        async removeComment(r) {
            if (!this.isAuthorized()) return;
            const confirmed = await Swal.fire({
                title: `${this.$t('Are you sure you want to delete it?')}`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f1b44c',
                cancelButtonColor: '#74788d',
                cancelButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('confirm'),
            }).then((result) => {
                return result.value;
            });

            if (confirmed) {
                const res = await this.commentDelete({ id: r._id });

                if (res) {
                    Swal.fire({
                        icon: res.success ? 'success' : 'error',
                        title: res.success
                            ? this.$t('comment has been deleted')
                            : this.$t('deleting failed'),
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    if (res.success) {
                        this.initThread();
                    }
                }
            }
        },
        async submitComment() {
            if (!this.isAuthorized()) return;
            var fd = new FormData();
            fd.append('content', this.comment);
            const res = await this.commentCreate({
                form_data: fd,
                ticket_id: this._id,
            });
            if (res) {
                if (res.success) {
                    this.closeTicket();
                    this.initThread();
                    // this.reset();
                }
                Swal.fire({
                    title: res.success
                        ? this.$t('comment has been created')
                        : this.$t('creating comment failed'),
                    icon: res.success ? 'success' : 'error',
                    timer: 2000,
                    confirmButtonText: this.$t('ok'),
                });
            }
        },
        async closeTicket() {
            if (!this.isAuthorized()) return;
            // const confirmed = await Swal.fire({
            //     title: `${this.$t('are you sure')}?`,
            //     html: `${this.$t('close this ticket')}?`,
            //     icon: 'warning',
            //     showCancelButton: true,
            //     confirmButtonColor: '#f1b44c',
            //     cancelButtonColor: '#74788d',
            //     cancelButtonText: this.$t('cancel'),
            //     confirmButtonText: this.$t('confirm'),
            // }).then((result) => {
            //     return result.value;
            // });

            // if (confirmed) {
            var fd = new FormData();
            fd.append('status', 'closed');
            const res = await this.ticketUpdate({
                form_data: this.formDataToUrlEncoded(fd),
                id: this._id,
            });

            if (res) {
                // Swal.fire({
                //     icon: res.success ? 'success' : 'error',
                //     title: res.success
                //         ? this.$t('ticket has been closed')
                //         : this.$t('updating failed'),
                //     showConfirmButton: false,
                //     timer: 2000,
                // });
                if (res.success) {
                    this.initThread();
                }
            }
            // }
        },
        reset() {
            this.comment = null;
            document.querySelector('.ql-editor').innerHTML = null;
        },
    },
    mounted() {
        this.initThread();
        if (Boolean(window.PusherChannel)) {
            window.PusherChannel.bind('new-ticket-reply', (e) => {
                this.initThread();
            });
        }
        // window.resizeTo(window.outerWidth, 750)
    },
    beforeUnmount() {
        if (Boolean(window.PusherChannel)) {
            window.PusherChannel.unbind('new-ticket-reply');
        }
    },
};
</script>
