
export const menuItems = [{
    id: 1,
    label: "menu",
    isTitle: true
},
{
    id: 2,
    label: "dashboard",
    icon: "bxs-dashboard",
    link: "/dashboard",
},
{
    id: 2,
    label: "dashboard 2",
    icon: "bxs-report",
    link: "/summary",
},
{
    id: 8,
    label: "member management",
    icon: "mdi mdi-account-cowboy-hat",
    subItems: [{
        id: 11,
        label: "member list",
        parentId: 4,
        link: "/player/management/all",
    },
    {
        id: 12,
        label: "approving online membership",
        parentId: 4,
        link: "/player/management/pending",
    },
    {
        id: 12,
        label: "live users (player)",
        parentId: 4,
        link: "/live-users/player",
    },
    {
        id: 12,
        label: "live users (agent)",
        parentId: 4,
        link: "/live-users/agent",
    },

    ]
},
{
    id: 4,
    label: "agent management",
    icon: "mdi mdi-account-tie",
    subItems: [
    {
        id: 11,
        label: "approving agents",
        parentId: 4,
        link: "/agents-approval",
    },
    
    {
        id: 11,
        label: "agent list (all)",
        parentId: 4,
        link: "/agents/all",
    },
    {
        id: 11,
        label: "agent list (level 1)",
        parentId: 4,
        link: "/agents/level-1",
    },
    {
        id: 11,
        label: "agent list (level 2)",
        parentId: 4,
        link: "/agents/level-2",
    },
    {
        id: 11,
        label: "agent list (level 3)",
        parentId: 4,
        link: "/agents/level-3",
    },
    {
        id: 11,
        label: "agent list (level 4)",
        parentId: 4,
        link: "/agents/level-4",
    },

    {
        id: 11,
        label: "agent list (level 5)",
        parentId: 4,
        link: "/agents/level-5",
    },
    {
        id: 11,
        label: "IP block",
        parentId: 4,
        link: "/player/ip-block",
    },
    {
        id: 11,
        label: "login failure history",
        parentId: 4,
        link: "/log/activity/login",
    },
    {
        id: 11,
        label: "blocked ip&id history",
        parentId: 4,
        link: "/log/activity/block",
    },
    ]
},
{
    id: 11,
    label: "bet management",
    icon: "mdi mdi-cards",
    subItems: [
        {
            id: 12,
            label: "unregistered bet",
            parentId: 4,
            link: "/player/bets/pending",
        },
        // {
        //     id: 12,
        //     label: "casino bet (hilton)",
        //     parentId: 4,
        //     link: "/player/bets/hilton/table/all",
        // },
        // {
        //     id: 12,
        //     label: "casino bet (mamahot)",
        //     parentId: 4,
        //     link: "/player/bets/mamahot/table/all",
        // },
        {
            id: 12,
            label: "casino",
            parentId: 4,
            link: "/player/bets/honorlink/table/all",
        },
        {
            id: 12,
            label: "slot",
            parentId: 4,
            link: "/player/bets/honorlink/slot/all",
        },
        // {
        //     id: 12,
        //     label: "casino bet (kplay)",
        //     parentId: 4,
        //     link: "/player/bets/kplay/table/all",
        // },
        // {
        //     id: 12,
        //     label: "slot betting (kplay)",
        //     parentId: 4,
        //     link: "/player/bets/kplay/slot/all",
        // },
    ]
},
{
    id: 8,
    label: "deposit & withdrawal history",
    icon: "bx-credit-card-alt",
    subItems: [
        {
            id: 'agent-transaction-deposit',
            label: "store charging",
            parentId: 4,
            link: "/agent/transaction/deposit",
        },
        {
            id: 'agent-transaction-withdraw',
            label: "store exchange",
            parentId: 4,
            link: "/agent/transaction/withdraw",
        },
        {
            id: 'player-transaction-deposit-online',
            label: "member charging (online)",
            parentId: 4,
            link: "/player/transaction/deposit/online",
        },
        {
            id: 'player-transaction-withdraw-online',
            label: "member exchange (online)",
            parentId: 4,
            link: "/player/transaction/withdraw/online",
        },
        {
            id: 'player-transaction-deposit-offline',
            label: "member charging (offline)",
            parentId: 4,
            link: "/player/transaction/deposit/offline",
        },
        {
            id: 'player-transaction-withdraw-offline',
            label: "member exchange (offline)",
            parentId: 4,
            link: "/player/transaction/withdraw/offline",
        },
    ]
},
{
    id: 8,
    label: "money management",
    icon: "mdi mdi-bank-transfer",
    subItems: [
        {
            id: 'honorlink-money-logs',
            label: "money logs (honorlink)",
            parentId: 8,
            link: "/money-logs/honorlink",
        },
        {
            id: 'log-asset-detailed',
            label: "total money logs",
            parentId: 8,
            link: "/asset-log/detailed",
        },
        {
            id: 'log-asset-player-points',
            label: "point logs (player)",
            parentId: 8,
            link: "/log/asset/player/points/all",
        },
        {
            id: 'log-asset-player-balance',
            label: "money logs (player)",
            parentId: 8,
            link: "/log/asset/player/balance/all",
        },
        {
            id: 'log-asset-agent-balance',
            label: "money logs (agent)",
            parentId: 8,
            link: "/log/asset/agent/balance/all",
        },
        {
            id: 11,
            label: "commissions (1 week)",
            parentId: 4,
            link: "/asset-log/agent-commissions/week",
        },
        {
            id: 11,
            label: "commissions (all)",
            parentId: 4,
            link: "/asset-log/agent-commissions/all",
        },
    ]
},

{
    id: 11,
    icon: "bx-spreadsheet",
    label: "accounting worksheet",
    subItems: [
        {
            id: 11,
            label: "agent details",
            parentId: 4,
            link: "/agents-detailed",
        },
        {
            id: 11,
            icon: "bx-spreadsheet",
            label: "jukjang jeongsan",
            link: "/accounting-worksheet",

        },
        {
            id: 12,
            icon: "bx-calendar",
            label: "monthly report",
            parentId: 10,
            link: "/report/monthly",
        },
    ]
},
// {
//     id: 11,
//     icon: "bxs-spreadsheet",
//     label: "agent deposit worksheet",
//     link: "/agent-deposit-worksheet",
// },
{
    id: 8,
    label: "bulletin board",
    icon: "bxs-message",
    subItems: [
        {
            id: 11,
            label: "message to all",
            parentId: 4,
            link: "/tickets/public",
        },
        {
            id: 11,
            label: "membership inquiry",
            parentId: 4,
            link: "/tickets/player",
        },
        {
            id: 11,
            label: "inquiry of distributor",
            parentId: 4,
            link: "/tickets/agent",
        },
       
        {
            id: 8,
            label: "announcement",
            icon: "bxs-megaphone",
            link: "/announcement/management",
        },
        {
            id: 8,
            label: "popup",
            icon: "bxs-megaphone",
            link: "/popup/management",
        },
        {
            id: 8,
            label: "event management",
            icon: "bxs-calendar-event",
            link: "/event/management",
        },
    ]
},
{
    id: 11,
    icon: "mdi mdi-ticket",
    label: "coupon",
    parentId: 4,
    subItems: [
        {
            id: 11,
            icon: "mdi mdi-ticket",
            label: "coupon (player)",
            parentId: 4,
            link: "/coupon/player",
        },
        {
            id: 11,
            icon: "bxs-coupon",
            label: "coupon (agent)",
            parentId: 4,
            link: "/coupon/agent",
        },
    ],
},
{
    id: 8,
    label: "settings",
    icon: "bxs-wrench",
    parentId: 10,
    subItems: [
        {
            id: 8,
            label: "game maintenance settings",
            parentId: 10,
            link: "/bet/game-settings/honorlink",
        },
        {
            id: 8,
            label: "setup provider",
            parentId: 10,
            link: "/setting/provider",
        },
        {
            id: 8,
            label: "advance settings",
            parentId: 10,
            link: "/setting/advance",
        },
        {
            id: 8,
            label: "auto message",
            parentId: 4,
            link: "/template/management",
        },
    ]
},
];