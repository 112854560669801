<template>
    <Layout>
        <!-- <h4 class="mb-1 font-size-18">
                {{ $t(`asset logs`) }}
                <span
                    class="ms-1 badge bg-gradient"
                    :class="{
                        'bg-primary': $route.params.role == 'player',
                        'bg-danger': $route.params.role == 'agent',
                    }"
                    >{{ $t($route.params.role) }}</span
                >
                <span class="ms-1 badge bg-dark bg-gradient">{{
                    $t($route.params.column)
                }}</span>
            </h4> -->
        <MoneyManagementTabs />
        <div class="card data">
            <div class="card-header bg-light bg-soft filters">
                <b-form inline>
                    <table
                        class="table table-bordered table-nowrap mb-0 table-form">
                        <tbody>
                            <tr>
                                
                                <td class="text-center hidden">
                                    {{ $t('username') }}
                                </td>
                                <td class="bg-white">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t(`enter username`)"
                                        v-model="filters.username" />
                                </td>
                                <td class="text-center hidden">
                                    {{ $t('id') }}
                                </td>
                                <td class="bg-white">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t(`enter id`)"
                                        v-model="filters.game_id" />
                                </td>
                                 <!-- <td class="text-center hidden">
                                    {{ $t('type') }}
                                </td>
                                <td class="bg-white" style="width: 15%">
                                    <multiselect
                                        :placeholder="`${$t('select type')}`"
                                        v-model="filters.type"
                                        :options="type_options">
                                        <template
                                            v-slot:singlelabel="{ value }">
                                            <span class="flex-fill ps-2">{{
                                                $t(value.value)
                                            }}</span>
                                        </template>
                                        <template v-slot:option="{ option }">
                                            {{ $t(option.value) }}
                                        </template>
                                    </multiselect>
                                </td> -->

                                <td class="text-center hidden">
                                    {{ $t('date') }}
                                </td>
                                <td class="bg-white">
                                    <div class="d-flex gap-1">
                                        <datepicker
                                            v-model="filters.from"
                                            :first-day-of-week="0"
                                            lang="en"
                                            confirm
                                            class="form-control bg-white"></datepicker>

                                        <label class="input-group-text"
                                            >~</label
                                        >
                                        <datepicker
                                            v-model="filters.to"
                                            :first-day-of-week="0"
                                            lang="en"
                                            confirm
                                            class="form-control bg-white"></datepicker>
                                        <button
                                            class="btn btn-outline-info flex-grow-1"
                                            @click="setDay('today')">
                                            {{ $t('today') }}
                                        </button>
                                        <button
                                            class="btn btn-outline-success flex-grow-1"
                                            @click="setDay('yesterday')">
                                            {{ $t('yesterday') }}
                                        </button>
                                    </div>
                                </td>
                                 <!-- <td class="bg-white">
                                    <div class="form-check-success w-100">
                                        <b-form-checkbox
                                            v-model="filters.with_bets"
                                            name="with_bets">
                                            {{ $t('include betting history') }}
                                        </b-form-checkbox>
                                    </div>
                                </td> -->
                                <td class="text-center">
                                    <button
                                        :class="`btn btn-danger ${
                                            loading ? 'disabled' : ''
                                        }`"
                                        @click="resetFilter()">
                                        {{ $t('reset') }}
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </b-form>
            </div>
            <div class="card-body">
                <multiselect
                    style="width: 120px"
                    class="multiselect-sm me-0"
                    :placeholder="`${$t('limit')}`"
                    v-model="filters.limit"
                    :options="[50, 100, 500, 1000]"
                    :preselect-first="true"
                    :can-deselect="false"
                    :can-clear="false">
                    <template v-slot:singlelabel="{ value }">
                        <span class="mx-auto">{{
                            $t('view') + ' ' + value.value
                        }}</span>
                    </template>
                </multiselect>
                <div ref="tableScroller" class="table-responsive">
                    <LoadingTable
                        :cols="8"
                        :rows="50"
                        :td_small_class="`d-none`"
                        class="table-bordered table-striped"
                        v-if="loading" />
                    <table
                        v-else
                        class="table table-bordered table-hover table-striped table-nowrap mb-0 table-list">
                        <thead class="hidden">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{{ $t('id') }}</th>
                                <th scope="col">{{ $t('user') }}</th>
                                <th scope="col">{{ $t('type') }}</th>
                                <th scope="col">{{ $t('game type') }}</th>
                                <th scope="col">{{ $t('game name') }}</th>
                                <th scope="col">{{ $t('previous balance') }}</th>
                                <th scope="col">{{ $t('inccured amount') }}</th>
                                <th scope="col">{{ $t('current balance') }}</th>
                                <th scope="col">{{ $t('note') }}</th>
                                <th scope="col">{{ $t('created at') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(r, i) in data.list" :key="r._id">
                                <td class="text-center">
                                    {{
                                        i +
                                        1 +
                                        (data.current_page - 1) * data.per_page
                                    }}
                                </td>
                                <td class="text-center">
                                    {{ r.id }}
                                </td>
                                <td class="text-start">
                                      <div class="td-username" v-if="r.user">
                                        <a
                                            href="javascript:void(0)"
                                            @click="
                                                goToTicketForm(
                                                    r.user.username,
                                                    r.user.role
                                                )
                                            ">
                                            <i
                                                class="bx bx-envelope bx-tada-hover fs-4"></i
                                        ></a>
                                        <a
                                            :title="$t(`click to view`)"
                                            href="javascript:void(0)"
                                            @click="
                                                goToUserForm(
                                                    r.user._id,
                                                    r.user.role
                                                )
                                            "
                                            class="link-username"
                                            ><i
                                                :class="`mdi ${
                                                    r.user.type == 'online'
                                                        ? 'mdi-web text-info'
                                                        : r.user.type ==
                                                          'offline'
                                                        ? 'mdi-storefront text-success'
                                                        : ''
                                                } fs-5`"></i>
                                            <i
                                                :class="`mdi ${
                                                    r.user.role == 'agent'
                                                        ? 'mdi-account-tie text-danger'
                                                        : r.user.role ==
                                                          'player'
                                                        ? 'mdi-account-cowboy-hat text-primary'
                                                        : ''
                                                } fs-5`"></i>
                                            <i
                                                :class="`mdi mdi-numeric-${
                                                    r.user[r.user.role].level
                                                }-box level-${
                                                    r.user[r.user.role].level
                                                } fs-5`"></i>
                                            {{ r.user.username }}</a
                                        >
                                    </div>
                                </td>
                                <td class="text-center">
                                    {{ $t(r.type=='agent.add_balance'? 'debit-honorlink':r.type=='agent.subtract_balance_all'? 'credit-honorlink':r.type ) }}
                                </td>
                                <td class="text-center">
                                    {{r.details?.game?.type}}
                                </td>
                                <td class="text-center">
                                    {{ r.details?.game?.title }}
                                </td>
                                <td class="text-end">
                                    {{ numberString(r.before) }}
                                </td>
                                <td class="text-end">
                                    {{ numberString(r.amount) }}
                                </td>
                                <td class="text-end">
                                    {{ numberString(r.before + r.amount) }}
                                </td>
                                <td class="text-start">
                                    {{ $t(r.type=='agent.add_balance'? 'Deposit to Honorlink':r.type=='agent.subtract_balance_all'? 'Withdraw from Honorlink': '' ) }}
                                </td>
                                <td class="text-center">
                                    {{ dateYmD(r.created_at, true) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div
                        class="no-data-placeholder"
                        v-if="!data.list.length && !loading">
                        {{ $t('no data found') }}
                    </div>
                </div>
                <Pagination v-if="!loading" :data="data" @emitPage="initList" />
            </div>
        </div>
    </Layout>
</template>

<script>
import Datepicker from 'vue3-datepicker';
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import LoadingTable from '@/components/loading-table';
import Pagination from '../../../components/pagination.vue';
import Multiselect from '@vueform/multiselect';
import Switches from 'vue-switches';
import MoneyManagementTabs from '@/components/money-management-tabs';

import { mapActions, mapGetters } from 'vuex';
import random from '../../../mixins/random';
import datetime from '../../../mixins/datetime';
import number from '../../../mixins/number';
import ui from '../../../mixins/ui';
export default {
    components: {
        Layout,
        PageHeader,
        Pagination,
        Switches,
        LoadingTable,
        Multiselect,
        Datepicker,
        MoneyManagementTabs,
    },
    mixins: [datetime, number, random, ui],
    data() {
        return {
            test: null,
            title: `players`,
            filters: {
                limit: 50,
                from: new Date(this.firstDateOftheMonth()),
                to: new Date(),
                search: null,
                username: null,
                with_bets: false,
                type: null,
                game_id: null,
                // column: this.$route.params.column,
                // role: this.$route.params.role,
            },
            column_options: [
                { name: this.$t('points'), value: 'points' },
                { name: this.$t('balance'), value: 'balance' },
                { name: this.$t('commissions'), value: 'commissions' },
            ],
            type_options: [
                { name: this.$t('bet'), value: 'bet' },
                { name: this.$t('win'), value: 'win' },
            ],
            data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            loading: false,
        };
    },
    watch: {
        filters: {
            async handler() {
                this.initList(1);
            },
            deep: true,
        },
        '$store.state.key'() {
            this.initList(1);
        },
    },
    methods: {
        ...mapActions('log', {
            assetGetList: 'getList',
            getAssetLogDetailed: 'getAssetLogDetailed',
            getAssetLogHonorlink: 'getAssetLogHonorlink',
        }),
        async initList(p) {
            this.resetScroll();
            var pl = {
                limit: this.filters.limit,
                page_no: p ? p : 1,
                from: this.dateYmD(this.filters.from) + ' 00:00:00',
                to: this.dateYmD(this.filters.to) + ' 23:59:59',
                username: this.filters.username,
                game_id: this.filters.game_id,
                // search: this.filters.search,
                // type: this.filters.type,
                // with_bets: !this.filters.with_bets
            }; 
            this.loading = true;
            const data = await this.getAssetLogHonorlink(pl);
            this.data.list = data.data;
            this.data.links = data.links;
            this.data.current_page = data.current_page;
            this.data.per_page = data.per_page;
            this.data.last_page = data.last_page;
            this.data.total = data.total;
            this.loading = false;
        },
        resetFilter() {
            this.filters.column = null;
            this.filters.role = null;
            this.filters.username = null;
            this.filters.game_id = null;
            this.filters.search = null;
            this.filters.type = null;
            this.filters.with_bets = false;
        },
        setDay(mode) {
            if (mode == 'today') {
                this.filters.from = new Date();
                this.filters.to = new Date();
            } else {
                this.filters.from = this.addDaysToDate(-1);
                this.filters.to = this.addDaysToDate(-1);
            }
        },
    },
    mounted() {
        this.$watch(
            () => this.$route.params,
            (n, o) => {
                if (this.$route.query.window) {
                    this.readQuery();
                } else {
                    if (this.$route.name == 'asset-logs') this.initList(1);
                }
            }
        );
        this.initList(1);
    },
};
</script>
