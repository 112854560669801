<template>
    <!-- <Layout> -->
    <div class="px-1 mt-1" ref="wrapper">
        <div class="form-header">
            <h4>
                uploading
            </h4>
            <textarea name="" id="" cols="100" rows="50" v-model="importData"></textarea>
            <button @click="uploadJson()">upload json</button>
        </div>
    </div>
    <!-- </Layout> -->
</template>

<script>
import Datepicker from 'vue3-datepicker';
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import Pagination from '../../../components/pagination.vue';
import LoadingTable from '@/components/loading-table';
import LoadingTableForm from '@/components/loading-table-form';
import Swal from 'sweetalert2';
import ui from '@/mixins/ui';

import {
    required,
    requiredIf,
    minLength,
    maxLength,
    minValue,
    maxValue,
    sameAs,
    email,
    helpers,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { mapActions } from 'vuex';
import datetime from '../../../mixins/datetime';
import number from '../../../mixins/number';
import convert from '../../../mixins/convert';
import string from '../../../mixins/string';

import Multiselect from '@vueform/multiselect';
// custom utilities
const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
});
const validPassword = (value) => {
    const regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]+$/;
    return !params.id ? helpers.req(value) && regex.test(value) : true;
};
const alphaNumericOnly = (value) => {
    // const regex = /^(?:[a-zA-Z0-9][a-zA-Z0-9_]*[a-zA-Z0-9]|)$/;
    const regex = /^[A-Za-z0-9]+$/;
    return !params.id ? helpers.req(value) && regex.test(value) : true;
};

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        Layout,
        PageHeader,
        PageHeader,
        Pagination,
        Multiselect,
        LoadingTable,
        LoadingTableForm,
        Datepicker,
    },
    mixins: [datetime, number, convert, string, ui],
    data() {
        return{
            importData:'',
        }
    },
    methods: {
        ...mapActions('player', {
            playerUpload: 'upload',
        }),
        async uploadJson(){
            const obj = JSON.parse(this.importData);
            // console.log(obj);
            var res = await this.playerUpload(obj);
            console.log(res);
        }
        
    },
    mounted() {
        // this.initFilter();
        // this.getDetails();
    },
};
</script>
