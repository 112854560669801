<template>
    <Layout>
        <BulletinBoardTabs  />
        <div class="card data">
            <div class="card-header bg-light bg-soft filters">
                <b-form inline>
                    <table
                        class="table table-bordered table-nowrap mb-0 table-form">
                        <tbody>
                            <tr>
                                <td class="bg-white">
                                    <div class="form-check-success w-100">
                                        <b-form-checkbox
                                            v-model="filters.unread"
                                            name="unread">
                                            {{ $t('view unchecked messages') }}
                                        </b-form-checkbox>
                                    </div>
                                </td>
                                <td class="text-center hidden">
                                    {{ $t('date') }}
                                </td>
                                <td class="bg-white">
                                    <div class="d-flex gap-1">
                                        <datepicker
                                            v-model="filters.from"
                                            :first-day-of-week="0"
                                            lang="en"
                                            confirm
                                            class="form-control bg-white"></datepicker>

                                        <label class="input-group-text"
                                            >~</label
                                        >
                                        <datepicker
                                            v-model="filters.to"
                                            :first-day-of-week="0"
                                            lang="en"
                                            confirm
                                            class="form-control bg-white"></datepicker>
                                    </div>
                                </td>
                                <td class="text-center hidden">
                                    {{ $t('status') }}
                                </td>
                                <td class="bg-white" style="width: 25%">
                                    <multiselect
                                        :placeholder="`${$t('select status')}`"
                                        v-model="filters.read"
                                        :options="read_options"
                                        label="name">
                                    </multiselect>
                                </td>
                                <td class="bg-white text-center">
                                    <div class="button-items">
                                        <button
                                            :class="`btn btn-danger ${
                                                loading ? 'disabled' : ''
                                            }`"
                                            @click="resetFilter()">
                                            {{ $t('reset') }}
                                        </button>
                                        <button
                                            class="btn btn-primary"
                                            @click="create_modal = true">
                                            {{ $t('send message') }}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </b-form>
            </div>
            <div class="card-body">
                <div ref="tableScroller" class="table-responsive">
                    <LoadingTable
                        :cols="5"
                        :rows="50"
                        :td_small_class="`d-none`"
                        v-if="loading" />
                    <table
                        v-else
                        class="table table-hover table-nowrap table-bordered table-striped mb-0 table-list">
                        <thead class="hidden">
                            <tr>
                                <th scope="col">
                                    {{ $t('confirm') }}
                                </th>
                                <th scope="col">
                                    {{ $t('title') }}
                                </th>
                                <th scope="col" v-if="$route.params.role!='public'">
                                    {{ $t('category') }}
                                </th>
                                <th scope="col">
                                    {{ $t('status') }}
                                </th>
                                <th scope="col">
                                    {{ $t('member ID') }}
                                </th>
                                <th scope="col">
                                    {{ $t('created at') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="r in data.list"
                                :key="r._id"
                                :class="`${
                                    !r.handler_read ? 'bg-primary bg-soft' : ''
                                }`">
                                <td class="text-center">
                                    <i
                                        class="fs-4 bx"
                                        :class="`${
                                            r.handler_read
                                                ? 'bx-check text-primary'
                                                : 'bx bx-x text-dark'
                                        }`"></i>
                                </td>
                                <td>
                                    <a
                                        href="javascript:void(0)"
                                        class="text-dark d-flex align-items-center"
                                        @click="goToTicketView(r._id)">
                                        <i
                                            :class="`mdi ${
                                                !r.handler_read
                                                    ? 'mdi-email text-warning'
                                                    : 'mdi-email-open-outline'
                                            } fs-4 me-1 `"></i>
                                        <span
                                            class="text-truncate me-2"
                                            :class="{
                                                'fw-bold': !r.handler_read,
                                            }"
                                            style="max-width: 500px">
                                            {{ r.subject }}
                                        </span>
                                        <span class="text-muted font-size-11">{{
                                            $t('click to view')
                                        }}</span>
                                    </a>
                                </td>
                                <td class="text-center" v-if="$route.params.role!='public'">
                                   {{r.category?$t(r.category):''}}
                                </td>
                                <td class="text-center">
                                    <!-- <span
                                        v-if="r.type != 'comment'"
                                        :class="`${
                                            r.status == 'open'
                                                ? 'text-success fw-bold'
                                                : 'text-danger'
                                        }`"
                                        >{{ $t(r.status) }}</span
                                    > -->
                                    <span
                                        :class="`${
                                             r.customer_read
                                                ? 'text-success fw-bold'
                                                : 'text-danger'
                                        }`"
                                        >{{ $t( r.customer_read?'checked':'unchecked') }}</span
                                    >
                                </td>
                                <td class="text-center">
                                    <div class="td-username">
                                        <a
                                            href="javascript:void(0)"
                                            @click="
                                                goToUserForm(r.customer._id, r.customer.role)
                                            "
                                            class="link-username"
                                            >
                                            <i
                                            
                                                :class="`mdi ${
                                                    r.customer.role == 'agent'
                                                        ? 'mdi-account-tie text-danger'
                                                        : r.customer.role == 'player'
                                                        ? 'mdi-account-cowboy-hat text-primary'
                                                        : ''
                                                }`"
                                            ></i>
                                            <i  v-if="r.customer.role=='player'" :class="`mdi mdi-numeric-${r.customer[r.customer.role].level}-box fs-5  level-${r.customer[r.customer.role].level}`"></i>
                                            <!-- <sapan  v-else :class="`mdi mdi-numeric-${r.customer[r.customer.role].level}-box fs-5  level-${r.customer[r.customer.role].level}`"></i> -->
                                            <span v-else :class="`lvl lvl-${r.customer[r.customer.role].level}`">{{$t(`level ${r.customer[r.customer.role].level}`)}}</span>
                                            <span
                                                :class="{
                                                    'text-muted': r.customer == null,
                                                }"
                                                >{{
                                                    r.customer
                                                        ? r.customer.username
                                                        : 'Unknown User'
                                                }}</span
                                            >
                                        </a>
                                    </div>
                                </td>

                                <td class="text-center">
                                    {{ dateYmD(r.created_at, true) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div
                        class="no-data-placeholder text-light fw-bolder fs-1"
                        v-if="!data.list.length && !loading">
                        {{ $t('no data found') }}
                    </div>
                </div>
                <Pagination :data="data" @emitPage="initList" />
            </div>
        </div>
        <b-modal
            v-model="create_modal"
            @hidden="reset()"
            centered
            size="lg"
            :title="$t(`create ticket`)"
            title-class="font-18"
            hide-footer>
            <b-form @submit.prevent="submitForm">
                <div class="template-buttons">
                    <b-button  
                        v-for="t,i in templates" :key="t._id"
                        variant="outline-secondary"
                        size="sm"
                        class="w-100"
                        @click.prevent="useTemplate(t)">
                    {{ $t('auto message')}} {{ i + 1}}</b-button>
                </div>
                <table class="table table-bordered table-wrap table-form mb-2">
                    <tbody>
                        <!-- <tr>
                            <th
                                class="bg-light bg-soft hidden"
                                style="width: 11%">
                                {{ $t('type') }}
                            </th>
                            <td style="width: 20%">
                                
                                <multiselect 
                                    :options="type_options"
                                    v-model="pl.type"
                                    :placeholder="`${$t('select type')}`"
                                    label="name"
                                    :searchable="false"
                                    :can-deselect="false"
                                    :can-clear="false"
                                    >
                                    <template v-slot:singlelabel="{ value }">
                                        <span class="flex-fill ps-2">{{
                                            $t(value.name)
                                        }}</span>
                                    </template>
                                    <template v-slot:option="{ option }">
                                        {{ $t(option.name) }}
                                    </template>
                                </multiselect>
                           
                            </td>
                        </tr> -->

                        <tr v-if="$route.params.role == 'public'">
                            <th
                                class="bg-light bg-soft hidden"
                                style="width: 11%">
                                {{ $t('receiver') }}
                            </th>
                            <td colspan="5">
                                <multiselect
                                    :placeholder="`${$t('select receiver')}`"
                                    v-model="pl.receiver"
                                    :options="recipient_options"
                                    label="name"
                                    :searchable="false"
                                    :can-deselect="false"
                                    :can-clear="false"
                                    >
                                    <template v-slot:singlelabel="{ value }">
                                        <span class="flex-fill ps-2">{{
                                            $t(`send to ${value.value}`)
                                        }}</span>
                                    </template>
                                    <template v-slot:option="{ option }">
                                        {{ $t(`send to ${option.value}`) }}
                                    </template>
                                </multiselect>
                            </td>
                        </tr>
                        <tr v-if="$route.params.role == 'public' && false">
                            <th
                                class="bg-light bg-soft hidden"
                                style="width: 11%">
                                {{ $t('level') }}
                            </th>
                            <td colspan="5">
                                <multiselect
                                    :placeholder="`${$t('select level')}`"
                                    v-model="pl.level"
                                    :options="level_options"
                                    label="name"
                                    :searchable="false"
                                    :can-deselect="false"
                                    :can-clear="false"
                                    :class="{
                                        'is-invalid':
                                            submitted && v$.pl.level.$error,
                                    }">
                                    <template v-slot:singlelabel="{ value }">
                                        <span class="flex-fill ps-2">{{
                                            $t(value.value)
                                        }}</span>
                                    </template>
                                    <template v-slot:option="{ option }">
                                        {{ $t(option.value) }}
                                    </template>
                                </multiselect>
                                <div
                                    v-for="(item, index) in v$.pl.level.$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <span v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="['agent','player'].includes($route.params.role)">
                            <th
                                class="bg-light bg-soft hidden"
                                style="width: 11%">
                                {{ $t('customer') }}
                            </th>
                            <td style="width: 20%">
                                <input
                                    :readonly="Boolean($route.query.username)"
                                    type="text"
                                    class="form-control"
                                    :class="{
                                        'is-invalid':
                                            pl.username &&
                                            v$.pl.username.$error,
                                        'is-valid':
                                            pl.username &&
                                            !v$.pl.username.$error,
                                    }"
                                    :placeholder="`${$t('enter username')}`"
                                    v-model="pl.username"
                                    list="usernames" />

                                <div
                                    v-if="
                                        pl.username &&
                                        v$.pl.username.validUsername.$invalid
                                    "
                                    class="invalid-feedback">
                                    {{
                                        $t(
                                            v$.pl.username.validUsername
                                                .$message
                                        )
                                    }}
                                </div>
                            </td>
                        </tr>
                        <tr v-if="$route.params.role != 'public'">
                            <th class="bg-light bg-soft hidden">
                                {{ $t('category') }}
                            </th>
                            <td colspan="5">
                                <multiselect
                                    :placeholder="`${$t('select category')}`"
                                    v-model="pl.category"
                                    :options="category_options"
                                    label="name"
                                    :searchable="false"
                                    :can-deselect="false"
                                    :can-clear="false"
                                    :class="{
                                        'is-invalid':
                                            submitted && v$.pl.category.$error,
                                    }">
                                    <template v-slot:singlelabel="{ value }">
                                        <span class="flex-fill ps-2">{{
                                            $t(value.value)
                                        }}</span>
                                    </template>
                                    <template v-slot:option="{ option }">
                                        {{ $t(option.value) }}
                                    </template>
                                </multiselect>
                                <div
                                    v-for="(item, index) in v$.pl.category
                                        .$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <span v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="bg-light bg-soft hidden">
                                {{ $t('title') }}
                            </th>
                            <td colspan="5">
                                <input
                                    type="text"
                                    :placeholder="`${$t('enter title')}`"
                                    v-model="pl.subject"
                                    class="form-control"
                                    :class="{
                                        'is-invalid':
                                            submitted && v$.pl.subject.$error,
                                    }" />
                                <div
                                    v-for="(item, index) in v$.pl.subject
                                        .$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <span v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="bg-light bg-soft hidden">
                                {{ $t('content') }}
                            </th>
                            <td colspan="3">
                                <textarea
                                    type="text"
                                    :placeholder="`${$t('enter content here')}`"
                                    v-model="pl.content"
                                    class="form-control"
                                    rows="10"
                                    style="resize: none"
                                    :class="{
                                        'is-invalid':
                                            submitted && v$.pl.content.$error,
                                    }"></textarea>
                                <div
                                    v-for="(item, index) in v$.pl.content
                                        .$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <span v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="text-end">
                    <button type="submit" class="btn btn-warning btn-lg">
                        {{ $t('send') }}
                    </button>
                </div>
            </b-form>
        </b-modal>
    </Layout>
</template>

<script>
import Datepicker from 'vue3-datepicker';
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import LoadingTable from '@/components/loading-table';
import Pagination from '../../../components/pagination.vue';
import BulletinBoardTabs from '@/components/bulletin-board-tabs';
import Multiselect from '@vueform/multiselect';
import Switches from 'vue-switches';
import Swal from 'sweetalert2';

import { mapActions } from 'vuex';
import random from '../../../mixins/random';
import datetime from '../../../mixins/datetime';
import number from '../../../mixins/number';
import ui from '../../../mixins/ui';

import {
    required,
    requiredIf,
    minLength,
    helpers,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        Layout,
        PageHeader,
        Pagination,
        Switches,
        LoadingTable,
        Multiselect,
        Datepicker,
        BulletinBoardTabs,
    },
    mixins: [datetime, number, random, ui],
    data() {
        return {
            test: null,
            title: `players`,
            filters: {
                keyword: null,
                username: null,
                status: null,
                unread: false,
                read: null,
                from: new Date(this.firstDateOftheMonth()),
                to: new Date(),
            },
            read_options: [
                { name: this.$t('checked'), value:  1},
                { name: this.$t('unchecked'), value: 0 },
            ],
            customer_options: [],
            data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            create_modal: false,
            loading: false,
            category_options: [
                'Inquiry of Deposit Accounts',
                'Charge and Exchange Inquiry',
                'System Error',
                'Game Error',
                'Other Inquiries',
            ],
            type_options: [
             
            ],
            level_options: [
                '1',
                '2',
                '3',
                '4',
                '5',
                '6',
                '7',
                '8',
                '9',
                '10',
            ],
            recipient_options: [
                'all',
                'agents',
                'level 1',
                'level 2',
                'level 3',
                'level 4',
                'level 5',
                'players',
            ],
            pl: {
                username: null,
                level: null,
                customer_id: null,
                subject: null,
                content: null,
                category: null,
                type: 'private',
                receiver: 'all',
            },
            templates:[]
        };
    },
    validations() {
        return {
            pl: {
                username: {
                    validUsername: helpers.withMessage(
                        'must be a valid username',
                        function (value) {
                            return this.pl.customer_id != null;
                        }
                    ),
                },
                customer_id: {
                    requiredIf: helpers.withMessage(
                        'required',
                        requiredIf(function () {
                            return this.pl.type == 'private';
                        })
                    ),
                },
                level: {
                    requiredIf: helpers.withMessage(
                        'required',
                        requiredIf(function () {
                            return this.pl.type != 'private';
                        })
                    ),
                },
                subject: {
                    required: helpers.withMessage('required', required),
                },
                type: {
                    required: helpers.withMessage('required', required),
                },
                content: {
                    required: helpers.withMessage('required', required),
                },
                category: {
                    required: helpers.withMessage('required', required),
                },
            },
        };
    },
    watch: {
        filters: {
            handler() {
                this.initList(1);
            },
            deep: true,
        },
        'pl.username'() {
            this.getUsernames();
        },
        '$store.state.key'() {
            this.initList(1);
        },
    },
    methods: {
        ...mapActions('ticket', {
            ticketGetList: 'getList',
            ticketCreate: 'create',
            ticketDelete: 'delete',
        }),
        ...mapActions('ticket', {
            templateGetList: 'templateGetList',
        }),
        ...mapActions('player', {
            playerGetList: 'getList',
        }),
        ...mapActions('agent', {
            agentGetList: 'getList',
        }),
        readQuery() {
            if (this.$route.query.status)
                this.filters.status = this.$route.query.status;
            if (this.$route.query.from)
                this.filters.from = new Date(this.$route.query.from);
            if (this.$route.query.to)
                this.filters.to = new Date(this.$route.query.to);
        },
        async initList(p) {
            this.resetScroll();
            var pl = {
                page_no: p ? p : 1,
                read: this.filters.unread ? 0 : null,
                username: this.filters.username,
                search: this.filters.keyword ? this.filters.keyword : '',
                status: this.filters.status,
                from: this.dateYmD(this.filters.from) + ` 00:00:00`,
                to: this.dateYmD(this.filters.to) + ` 23:59:59`,
            };
            if(this.filters.read!=null) {
                pl.read = this.filters.read
            }
            const role = this.$route.params.role;
            if(role == 'public') {
                pl.type = role
                pl.level = 'all'
                pl.role = 'all'
            }else {
                pl.role = role
            }
    
            this.loading = true;
            const data = await this.ticketGetList(pl);
            this.data.list = data.data;
            this.data.links = data.links;
            this.data.current_page = data.current_page;
            this.data.per_page = data.per_page;
            this.data.last_page = data.last_page;
            this.data.total = data.total;
            this.loading = false;
        },
        view(id) {
            window.open(
                `/player/${id}`,
                '_blank',
                'width=' + 1720 + ',height=' + screen.height
            );
        },
        async submitForm() {
            if (!this.isAuthorized()) return;
            this.submitted = true;
            this.v$.pl.$touch();
            // if (this.v$.pl.$invalid) return;

            var fd = new FormData();
            if(this.$route.params.role == 'public') {
                switch(this.pl.receiver) {
                    case 'all':
                        fd.append('type', 'public')
                            break;
                    case 'level 1':
                    case 'level 2':
                    case 'level 3':
                    case 'level 4':
                    case 'level 5':
                        fd.append('type', 'agent')
                        fd.append('level',this.pl.receiver.replace("level ", ""));
                            break;
                    case 'players':
                        fd.append('type', 'player');
                            break;
                    case 'agents':
                        fd.append('type', 'agent');
                            break;

                }
            } else {
                fd.append('type', 'private');
                fd.append('customer_id', this.pl.customer_id);
                fd.append('category', this.pl.category);
            }

            fd.append('subject', this.pl.subject);
            fd.append('content', this.pl.content);
            fd.append('status', 'closed');
            
            const res = await this.ticketCreate({
                form_data: fd,
            });

            if (res) {
                if (res.success) {
                    this.create_modal = false;
                    this.initList(1);
                }
                Swal.fire({
                    title: this.$t(res.data.message),
                    icon: res.data.status,
                    timer: 2000,
                    confirmButtonText: this.$t('ok'),
                });
            }
            // }
        },
        async remove(r) {
            const confirmed = await Swal.fire({
                title: `${this.$t('are you sure')}?`,
                html: `${this.$t('delete this ticket')}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f1b44c',
                cancelButtonColor: '#74788d',
                cancelButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('confirm'),
            }).then((result) => {
                return result.value;
            });

            if (confirmed) {
                const res = await this.ticketDelete({ id: r._id });

                if (res) {
                    Swal.fire({
                        icon: res.success ? 'success' : 'error',
                        title: res.success
                            ? this.$t('ticket has been deleted')
                            : this.$t('deleting failed'),
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    if (res.success) {
                        this.initList(1);
                    }
                }
            }
        },
        resetFilter() {
            this.filters.from = new Date(this.firstDateOftheMonth());
            this.filters.to = new Date();
            this.filters.username = null;
            this.filters.status = null;
        },
        reset() {
            this.submitted = false;
            this.v$.pl.$reset();
            this.pl.receiver = 'all';
            this.pl.username = null;
            this.pl.customer_id = null;
            this.pl.subject = null;
            this.pl.content = null;
            this.pl.type = 'private';
            this.pl.level = null;
        },
        async getUsernames() {
            this.v$.pl.username.$touch();
            this.customer_options = [];
            this.pl.customer_id = null;

            var pl = {
                limit: 100,
                username: this.pl.username || null,
            };
            const users =
                this.$route.params.role == 'player'
                    ? await this.playerGetList(pl)
                    : await this.agentGetList(pl);
            users.data.forEach((r) => {
                this.customer_options.push({ name: r.username, value: r._id });
            });
            if (users.data.length === 1) {
                this.pl.username = users.data[0].username;
                this.pl.customer_id = users.data[0]._id;
            }
        },
        async initTemplates() {
            this.templates = await this.templateGetList({});
        },
        useTemplate(template) {
            this.pl.subject = template.subject
            this.pl.content = template.content
        }
    },
    mounted() {
        this.type_options = [
            { name: this.$route.params.role, value:'private'},
            { name:`all ${this.$route.params.role}s`,value:this.$route.params.role}
        ]

        if (this.$route.query.status || this.$route.query.from) {
            this.readQuery();
        } else {
            this.initList(1);
            this.initTemplates();
        }

        this.$watch(
            () => this.$route.params.role,
            (n, o) => {
                this.initList(1);
                this.initTemplates();
                this.pl.type = 'private';
                this.pl.level = null;
                this.type_options = [
                    { name: this.$route.params.role, value:'private'},
                    { name:`all ${this.$route.params.role}s`,value:this.$route.params.role}
                ]
            }
        );
        if (Boolean(window.PusherChannel)) {
            window.PusherChannel.bind('new-ticket', (e) => {
                if (this.$route.params.role == e.message.role) {
                    this.initList(1);
                    this.initTemplates();
                }
            });
            window.PusherChannel.bind('new-ticket-reply', (e) => {
                if (this.$route.params.role == e.message.role) {
                    this.initList(1);
                    this.initTemplates();
                }
            });
        }

        window.addEventListener('message', (event) => {
            if (event.origin !== window.origin) {
                return;
            }
            if (event.data == 'reload-tickets') {
                this.authGetProfile();
                this.initList(this.data.current_page);
            }
        });


        
    },
    beforeUnmount() {
        if (Boolean(window.PusherChannel)) {
            window.PusherChannel.unbind('new-ticket');
            window.PusherChannel.unbind('new-ticket-reply');
        }
    },
};
</script>
